import * as React from 'react';

import { List } from 'immutable';
import * as moment from 'moment';
import { connect } from 'react-redux';

import TransactionDayList from './TransactionDayList/TransactionDayList';

import { ISingleTransaction } from '../../../../models/transactions';

interface ITransactionListProps {
  transactions: List<ISingleTransaction>;
}

class TransactionList extends React.Component <ITransactionListProps, {}> {

  // TODO: add timezone calculation
  getStartOfDay(day) {
    moment(day).set({
      millisecond: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
    });
  }

  splitTransactionsToDays(transactions: List<ISingleTransaction>): List<List<ISingleTransaction>> {
    const sortComparator = (a, b) => b.date - a.date;
    return transactions
      .groupBy(transaction => transaction.date.toDateString())
      .map((dayTransactions) => dayTransactions.toList().sort(sortComparator))
      .toList()
      .sort(sortComparator);
  }

  render() {
    const { transactions } = this.props;
    const transactionsByDay = this.splitTransactionsToDays(transactions);

    const transactionsDayRender = transactionsByDay.map((dayTransactions) => {
      // @ts-ignore
      const firstDate = dayTransactions.first().date;

      return (
        <TransactionDayList
          key={`transaction-day-wrapper-${firstDate}`}
          transactions={dayTransactions}
        />
      );
    });

    return (
      <div className="transaction-list-wrapper">
        {transactionsDayRender}
      </div>
    );
  }

}

function inject({ transactions }) {
  return {
    transactions: transactions.transactions,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(TransactionList);
