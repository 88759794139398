
require.register("es-abstract/helpers/forEach.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "es-abstract");
  (function() {
    'use strict';

module.exports = function forEach(array, callback) {
	for (var i = 0; i < array.length; i += 1) {
		callback(array[i], i, array);
	}
};
  })();
});