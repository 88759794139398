import * as React from 'react';

import classnames from 'classnames';
import { connect } from 'react-redux';

import Icon from '../../Basic/Icon/Icon';
import IconButton from '../../Basic/IconButton/IconButton';

import { hideSnackbarMessage } from '../../../actions/ui/snackbar';
import { texts } from '../../../constants/lang';
import { snackbarMessageTypes, uiConfig } from '../../../constants/uiConfig';
import { ISnackbarElement } from '../../../models/ui';

interface ISnackbarProps extends ISnackbarElement {
  onSnackbarClose: (timeStamp: number) => any;
}

class Snackbar extends React.Component <ISnackbarProps, {}> {

  closeDelayTimer;

  constructor(props) {
    super(props);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }

  componentDidMount(): void {
    this.closeDelayTimer = setTimeout(this.handleSnackbarClose, 4000);
  }

  componentWillUnmount(): void {
    clearTimeout(this.closeDelayTimer);
  }

  handleSnackbarClose () {
    const { timestamp, onSnackbarClose } = this.props;
    onSnackbarClose(timestamp);
  }

  render() {
    const { type, content } = this.props;

    const icons = uiConfig.snackbar;
    const snackbarTexts = texts.snackbar;

    const iconConfigName = {
      [snackbarMessageTypes.copied] : icons.copy,
      [snackbarMessageTypes.serverError] : icons.serverError,
      [snackbarMessageTypes.success] : icons.success,
    }[type];

    const iconName = iconConfigName || icons.unknown;

    const serverErrorText = type === snackbarMessageTypes.serverError ? (
      <strong> {snackbarTexts.serverError} </strong>
    ) : null;

    const wrapperClass = classnames({
      snackbar: true,
      'snackbar-copy': type === snackbarMessageTypes.copied,
      'snackbar-server-error': type === snackbarMessageTypes.serverError,
      'snackbar-unknown': !iconConfigName,
    });

    return (
      <div className={wrapperClass}>
        <Icon className="snackbar-icon" name={iconName} />
        <p className="snackbar-message"> {serverErrorText} {content} </p>
        <IconButton className="snackbar-close" onClick={this.handleSnackbarClose}>
          <Icon name="close" />
        </IconButton>
      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onSnackbarClose: (timeStamp: number) => dispatch(hideSnackbarMessage(timeStamp)),
  };
}

export default connect(inject, actions)(Snackbar);
