
require.register("parse5/lib/sax/dev_null_stream.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "parse5");
  (function() {
    'use strict';

var WritableStream = require('stream').Writable,
    util = require('util');

var DevNullStream = module.exports = function () {
    WritableStream.call(this);
};

util.inherits(DevNullStream, WritableStream);

DevNullStream.prototype._write = function (chunk, encoding, cb) {
    cb();
};
  })();
});