import * as React from 'react';

import classnames from 'classnames';
import { List } from 'immutable';
import { connect } from 'react-redux';

import Card from '../Card/Card';

import { ICard } from '../../../../models/cards';

interface ICardsSliderProps {
  activeCard: string;
  cards: List<ICard>;
  onCardSwipePrevious: () => any;
  onCardSwipeNext: () => any;
}

class CardsSlider extends React.Component <ICardsSliderProps, {}> {
  render() {
    const { cards, activeCard, onCardSwipePrevious, onCardSwipeNext } = this.props;
    const activeCardIndex = cards.findIndex((item) => item.id === activeCard);

    const cardsRender = cards.map((card, index) => {
      const previousCardID = index > 0 ? cards.get(index - 1).id : null;
      const nextCardID = index < cards.size - 1 ? cards.get(index + 1).id : null;
      const cardClass = classnames({
        'card-previous': index === activeCardIndex - 1,
        'card-active': index === activeCardIndex,
        'card-next': index === activeCardIndex + 1,
      });
      return (
        <Card
          className={cardClass}
          key={card.id}
          content={card}
          previousID={previousCardID}
          nextID={nextCardID}
          onCardSwipePrevious={onCardSwipePrevious}
          onCardSwipeNext={onCardSwipeNext}
        />
      );
    });
    return (
      <div className="slider">
        <div
          className="slider-inner"
          style={{
            left: `${0 - activeCardIndex * 100}vw`,
          }}
        >
          { cardsRender }
        </div>
      </div>
    );
  }
}

function inject() {
  return {

  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(CardsSlider);
