import * as React from 'react';

import { connect } from 'react-redux';

import AvatarIcon from '../../../Basic/SVG/AvatarIcon';

import { IProfile } from '../../../../models/profile';
import { urlImage } from '../../../../utils/other';

interface IProfilePreviewProps {
  id?: string;
  avatar: string;
  displayName: string;
}

class ProfilePreview extends React.Component <IProfilePreviewProps, {}> {
  render() {
    const { id, avatar, displayName } = this.props;
    const avatarRender = avatar ? (
      <div
        className="profile-preview-avatar"
        style={{
          backgroundImage: urlImage(avatar),
        }}
      />
    ) : <AvatarIcon />;

    return (
      <div className="profile-preview-wrapper">
        <div className="profile-preview-background" />
        {avatarRender}

        { displayName && (
          <h2 className="profile-preview-title"> {displayName} </h2>
        )}

        { id && (
          <h5 className="profile-preview-id"> ID {id} </h5>
        )}
      </div>
    );
  }
}

function inject({ profile }) {
  const profileData: IProfile = profile.profile;
  return {
    id: profileData.id,
    avatar: profileData.avatar,
    displayName: profileData.displayName,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(ProfilePreview);
