
require.register("parse5/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "parse5");
  (function() {
    'use strict';

var Parser = require('./parser'),
    Serializer = require('./serializer');


// Shorthands
exports.parse = function parse(html, options) {
    var parser = new Parser(options);

    return parser.parse(html);
};

exports.parseFragment = function parseFragment(fragmentContext, html, options) {
    if (typeof fragmentContext === 'string') {
        options = html;
        html = fragmentContext;
        fragmentContext = null;
    }

    var parser = new Parser(options);

    return parser.parseFragment(html, fragmentContext);
};

exports.serialize = function (node, options) {
    var serializer = new Serializer(node, options);

    return serializer.serialize();
};


// Tree adapters
exports.treeAdapters = {
    default: require('./tree_adapters/default'),
    htmlparser2: require('./tree_adapters/htmlparser2')
};


// Streaming
exports.ParserStream = require('./parser/parser_stream');
exports.PlainTextConversionStream = require('./parser/plain_text_conversion_stream');
exports.SerializerStream = require('./serializer/serializer_stream');
exports.SAXParser = require('./sax');
  })();
});