
require.register("parse5/lib/utils/mixin.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "parse5");
  (function() {
    'use strict';

var Mixin = module.exports = function (host) {
    var originalMethods = {},
        overriddenMethods = this._getOverriddenMethods(this, originalMethods);

    Object.keys(overriddenMethods).forEach(function (key) {
        if (typeof overriddenMethods[key] === 'function') {
            originalMethods[key] = host[key];
            host[key] = overriddenMethods[key];
        }
    });
};

Mixin.prototype._getOverriddenMethods = function () {
    throw new Error('Not implemented');
};
  })();
});