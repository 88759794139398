import * as React from 'react';

import classnames from 'classnames';
import { connect } from 'react-redux';

import Icon from '../../Basic/Icon/Icon';
import IconButton from '../../Basic/IconButton/IconButton';

import { closeModal } from '../../../actions/ui/modals';

interface INotificationModalProps {
  title: string;
  className: string;
  titleWidget?: React.ReactElement;
  onClose: () => any;
  children: any;
}
class NotificationModal extends React.Component <INotificationModalProps, {}> {
  renderTitle(): React.ReactElement {
    const { titleWidget, title, onClose } = this.props;
    return titleWidget || (
      <React.Fragment>
        <h2 className="modal-header-title">{title}</h2>
        <IconButton
          className="modal-close"
          onClick={onClose}
        >
          <Icon name="close" />
        </IconButton>
      </React.Fragment>
    );
  }

  render() {
    const { title, titleWidget, onClose, className, children } = this.props;
    const wrapperClass = classnames({
      'notification-modal': true,
    }, className);
    return (
      <div className={wrapperClass}>
        <div
          className="shadow"
          onClick={onClose}
        />
        <div className="modal-wrapper">
          <div className="modal-header">
            {this.renderTitle()}
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onClose: () => dispatch(closeModal()),
  };
}

// tslint:disable-next-line:no-shadowed-variable
const mergeProps = (inject, actions, ownProps) => {
  return Object.assign({}, inject, actions, ownProps);
};

export default connect(inject, actions, mergeProps)(NotificationModal);
