import * as React from 'react';

import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CardActions from './CardActions/CardActions';
import CardPreview from './CardPreview/CardPreview';

import { Swipeable } from 'react-swipeable';
import { cardTypes } from '../../../../constants/uiConfig';
import { ICard } from '../../../../models/cards';
import { getCardPath } from '../../../../utils/routing';

interface ISingleCardProps {
  content: ICard;
  active: boolean;
  previousID: string;
  nextID: string;
  className?: any;
  onCardSwipePrevious?: () => any;
  onCardSwipeNext?: () => any;
}

class SingleCard extends React.Component <ISingleCardProps, {}> {
  render() {
    const { content, previousID, nextID, className, onCardSwipePrevious, onCardSwipeNext } = this.props;
    const leftRoute = previousID ? (
      <Link
        className="switcher previous-link"
        to={getCardPath(previousID)}
        replace
      />
    ) : <div className="switcher" />;
    const rightRoute = nextID ? (
      <Link
        className="switcher next-link"
        to={getCardPath(nextID)}
        replace
      />
    ) : <div className="switcher" />;

    const wrapperClass = classnames({
      card: true,
      'card-physical': content.type === cardTypes.chipPin,
      'card-sticker': content.type === cardTypes.sticker,
      'card-virtual': content.type === cardTypes.virtual,
      'card-create': content.type === cardTypes.creating,
      // tslint:disable-next-line:align
    }, className);

    return (
      <div className={wrapperClass}>
        <Swipeable
          onSwipedRight={onCardSwipePrevious}
          onSwipedLeft={onCardSwipeNext}
          delta={window.innerWidth / 4}
        >
          <div className="card-head-wrapper">
            {leftRoute}
            <CardPreview content={this.props.content} />
            {rightRoute}
          </div>
        </Swipeable>
        <CardActions content={content} />
      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(SingleCard);
