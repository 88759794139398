import * as React from 'react';

import Button from '../../../../Basic/Button/Button';
import MailIcon from '../../../../Basic/SVG/MailIcon';

import { texts } from '../../../../../constants/lang';

interface ISendRecoverSuccessProps {
  onClose: () => any;
}

class SendRecoverySuccess extends React.Component <ISendRecoverSuccessProps, {}> {
  render() {
    const recoveryTexts = texts.login.sendRecoveryEmail.step2;

    return (
      <div className="send-recover-email-success">
        <MailIcon />
        <h2 className="title"> {recoveryTexts.title} </h2>
        <p className="description"> {recoveryTexts.description} </p>
        <Button
          rounded
          icon="arrow-left"
          label={recoveryTexts.action}
          onClick={this.props.onClose}
        />
      </div>
    );
  }
}

export default SendRecoverySuccess;
