
require.register("parse5/lib/parser/plain_text_conversion_stream.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "parse5");
  (function() {
    'use strict';

var ParserStream = require('./parser_stream'),
    inherits = require('util').inherits,
    $ = require('../common/html').TAG_NAMES;

var PlainTextConversionStream = module.exports = function (options) {
    ParserStream.call(this, options);

    // NOTE: see https://html.spec.whatwg.org/#read-text
    this.parser._insertFakeElement($.HTML);
    this.parser._insertFakeElement($.HEAD);
    this.parser.openElements.pop();
    this.parser._insertFakeElement($.BODY);
    this.parser._insertFakeElement($.PRE);
    this.parser.treeAdapter.insertText(this.parser.openElements.current, '\n');
    this.parser.switchToPlaintextParsing();
};

inherits(PlainTextConversionStream, ParserStream);
  })();
});