import * as React from 'react';

import { List } from 'immutable';
import * as InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { compose } from 'redux';

import CircleSpinner from '../../../Basic/CircleSpinner/CircleSpinner';
import EmptySearch from '../../../Basic/EmptySearch/EmptySearch';
import Input from '../../../Basic/Inputs/Input/Input';
import AdjustmentTransaction from '../../../Basic/Transactions/AdjustmentTransaction/AdjustmentTransaction';
import SingleTransaction from '../../../Basic/Transactions/SingleTransaction/SingleTransaction';
import Modal from '../../../Layout/Modal/Modal';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { getAdditionalSearchTransactions } from '../../../../actions/transactions';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { singleTransactionTypes } from '../../../../constants/transactions';
import { ISingleTransaction } from '../../../../models/transactions';

interface ISearchProps extends IWithRouterModalChild {
  transactions: List<ISingleTransaction>;
  getSearchTransactions?: () => any;
  hasMoreTransactions: boolean;
  getAdditionalSearchTransactions: (day, filter: string) => any;
}

interface ISearchState {
  searchMerchantName: string;
}

class Search extends React.Component <ISearchProps, ISearchState> {

  constructor(props) {
    super(props);
    this.state = {
      searchMerchantName: '',
    };
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleSearchFilterClear = this.handleSearchFilterClear.bind(this);
    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  handleSearchInputChange(value) {
    this.setState({
      searchMerchantName: value,
    });
  }

  handleSearchFilterClear() {
    this.setState({
      searchMerchantName: '',
    });
  }

  onLazyLoad() {
    // tslint:disable-next-line:no-shadowed-variable
    const { transactions, getAdditionalSearchTransactions } = this.props;
    const { searchMerchantName } = this.state;
    if (transactions.size > 0) {
      // @ts-ignore
      const lastDay = transactions.last().date;
      getAdditionalSearchTransactions(lastDay, searchMerchantName);
    }
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.account.root);
  }

  renderModal(): React.ReactElement {
    const { withoutUserRoute, transactions, hasMoreTransactions } = this.props;
    const { searchMerchantName } = this.state;

    const filteredTransactions = transactions.filter((transaction) => {
      return (transaction.title && transaction.title.toLowerCase().includes(searchMerchantName.toLowerCase()));
    });

    const transactionsRender = filteredTransactions.map((transaction) => {
      return (transaction.type === singleTransactionTypes.deposit || transaction.type === singleTransactionTypes.withdraw) ? (
          <AdjustmentTransaction
            key={transaction.id}
            content={transaction}
            showFullDate
          />
        ) : (
          <SingleTransaction
            key={transaction.id}
            content={transaction}
            showFullDate
          />
        );
    });

    const searchTexts = texts.accountPage.search;

    const searchInputRender = (
      <Input
        icon="magnify"
        autoFocusDelay={400}
        placeholder={searchTexts.filterPlaceholder}
        value={searchMerchantName}
        onChange={this.handleSearchInputChange}
      />
    );

    const spinnerElement = (<CircleSpinner key={'spinner'} />);

    const isEmptySearch = filteredTransactions.size === 0 && !hasMoreTransactions;

    const emptySearchTexts = texts.accountPage.search.emptyState;

    return (
      <Modal
        className="search-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalWidget={searchInputRender}
      >
        <div className="search-modal-wrapper">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.onLazyLoad}
            initialLoad={false}
            useWindow={false}
            hasMore={hasMoreTransactions}
            threshold={300}
            loader={spinnerElement}
          >
            {transactionsRender}
          </InfiniteScroll>

          {isEmptySearch && (
            <EmptySearch
                className="search-empty-screen"
                title={emptySearchTexts.title}
                description={emptySearchTexts.description}
                actionTitle={emptySearchTexts.action}
                action={this.handleSearchFilterClear}
            />
          )}
        </div>
      </Modal>
    );
  }

  render() {
    this.props.onModalOpen(this.renderModal());
    return <React.Fragment />;
  }
}

function inject({ transactions }) {
  return {
    transactions: transactions.transactions,
    hasMoreTransactions: !transactions.done,
  };
}

function actions(dispatch) {
  return {
    // tslint:disable-next-line:max-line-length
    getAdditionalSearchTransactions: (day, filter) => dispatch(getAdditionalSearchTransactions(day, filter)),
  };
}

// @ts-ignore
export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(Search);
