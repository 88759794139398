import * as React from 'react';

import { List } from 'immutable';
import * as InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';

import CircleSpinner from '../../Basic/CircleSpinner/CircleSpinner';
import AccountChart from './AccountChart/AccountChart';
import AccountDetails from './AccountDetails/AccountDetails';
import AccountHeader from './AccountHeader/AccountHeader';
import Search from './Search/Search';
import TopUp from './TopUp/TopUp';
import TransactionList from './TransactionList/TransactionList';

import { ISingleTransaction } from '../../../models/transactions';

import { getAccount } from '../../../actions/account';
import { listCards } from '../../../actions/cards';
import {
  getAdditionalAccountTransactions,
  initialAccountTransactions,
} from '../../../actions/transactions';
import { removeAnchorPage, setAnchorPage } from '../../../actions/ui/scroll';
import { routingPaths } from '../../../constants/routing';

interface IOverviewPageProps extends RouteComponentProps<any> {
  transactions?: List<ISingleTransaction>;
  hasMoreTransactions: boolean;
  isAppLoaded?: boolean;

  onPageAnchorSet?: (page) => any;
  onPageAnchorRemove?: () => any;
  getAdditionalAccountTransactions?: (day) => any;
  getAccount: () => any;
  getInitialAccountTransactions: () => any;
  listCards: () => any;
}

interface IOverviewPageState {
  showHeader: boolean;
  shouldRouteTopUp: boolean;
}

class OverviewPage extends React.Component <IOverviewPageProps, IOverviewPageState> {

  detailsElement;
  pageElement;

  constructor(props) {
    super(props);

    this.state = {
      showHeader: false,
      shouldRouteTopUp: false,
    };

    this.refPageElement = this.refPageElement.bind(this);
    this.refDetailsElement = this.refDetailsElement.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onLazyLoad = this.onLazyLoad.bind(this);
  }

  componentDidMount(): void {
    this.pageElement.addEventListener('scroll', this.handleScroll);
    this.props.onPageAnchorSet(this.pageElement);
    // tslint:disable-next-line:no-shadowed-variable
    const { isAppLoaded, getAccount, listCards, getInitialAccountTransactions } = this.props;
    if (isAppLoaded) {
      getAccount();
      listCards();
      getInitialAccountTransactions();
    }
  }

  componentWillUnmount() {
    this.pageElement.removeEventListener('scroll', this.handleScroll);
    this.props.onPageAnchorRemove();
  }

  refPageElement(element) {
    this.pageElement = element;
  }
  refDetailsElement(element) {
    this.detailsElement = element;
  }
  handleScroll() {
    // Show fixed header on scroll
    const headerHeight = 100;
    const detailsBottom = this.detailsElement.offsetTop + this.detailsElement.clientHeight;
    if ((this.pageElement.scrollTop + headerHeight > detailsBottom) && (!this.state.showHeader)) {
      this.setState({ showHeader: true });
    } else {
      if ((this.pageElement.scrollTop + headerHeight < detailsBottom) && (this.state.showHeader)) {
        this.setState({ showHeader: false });
      }
    }

  }

  onLazyLoad() {
    // tslint:disable-next-line:no-shadowed-variable
    const { transactions, getAdditionalAccountTransactions, location } = this.props;
    if (transactions.size > 0 && (location.pathname.indexOf(routingPaths.account.search) === -1)) {
      // @ts-ignore
      const lastDay = transactions.last().date;
      getAdditionalAccountTransactions(lastDay);
    }
  }

  render() {
    const spinnerElement = (<CircleSpinner key={'spinner'} />);
    return (
      <div
        className="page overview-page"
        ref={this.refPageElement}
      >

        { this.props.isAppLoaded && (
          <InfiniteScroll
              pageStart={0}
              loadMore={this.onLazyLoad}
              initialLoad={true}
              useWindow={false}
              hasMore={this.props.hasMoreTransactions}
              threshold={300}
              loader={spinnerElement}
          >
              <AccountHeader visible={this.state.showHeader} />
              <AccountDetails forwardRef={this.refDetailsElement} />
            {/*TODO: add account chart*/}
              <AccountChart />
              <TransactionList />
              <Switch>
                  <Route
                      path={routingPaths.account.root + routingPaths.account.search}
                      component={(props) => <Search {...props} /> }
                  />
                  <Route
                      path={routingPaths.account.root + routingPaths.account.topUp}
                      component={(props) => <TopUp {...props} /> }
                  />
              </Switch>
          </InfiniteScroll>
        )}

      </div>
    );
  }
}

function inject({ transactions, ui }) {
  return {
    transactions: transactions.transactions,
    hasMoreTransactions: !transactions.done,
    isAppLoaded: ui.preloadSpinner === 0,
  };
}

function actions(dispatch) {
  return {
    onPageAnchorSet: (page) => dispatch(setAnchorPage(page)),
    onPageAnchorRemove: () => dispatch(removeAnchorPage()),
    getAccount: () => dispatch(getAccount()),
    getAdditionalAccountTransactions: (day) => dispatch(getAdditionalAccountTransactions(day)),
    getInitialAccountTransactions: () => {
      dispatch(initialAccountTransactions());
    },
    listCards: () => dispatch(listCards()),
  };
}

export default connect(inject, actions)(OverviewPage);
