import * as React from 'react';

import { List } from 'immutable';
import * as ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { IModalElement } from '../../../models/ui';

interface IModalHandlerProps {
  modalStack: List<IModalElement>;
}

// tslint:disable-next-line:function-name
function ModalHandler(props: IModalHandlerProps) {
  const { modalStack } = props;
  // @ts-ignore

  // Get 2 last modals to render
  const modalRender = modalStack.slice(-2)
    // Generate modals to output
    .map((modalItem: IModalElement) => (
     <span key={modalItem.id}>
       {modalItem.content}
     </span>
    ));

  return (
    <div className="modal-holder">
      <ReactCSSTransitionGroup
        transitionName="modal-slide"
        transitionEnter={true}
        transitionLeave={true}
        transitionEnterTimeout={400}
        transitionLeaveTimeout={400}
      >
        {modalRender}
      </ReactCSSTransitionGroup>
    </div>
  );
}

function inject({ ui }) {
  return {
    modalStack: ui.modalStack,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(ModalHandler);
