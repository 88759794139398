export function generateID(): string {
  return Math.round(Math.random() * 100000).toString();
}

export function generateTransactionAmount(): number {
  return Math.round(Math.random() * 100000) / 100;
}

export function urlImage(image) {
  return `url(${image})`;
}
