import * as React from 'react';

import CardActivate from './CardActivate/CardActivate';
import CardBlocked from './CardBlocked/CardBlocked';
import CardCreate from './CardCreate/CardCreate';
import CardMenu from './CardMenu/CardMenu';
import CardProducing from './CardProducing/CardProducing';

import { blockType, cardProducingStatuses, cardStatus } from '../../../../../constants/cards';
import { cardTypes } from '../../../../../constants/uiConfig';
import { ICard } from '../../../../../models/cards';

interface ICardActionsProps {
  content: ICard;
}

class CardActions extends React.Component <ICardActionsProps, {}> {
  render() {
    const { content } = this.props;

    let contentRender: React.ReactElement;
    // TODO: w2lker - define what we are doing for card blocked cause of PIN or CVV2 incorrect enter
    const cardIsBlocked = content.status === cardStatus.blocked && (content.blockType !== blockType.blockedByCardholder);

    if (cardProducingStatuses.includes(content.status)) {
      contentRender = <CardProducing />;
    } else if (content.status === cardStatus.dispatched) {
      contentRender = <CardActivate id={content.id}/>;
    } else if (content.type === cardTypes.creating) {
      contentRender = <CardCreate/>;
    } else if (cardIsBlocked) {
      contentRender = <CardBlocked />;
    } else {
      contentRender = <CardMenu content={content} />;
    }

    return (
      <div className="card-menu">
        <div className="card-menu-notification" />
        <div className="card-menu-content">
          {contentRender}
        </div>
      </div>
    );
  }
}

export default CardActions;
