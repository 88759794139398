import { texts } from '../constants/lang';
import { IFormInputWithValidation } from '../models/forms';

export const emptyFormInputWithValidation: IFormInputWithValidation = {
  value: '',
  errorPrompt: '',
};

export function setFormInputWithValidation(value: string): IFormInputWithValidation {
  return {
    value,
    errorPrompt: '',
  };
}

export function changeFormField(key: string, context): (value: string) => any {
  return (value: string) => {
    context.setState({
      [key]: setFormInputWithValidation(value),
    });
  };
}

const errorTexts = texts.general.formErrorMessages;

export function validateFormFieldIsEntered(errorPrompt: string = errorTexts.required): (value: string) => string {
  return (value: string) => {
    return value.length === 0 ? errorPrompt : null;
  };
}

export function validateFormPasswordComplexity(errorPrompt: string = errorTexts.passwordComplexity): (value: string) => string {
  return (value: string) => {
    const hasValueDigits = /\d/.test(value);
    const hasValueMixCase = /[a-zA-Z]/.test(value);
    const hasValueRequiredLength = value.length >= 8;
    return (hasValueDigits && hasValueMixCase && hasValueRequiredLength) ? null : errorPrompt;
  };
}

export function validateFormPasswordConfirmation(matchValue: string, errorPrompt: string = errorTexts.confirmationMatch): (value: string) => string {
  return (value: string) => {
    return value !== matchValue ? errorPrompt : null;
  };
}

export function validatedFormEmail(errorPrompt: string = errorTexts.invalidEmail) {
  return (value: string) => {
    const checkEmailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return checkEmailRegExp.test(value.toLowerCase()) ? null : errorPrompt;
  };
}
