import * as React from 'react';

import { connect } from 'react-redux';

import Button from '../../../../Basic/Button/Button';
import GhostInput from '../../../../Basic/Inputs/GhostInput/GhostInput';
import Modal from '../../../../Layout/Modal/Modal';

import { setCardLimitConfig } from '../../../../../actions/cards';
import { closeModal } from '../../../../../actions/ui/modals';
import currencySymbols from '../../../../../constants/currencySymbols';
import { texts } from '../../../../../constants/lang';
import { ICard } from '../../../../../models/cards';

interface ILimitsEditingModalProps {
  period: string;
  type: string;
  card: ICard;

  currencySymbol: string;
  onLimitChange: (id: string, period: string, type: string, value: number, successMessage: string) => any;
  onClose: () => any;
}

interface ILimitsEditingModalState {
  amount: string;
}

class LimitEditingModal extends React.Component <ILimitsEditingModalProps, ILimitsEditingModalState> {
  constructor(props) {
    super(props);

    const { period, type, card } = this.props;

    this.state = {
      amount: card.limits[period][type].total || '0',
    };

    this.onAmountChange = this.onAmountChange.bind(this);
    this.onApplyLimit = this.onApplyLimit.bind(this);
    this.onBlurLimitInput = this.onBlurLimitInput.bind(this);
  }

  onAmountChange(newValue) {
    if (newValue.length === 0) {
      this.setState({
        amount: '0',
      });
    } else if (/^\+?\d+$/.test(newValue) && newValue.length <= 6) {
      const amountUpdate = this.state.amount === '0' ? newValue.slice(1) : newValue;
      this.setState({
        amount: amountUpdate,
      });
    }
  }

  onApplyLimit() {
    const { card, period, type, onLimitChange, onClose } = this.props;
    const limitTexts = texts.cardPage.card.cardLimits.editing;
    const message = limitTexts.successMessage(limitTexts[period][type].title);

    onLimitChange(card.id, period, type, parseInt(this.state.amount, 10), message);
    onClose();
  }

  onBlurLimitInput() {
    this.setState({
      amount: parseInt(this.state.amount, 10).toString(),
    });
  }

  render() {
    const { period, type, card, onClose, currencySymbol } = this.props;
    const { amount } = this.state;

    const modalTexts = texts.cardPage.card.cardLimits.editing[period][type];
    const currencySymbolRender = <span className="currency-symbol"> {currencySymbol} </span>;

    return (
      <Modal
        className="card-limits-editing-modal"
        onBack={onClose}
        modalTitle={modalTexts.title}
      >
        <div className="card-limits-editing-modal-wrapper">
          <p className="modal-description card-limits-editing-modal-description">
            {modalTexts.description(currencySymbol, card.limits[period][type].total || 0)}
          </p>

          <GhostInput
            value={amount}
            leftElement={currencySymbolRender}
            onEnter={this.onApplyLimit}
            onChange={this.onAmountChange}
            onBlur={this.onBlurLimitInput}
          />

          <Button
            label={texts.cardPage.card.cardLimits.editing.action}
            width="block"
            pinnedBottom
            onClick={this.onApplyLimit}
          />
        </div>

      </Modal>
    );
  }
}

function inject({ account }) {
  return {
    currencySymbol: currencySymbols[account.currency],
  };
}

function actions(dispatch) {
  return {
    onClose: () => dispatch(closeModal()),
    onLimitChange: (id: string, period: string, type: string, value: number, successMessage: string) => dispatch(setCardLimitConfig(id, period, type, value, successMessage)),
  };
}

export default connect(inject, actions)(LimitEditingModal);
