import { cardTypes } from './uiConfig';

export const texts = {
  accountPage: {
    accountTitle: {
      balance: 'Your account balance',
    },
    transactions: {
      statusTags: {
        reverted: 'Reverted',
        declined: 'Rejected',
        deposit: 'Account Top Up',
        adjustment: 'Account Adjustment',
      },
    },
    topUp: {
      title: 'Top-up your account',
      description: 'To top up your account you may use following IBAN credentials to make bank transfer.',
      credentials: {
        iban: {
          label: 'IBAN',
          message: 'IBAN number copied',
        },
        receiverName: {
          label: 'Receiver name',
          message: 'Receiver name copied',
        },
        swift: {
          label: 'SWIFT code',
          message: 'SWIFT code copied',
        },
        details: {
          label: 'Payment details',
          message: 'Payment details copied',
        },
      },
      action: 'Mail me account credentials',
      conditions: [
        'Please make payment in EUR currency. If funds are transferred in other currency, payment will be converted to EUR applying Swedbank’s exchange rate.',
        'Your payment will be processed within 1 working day upon receiving it.',
        'If the transaction exceeds 500 EUR, the funds will be returned',
      ],
      additionalInfo: [
        'Wallester AS, Parda 12, Tallinn, 10151, Estonia',
      ],
    },
    search: {
      filterPlaceholder: 'Type to search',
      emptyState: {
        title: 'No transactions found',
        description: 'We haven’t found any transactions with merchant name fitting requested one.\nWarning: some merchant has names different from their branding.',
        action: 'Clear',
      },
    },
  },
  cardPage: {
    card:{
      cardTypes: {
        [cardTypes.chipPin]: 'Physical card',
        [cardTypes.sticker]: 'Sticker card',
        [cardTypes.virtual]: 'Virtual card',
        [cardTypes.creating]: 'Virtual card',
      },
      expiresTitle: 'Valid to',
      menu: {
        freeze: {
          title: 'Freeze Card',
          subtitle: 'You may unfreeze card anytime',
        },
        unfreeze: {
          title: 'Unfreeze Card',
        },
        pin: {
          title: 'Remind PIN',
        },
        cvv2: {
          title: 'Remind CVV2',
        },
        wallet: {
          title: 'Wallet Integration',
        },
        security: {
          title: 'Card Security',
        },
        limits: {
          title: 'Limits',
        },
        report: {
          title: 'Report an issue',
        },
      },
      statusMessages: {
        numberCopied: 'Card number copied',
      },
      cardSecurity: {
        title: 'Card Security',
        description: 'We are providing real-time machine learning security services to protect your money from fraud actions. Anyway, you are allowed to manage your card transfer options.',
        config: {
          internetPurchase: {
            title: 'E-commerce',
            description: 'Transactions made with online payment systems and e-commerce',
          },
          magnetic: {
            title: 'Magnetic stripe',
            description: 'Transactions made with POS terminals without equipped chip or NFC reader',
          },
          nfc: {
            title: 'NFC',
            description: 'Transactions made with NFC readers and POS terminals',
          },
          withdrawal: {
            title: 'ATM',
            description: 'Cash withdraw made from a teller machine',
          },
          countries: {
            title: 'Other countries',
            description: 'Reject transactions if recipient location doesn’t match your delivery address country',
          },
        },
      },
      cardLimits:{
        overview: {
          title: 'Cards limits',
          description: 'Manage limits allowed for a specific type of transaction for this card. Changes will affect the current period.',
          types: {
            transaction: 'Transaction',
            day: 'Daily',
            month: 'Monthly',
          },
          options: {
            purchase: {
              title: 'POS',
              description: 'Amount of transactions made with the physical card',
            },
            withdrawal:{
              title: 'ATM',
              description: 'Amount of cash withdrawn from a teller machine',
            },
            internetPurchase: {
              title: 'E-commerce',
              description: 'Amount of transactions made with online payment systems and e-commerce',
            },
          },
          progress: {
            usage: 'Your card limit usage',
            noUsage: 'Your card limit is',
            notConfigured: 'Limit is not configured',
          },
        },
        editing: {
          daily: {
            purchase: {
              title: 'POS Daily limit',
              // TODO: w2lker - apply account limit output with this field by integrating account limits functionality
              description: (currency, amount) => `Manage daily limit allowed for transactions made with the physical card. Your current limit is ${currency}${amount}.`,
            },
            withdrawal: {
              title: 'ATM Daily limit',
              description: (currency, amount) => `Manage daily limit allowed to withdraw cash from teller machine. Your current limit is ${currency}${amount}`,
            },
            internetPurchase: {
              title: 'E-Commerce Daily limit',
              description: (currency, amount) => `Manage daily limit allowed for transactions made with online payment systems and e-commence. Your current limit is ${currency}${amount}`,
            },
          },
          monthly: {
            purchase: {
              title: 'POS Monthly limit',
              description: (currency, amount) => `Manage monthly limit allowed for transactions made with the physical card. Your current limit is ${currency}${amount}.`,
            },
            withdrawal: {
              title: 'ATM Monthly limit',
              description: (currency, amount) => `Manage monthly limit allowed to withdraw cash from teller machine. Your current limit is ${currency}${amount}`,
            },
            internetPurchase: {
              title: 'E-Commerce Monthly limit',
              description: (currency, amount) => `Manage monthly limit allowed for transactions made with online payment systems and e-commence. Your current limit is ${currency}${amount}`,
            },
          },
          transaction: {
            purchase: {
              title: 'POS transaction limit',
              description: (currency, amount) => `Manage limit allowed for single transaction made with the physical card. Your current limit is ${currency}${amount}.`,
            },
            withdrawal: {
              title: 'ATM transaction limit',
              description: (currency, amount) => `Manage limit allowed to single withdraw cash from teller machine. Your current limit is ${currency}${amount}`,
            },
            internetPurchase: {
              title: 'E-Commerce transaction limit',
              description: (currency, amount) => `Manage limit allowed for single transaction made with online payment systems and e-commence. Your current limit is ${currency}${amount}`,
            },
          },
          action: 'Apply limit',
          successMessage: (limitDefinition: string) => `Your ${limitDefinition} has been successfully modified`,
        },
      },
      receivePINCVV: {
        PIN: {
          title: 'PIN code has been sent',
          description: 'Your card PIN code has been sent to you via SMS on your contact phone number. Please check your phone. In case you haven’t receive message in few minutes — create new request',
          action: 'Done',
        },
        CVV: {
          title: 'CVV2 code has been sent',
          description: 'Your card CVV2 code has been sent to you via SMS on your contact phone number. Please check your phone. In case you haven’t receive message in few minutes — create new request',
          action: 'Done',
        },
      },
      creating: {
        title: 'Creating virtual card',
        description: '<p>You are about to create virtual card. This card can be used for internet purchases and bring additional security level for your account with additional card credentials.</p><p>Virtual card issuing is completely free. To get additional information about virtual card services and legal documents <a href="https://wallester.eu" target="_blank">click here.</a></p>',
        label: 'Create Virtual Card',
      },
      producing: {
        title: 'Card is in production',
        description: '<p>Your card is on printing and personalizing stage. Usually, this process takes 3-5 working days and we will send you parcel with your brand new card.</p><p>Once you will receive your card please log into this admin again to activate card and start using card features.</p>',
      },
      activating: {
        title: 'Activate your card',
        description: '<p>Your card card been produced and delivering to you. Please active card here once you receive package with card.</p><p>By activativing card you confirm that you received card, it has propriate condition and credentials on a card match displayed on this screen</p>',
        action: 'Activate card',
      },
      blocked: {
        title: 'Card is blocked',
        description: '<p>This card has been blocked by the bank.</p><p>Please contact support team to get blocked reason details and steps to unblock this card</p>',
      },
    },
  },
  settingsPage: {
    menu: {
      profile: {
        title: 'Profile',
        deliveryAddress: 'Delivery Address',
        timezone: 'Time zone',
        phone: 'Phone',
        email: 'Email',
      },
      security: {
        title: 'Security',
        password: 'Change password',
        limits: 'Limits',
      },
      legal: {
        title: 'Legal documents',
        terms: 'Terms & Conditions',
        privacy: 'Privacy Policy',
      },
      other: {
        faq: 'FAQ',
        support: 'Contact support',
        logout: 'Logout',
      },
    },
    deliveryAddress: {
      title: 'Delivery address',
      description: '<p>This address will be used for all delivery purposes (new cards, bank letters, reports and analytics, special offers and contracts).</p> <p>Please ensure you have entered valid address and you will have access to the mailing box on this address for the next few weeks after you request delivery from the bank.</p>',
      country: 'Country',
      region: 'Region/Province',
      postal: 'Postal code',
      city: 'City',
      addressLine1: 'Address line 1',
      addressLine2: 'Address line 2',
      actions: {
        update: 'Update',
        cancel: 'Cancel',
      },
      errorMessages: {
        requiredPrompt: 'This field is required',
      },
      countryPicker: {
        searchPlaceholder: 'Enter country name',
        emptySearch: 'No countries matched your search request. Please check your request. If there is no country you are looking for — please contact the support team.',
      },
    },
    passwordPage: {
      title: 'Password',
      passwordForm: {
        description: '<p>You may change the account password with form below.</p> <p><strong>Warning:</strong> to apply changes you will be prompted to verify password change with email.</p>',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        tooltip: 'The password should be not less then 8 characters long; must contain at least one letter and numeric digit',
        confirmPassword: 'Confirmation',
        update: 'Change',
        errorMessages: {
          required: 'This field is required',
          passwordDoesntMatch: 'Password doesn\'t match account password',
          passwordComplexity: 'New password is too weak. Password should not less then 8 characters; must contain at least one letter and numeric digit.',
          confirmationMatch: 'Confirmation doesn\'t match new password',
        },
      },
      verifyForm: {
        stepper: 'Step 2',
        title: 'Verify Password Change',
        description: 'We have sent you the mail with verification code and verification link. You may open one-time verification link or copy verification code and paste it in the field below. If you have not received mail please check the spam folder.',
        input: {
          label: 'Verification code',
          placeholder: 'Enter verification code here',
          errorPrompt: 'This field is required',
        },
        action: 'Verify',
      },
      completenessTexts: {
        title: 'Password is updated',
        description: 'Your password changes successfully completed. You will be logged out of the application. Please login with your new password.',
        action: 'Done',
      },
    },
    faqPage: {
      title: 'FAQ',
      searchPlaceholder: 'Enter your question',
    },
  },
  navigation: {
    account: {
      title: 'Account',
    },
    analytics: {
      title: 'Analytics',
    },
    cards: {
      title: 'Cards',
    },
    more: {
      title: 'More',
    },
  },
  login: {
    loginForm: {
      title: 'Cardholder Portal ',
      description: 'Welcome! Use login data provided by Wallester to enter your account',
      invalid: 'Email or password are invalid',
      login: {
        placeholder: 'Your email',
      },
      password: {
        placeholder: 'Password',
      },
      action: {
        title: 'Login',
        forgotPassword: 'Forgot Password',
      },
    },
    sendRecoveryEmail: {
      title: 'Reset password',
      step1: {
        stepper: 'Step 1',
        title: 'Initiate reset password process',
        description: 'Enter your email below and we\'ll email you a link to reset your password.',
        emailLabel: 'Email address',
        emailPlaceholder: 'Your email',
        action: 'Send recover email',
        errorPrompts: {
          invalid: 'Please provide valid email address',
          unregistered: 'Email you provided hasn\'t been found. Please check data entered or contact bank for additional support',
        },
      },
      step2: {
        title: 'Mail has been sent',
        description: 'Please check your inbox. You will receive a link to reset the password within few minutes. Follow the instructions to complete the process.',
        action: 'Back to login',
      },
    },
    recoverPassword: {
      title: 'Reset password',
      step1: {
        stepper: 'Step 2',
        title: 'New password',
        description: 'Create a new password you will use <br/> to log into your account.',
        passwordLabel: 'New Password',
        passwordHint: 'The password must be at least 8 characters long and contain at least one letter and one digit',
        confirmationLabel: 'Confirmation',
        actions: {
          update: 'Update',
          cancel: 'Cancel',
        },
        errorMessages: {
          required: 'This field is required',
          passwordComplexity: 'New password is too weak. The password must be at least 8 characters long and contain at least one letter and one digit.',
          confirmationMatch: 'Confirmation doesn\'t match password',
        },
      },
      step2: {
        title: 'Password has been updated',
        description: 'Your account password has been successfully updated. Use your new password to login. All previous sessions will be logged out.',
        action: 'Back to login',
      },
      invalidToken: {
        title: 'Recovery link is not active',
        description: 'This password recovery link is not active anymore. It may already be taken or outdated. To get access to password recovery functionality please generate new recovery request from login page',
        action: 'Back to login',
      },
    },
  },
  snackbar: {
    serverError: 'Server error:',
  },
  general: {
    formErrorMessages: {
      required: 'This field is required',
      passwordComplexity: 'New password is too weak. The password must be at least 8 characters long and contain at least one letter and one digit',
      confirmationMatch: 'Confirmation doesn\'t match password',
      invalidEmail: 'Please provide valid email address',
    },
  },
};
