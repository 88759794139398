import * as React from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';

interface IGhostInputProps {
  className?: any;
  type?: string;
  value: string;
  leftElement: React.ReactElement;

  onChange: (newValue: string, event) => any;
  onEnter?: () => any;
  onBlur?: () => any;
}

interface IGhostInputState {
  width: number;
}

class GhostInput extends React.Component <IGhostInputProps, IGhostInputState> {

  hiddenControllerRef;

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
    this.refHiddenController = this.refHiddenController.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onInputKeyPress = this.onInputKeyPress.bind(this);
  }

  componentDidMount(): void {
    this.configureWidth();
  }

  // tslint:disable-next-line:max-line-length
  componentDidUpdate(prevProps: Readonly<IGhostInputProps>, prevState: Readonly<IGhostInputState>, snapshot?: any): void {
    this.configureWidth();
  }

  refHiddenController(element) {
    this.hiddenControllerRef = element;
  }

  onChange(event) {
    this.props.onChange(event.target.value, event);
  }

  onInputKeyPress(event) {
    const { onEnter } = this.props;
    if (event.key === 'Enter') {
      return onEnter && onEnter();
    }
  }

  configureWidth() {
    const controllerWidth = this.hiddenControllerRef.offsetWidth;
    if (controllerWidth !== this.state.width) {
      this.setState({ width: controllerWidth });
    }
  }

  render() {
    const { className, value, leftElement, onBlur } = this.props;
    const wrapperClass = classnames('input-wrapper input-ghost', className);
    const type = this.props.type || 'number';

    return (
      <div className={wrapperClass}>
        {leftElement}
        <input
          className="input"
          value={value}
          type={type}
          maxLength={6}
          style={{
            width: this.state.width + 15,
          }}
          onChange={this.onChange}
          onKeyPress={this.onInputKeyPress}
          onBlur={onBlur}
        />
        <div className="hidden-wrapper">
          <span
            className="hidden-controller"
            ref={this.refHiddenController}
          >
            {value}
          </span>
        </div>
      </div>
    );
  }

}

export default GhostInput;
