import * as React from 'react';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import Accordion from '../../../Basic/Accordion/Accordion';
import MenuItem from '../../../Basic/MenuItem/MenuItem';
import DeliveryAddress from '../DeliveryAddress/DeliveryAddress';
import FaqPage from '../FaqPage/FaqPage';
import PasswordPage from '../PasswordPage/PasswordPage';

import { logout } from '../../../../actions/auth';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { IProfile, IProfileAddress } from '../../../../models/profile';
import { getRoutedByUserLink } from '../../../../utils/routing';

import { displayCountryName } from '../../../../utils/ui';

interface ISettingsMenuProps {
  address: IProfileAddress;
  email: string;
  phone: string;
  timezone: string;
  logout: () => any;
}

class SettingsMenu extends React.Component <ISettingsMenuProps, {}> {
  render() {
    const { address, phone, email } = this.props;

    const menuTexts = texts.settingsPage.menu;
    const addressDisplay = [address.addressLine1, address.addressLine2, address.city, displayCountryName(address.country)]
      .filter(value => !!value)
      .join(', ');

    const menuLink = `${routingPaths.settings.root}/`;

    return (
      <div className="menu settings-menu">
        <div className="menu-section">
          <h5 className="menu-section-title"> {menuTexts.profile.title} </h5>
          <MenuItem
            icon="map-marker"
            title={menuTexts.profile.deliveryAddress}
            subtitle={addressDisplay}
            to={getRoutedByUserLink(menuLink + routingPaths.settings.deliveryAddress)}
          />
          <MenuItem
            icon="clock"
            title={menuTexts.profile.timezone}
            subtitle={this.props.timezone}
          />
          { phone && (
            <MenuItem
              icon="phone"
              title={menuTexts.profile.phone}
              subtitle={phone}
            />
          )}
          { email && (
            <MenuItem
              icon="email"
              title={menuTexts.profile.email}
              subtitle={email}
            />
          )}
        </div>

        <div className="menu-section">
          <h5 className="menu-section-title"> {menuTexts.security.title} </h5>
          <MenuItem
            icon="lock"
            title={menuTexts.security.password}
            to={getRoutedByUserLink(menuLink + routingPaths.settings.password)}
          />
          {/*<MenuItem*/}
          {/*  icon="poll-box"*/}
          {/*  title={menuTexts.security.limits}*/}
          {/*/>*/}
        </div>
        <Accordion
          isOpen
          title={menuTexts.legal.title}
          className="menu-section"
        >
          <MenuItem
            icon="file-document"
            title={menuTexts.legal.terms}
            to={routingPaths.settings.legal.terms}
          />
          <MenuItem
            icon="file-document"
            title={menuTexts.legal.privacy}
            to={routingPaths.settings.legal.privacy}
          />
        </Accordion>
        <div className="menu-section">
          <MenuItem
            icon="comment-question"
            title={menuTexts.other.faq}
            to={getRoutedByUserLink(menuLink + routingPaths.settings.faq)}
          />
          {/*<MenuItem*/}
          {/*  icon="headset"*/}
          {/*  title={menuTexts.other.support}*/}
          {/*/>*/}
          <MenuItem
            icon="logout"
            title={menuTexts.other.logout}
            to={routingPaths.home}
            onClick={this.props.logout}
          />
        </div>
        <Switch>
          <Route
            path={menuLink + routingPaths.settings.deliveryAddress}
            component={DeliveryAddress}
          />
          <Route
            path={menuLink + routingPaths.settings.password}
            component={PasswordPage}
          />
          <Route
            path={menuLink + routingPaths.settings.faq}
            component={FaqPage}
          />
        </Switch>
      </div>
    );
  }
}

function inject({ profile }) {
  const profileData: IProfile = profile.profile;
  return {
    address: profileData.address,
    email: profileData.email,
    phone: profileData.phone,
    timezone: profileData.timezone,
  };
}

function actions(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

export default connect(inject, actions)(SettingsMenu);
