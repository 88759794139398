export type ProfileErrorType = 'profile/previous-password-invalid';

interface IProfileErrorCodes {
  passwordInvalid: ProfileErrorType;
}

export const profileErrorCodes: IProfileErrorCodes = {
  passwordInvalid: 'profile/previous-password-invalid',
};

export interface IProfileAddress {
  country: string;
  region?: string;
  postalCode?: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
}

export interface IProfile {
  avatar: string;
  id: string;
  displayName: string;
  address: IProfileAddress;
  email: string;
  phone: string;
  timezone: string;
}

export interface IProfileReducer {
  profile: IProfile;
  error: ProfileErrorType;
}
