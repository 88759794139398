import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import MenuItem from '../../../../../Basic/MenuItem/MenuItem';
import CardLimits from '../../../CardLimits/CardLimits';
import CardSecurity from '../../../CardSecurity/CardSecurity';

import { ICard } from '../../../../../../models/cards';
import SecureCredentialsNotificationModal from '../../../SecureCredentialsNotificationModal/SecureCredentialsNotificationModal';

import {
  getCardCVVReminder,
  getCardPINReminder,
  setCardToFrozenState,
  setCardToUnfreezeState,
} from '../../../../../../actions/cards';

import { cardStatus } from '../../../../../../constants/cards';
import { texts } from '../../../../../../constants/lang';
import { routingPaths } from '../../../../../../constants/routing';
import { cardTypes } from '../../../../../../constants/uiConfig';
import { getRoutedByUserLink } from '../../../../../../utils/routing';

interface ICardMenuProps {
  content: ICard;
  getCardPINReminder: (id: string, content: React.ReactElement) => any;
  getCardCVVReminder: (id: string, content: React.ReactElement) => any;
  freezeCard: (id: string) => any;
  unfreezeCard: (id: string) => any;
}

class SingleCardMenu extends React.Component <ICardMenuProps, {}> {
  constructor(props) {
    super(props);

    this.onPINRemind = this.onPINRemind.bind(this);
    this.onCVVRemind = this.onCVVRemind.bind(this);
    this.onFreezeCard = this.onFreezeCard.bind(this);
    this.onUnfreezeCard = this.onUnfreezeCard.bind(this);
    this.renderCardSecuritySection = this.renderCardSecuritySection.bind(this);
    this.renderCardLimitsSection = this.renderCardLimitsSection.bind(this);
  }

  onPINRemind() {
    // tslint:disable-next-line:no-shadowed-variable
    const { content, getCardPINReminder } = this.props;
    const modalTexts = texts.cardPage.card.receivePINCVV.PIN;
    const modal = (
      <SecureCredentialsNotificationModal
        title={modalTexts.title}
        description={modalTexts.description}
        actionText={modalTexts.action}
      />
    );
    getCardPINReminder(content.id, modal);
  }

  onCVVRemind() {
    // tslint:disable-next-line:no-shadowed-variable
    const { content, getCardCVVReminder } = this.props;
    const modalTexts = texts.cardPage.card.receivePINCVV.CVV;
    const modal = (
      <SecureCredentialsNotificationModal
        title={modalTexts.title}
        description={modalTexts.description}
        actionText={modalTexts.action}
      />
    );
    getCardCVVReminder(content.id, modal);
  }

  onFreezeCard() {
    const { content, freezeCard } = this.props;
    freezeCard(content.id);
  }

  onUnfreezeCard() {
    const { content, unfreezeCard } = this.props;
    unfreezeCard(content.id);
  }

  renderCardSecuritySection(props) {
    return <CardSecurity {...props} id={this.props.content.id} />;
  }

  renderCardLimitsSection(props) {
    return <CardLimits {...props} id={this.props.content.id} />;
  }

  render() {
    const { content } = this.props;
    const menuLink = `${routingPaths.card.root}/${content.id}/`;
    const menuTexts = texts.cardPage.card.menu;
    const cardType = content.type;

    return (
      <React.Fragment>
        { content.status !== cardStatus.blocked && (
          <MenuItem
              icon="asterisk"
              title={menuTexts.freeze.title}
              subtitle={menuTexts.freeze.subtitle}
              onClick={ this.onFreezeCard }
          />
        )}
        {content.status === cardStatus.blocked && (
          <MenuItem
            icon="fire"
            title={menuTexts.unfreeze.title}
            onClick={ this.onUnfreezeCard }
          />
        )}

        { cardType !== cardTypes.virtual && (
          <MenuItem
              icon="lock"
              title={menuTexts.pin.title}
              onClick={ this.onPINRemind }
          />
        )}
        {/*TODO: add CVV2 icon as custom imported icon*/}
        <MenuItem
            icon="shield-key"
            title={menuTexts.cvv2.title}
            onClick={ this.onCVVRemind }
        />
        { cardType !== cardTypes.virtual && (
          <MenuItem
              icon="security"
              title={ menuTexts.security.title }
              to={ getRoutedByUserLink(menuLink + routingPaths.card.security) }
          />
        )}
        {/*TODO: add limits icon as custom imported icon*/}
        <MenuItem
            icon="chart-bar"
            title={menuTexts.limits.title}
            to={ getRoutedByUserLink(menuLink + routingPaths.card.limits)}
        />

        <Switch>
            <Route
                path={menuLink + routingPaths.card.security}
                component={(props) => (
                    <CardSecurity
                        {...props}
                        id={content.id}
                    />)
                }
            />
          <Route
            path={menuLink + routingPaths.card.limits}
            component={this.renderCardLimitsSection}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    getCardPINReminder: (id, content) => dispatch(getCardPINReminder(id, content)),
    getCardCVVReminder: (id, content) => dispatch(getCardCVVReminder(id, content)),
    freezeCard: (id) => dispatch(setCardToFrozenState(id)),
    unfreezeCard: (id) => dispatch(setCardToUnfreezeState(id)),
  };
}

export default connect(inject, actions)(SingleCardMenu);
