import { List } from 'immutable';

import {
  CARD_ACTIVATE,
  CARD_CREATE,
  CARD_FROZEN_SET,
  CARD_FROZEN_UNSET,
  CARD_LIMIT_SETTINGS_GET,
  CARD_LIMIT_SETTINGS_SET,
  CARD_MASK_ALL_NUMBERS,
  CARD_SECURE_SETTINGS_SET,
  CARD_UNMASK_NUMBER,
  CARDS_LIST,
  CardsActions,
} from '../actions/cards';

import { blockType, cardStatus } from '../constants/cards';
import { ICardReducer } from '../models/cards';

const initialState: ICardReducer = {
  cards: List(),
};

export default function cardsReducer(state = initialState, action: CardsActions) {
  switch (action.type) {

    case CARD_CREATE: {
      return {
        ...state,
        cards: state.cards.push(action.payload),
      };
    }

    case CARDS_LIST:
      return {
        ...state,
        cards: action.payload,
      };

    case CARD_UNMASK_NUMBER: {
      const currentCardIndex = state.cards.findIndex((item) => item.id === action.payload.id);
      const updatedCard = {
        ...state.cards.get(currentCardIndex),
        number: action.payload.number,
      };
      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }

    case CARD_MASK_ALL_NUMBERS: {
      return {
        ...state,
        cards: action.payload,
      };
    }

    case CARD_ACTIVATE: {
      const currentCardIndex = state.cards.findIndex((item) => item.id === action.payload);
      const updatedCard = {
        ...state.cards.get(currentCardIndex),
        status: cardStatus.active,
      };

      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }

    case CARD_FROZEN_SET: {
      const currentCardIndex = state.cards.findIndex((item) => item.id === action.payload);
      const updatedCard = {
        ...state.cards.get(currentCardIndex),
        status: cardStatus.blocked,
        blockType: blockType.blockedByCardholder,
      };

      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }

    case CARD_FROZEN_UNSET: {
      const currentCardIndex = state.cards.findIndex((item) => item.id === action.payload);
      const updatedCard = {
        ...state.cards.get(currentCardIndex),
        status: cardStatus.active,
        blockType: null,
      };

      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }

    case CARD_SECURE_SETTINGS_SET: {
      const currentCardIndex = state.cards.findIndex((item) => item.id === action.payload.id);
      const updatedCard = {
        ...state.cards.get(currentCardIndex),
        security: action.payload.security,
      };

      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }
    case CARD_LIMIT_SETTINGS_GET: {
      const currentCardIndex = state.cards.findIndex((item) => item.id === action.payload.id);
      const updatedCard = {
        ...state.cards.get(currentCardIndex),
        limits: action.payload.limits,
      };

      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }
    case CARD_LIMIT_SETTINGS_SET: {
      const { payload } = action;
      const currentCardIndex = state.cards.findIndex((item) => item.id === payload.id);
      const previousCard = state.cards.get(currentCardIndex);
      const updatedCard = {
        ...previousCard,
        limits: {
          ...previousCard.limits,
          [payload.period]: {
            ...previousCard.limits[payload.period],
            [payload.type]: {
              ...previousCard.limits[payload.period][payload.type],
              total: payload.amount,
            },
          },
        },
      };

      return {
        ...state,
        cards: state.cards.set(currentCardIndex, updatedCard),
      };
    }
    default:
      return state;
  }
}
