import * as React from 'react';

import { connect } from 'react-redux';

import CardMessageMenu from '../CardMessageMenu/CardMessageMenu';

import { createVirtualCard } from '../../../../../../actions/cards';
import { texts } from '../../../../../../constants/lang';

interface ICardCreateBlockProps {
  onCreate: () => any;
}

class CardCreateBlock extends React.Component <ICardCreateBlockProps, {}> {
  render() {
    const creationTexts = texts.cardPage.card.creating;
    return (
      <CardMessageMenu
        className="card-create"
        title={creationTexts.title}
        descriptionHtml={creationTexts.description}
        label={creationTexts.label}
        action={this.props.onCreate}
      />
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onCreate: () => dispatch(createVirtualCard()),
  };
}

export default connect(inject, actions)(CardCreateBlock);
