
require.register("nth-check/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "nth-check");
  (function() {
    var parse = require("./parse.js"),
    compile = require("./compile.js");

module.exports = function nthCheck(formula){
	return compile(parse(formula));
};

module.exports.parse = parse;
module.exports.compile = compile;
  })();
});