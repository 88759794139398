import * as React from 'react';

import classnames from 'classnames';

interface IProgressProps {
  title: string;
  description?: string;
  value: number;
  max: number;
}

// tslint:disable-next-line:function-name
function Progress(props: IProgressProps) {
  const { title, description, value, max } = props;
  const fillerWidth = value * 100 / max;

  const widgetFillerClass = classnames({
    'progress-widget-filler': true,
    empty: value === 0,
    warning: fillerWidth >= 75 && fillerWidth < 90,
    reached: fillerWidth >= 90,
  });

  return (
    <div className="progress-wrapper">
      <div className="progress-content">
        <span className="progress-title"> {title} </span>
        {
          description &&
          <span className="progress-description"> {description} </span>
        }
      </div>
      <div className="progress-widget">
        <div className={widgetFillerClass}
             style={{ width: `${fillerWidth}%` }}
        />
      </div>
    </div>
  );
}

export default Progress;
