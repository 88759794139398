import { routingPaths } from '../constants/routing';

export function checkAccountPath(location) {
  return location === routingPaths.home || location.indexOf(routingPaths.account.root) === 0;
}

export function checkCardPath(location) {
  return location.indexOf(routingPaths.card.root) === 0;
}

export function checkSettingsPath(location) {
  return location.indexOf(routingPaths.settings.root) === 0;
}

export function getCardPath(cardID: string) {
  return `${routingPaths.card.root}/${cardID}`;
}

export function getRoutedByUserLink(to: string) {
  return {
    pathname: to,
    state: {
      routerByUser: true,
    },
  };
}
