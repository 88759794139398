// import api from '../api/api';
import api from '../api/fakeApi';
import { IProfile, IProfileAddress } from '../models/profile';
import { handleErrors } from './error';
import { hideRequestSpinner, showRequestSpinner } from './ui/spinner';

export const PROFILE_CHANGE_PASSWORD = 'PROFILE/CHANGE_PASSWORD';
export const PROFILE_CHANGE_PASSWORD_ERROR = 'PROFILE/CHANGE_PASSWORD_ERROR';
export const PROFILE_CHANGE_PASSWORD_CLEAN_ERROR = 'PROFILE/CHANGE_PASSWORD_CLEAN_ERROR';
export const PROFILE_CHANGE_ADDRESS = 'PROFILE/CHANGE_ADDRESS';
export const PROFILE_GET = 'PROFILE/GET';

interface IProfileGet {
  type: typeof PROFILE_GET;
  payload: IProfile;
}

export function convertProfileResponse(content): IProfile {
  return {
    // Demo avatar url is '/img/profile-avatar.png'
    avatar: '',
    id: content.id,
    displayName: [content.first_name, content.last_name].join(' '),
    address: {
      country: content.address.country,
      postalCode: content.address.postal_code,
      city: content.address.city,
      addressLine1: content.address.address_line_1,
      addressLine2: content.address.address_line_2,
    },
    email: content.email,
    phone: content.phone,
    timezone: content.timezone,
  };
}

export function me() {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.me().then((content) => {
      // tslint:disable-next-line:no-console
      dispatch({
        type: PROFILE_GET,
        payload: convertProfileResponse(content),
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface IChangePassword {
  type: typeof PROFILE_CHANGE_PASSWORD;
}

interface IChangePasswordError {
  type: typeof PROFILE_CHANGE_PASSWORD_ERROR;
}

function showChangePasswordError() {
  return (dispatch) => {
    dispatch({ type: PROFILE_CHANGE_PASSWORD_ERROR });
  };
}

interface IChangePasswordCleanError {
  type: typeof PROFILE_CHANGE_PASSWORD_CLEAN_ERROR;
}

function hideChangePasswordError() {
  return (dispatch) => {
    dispatch({ type: PROFILE_CHANGE_PASSWORD_CLEAN_ERROR });
  };
}

export function changePassword(currentPassword: string, newPassword: string, onSuccess: () => any) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    dispatch(hideChangePasswordError());

    return api.changePassword(currentPassword, newPassword).then(() => {
      dispatch({
        type: PROFILE_CHANGE_PASSWORD,
      });
      dispatch(hideRequestSpinner());
      onSuccess();
    }).catch((e) => {
      dispatch(hideRequestSpinner());
      if (e.code === 400) {
        dispatch(showChangePasswordError());
      } else {
        throw e;
      }
    });
  });
}

interface IUpdateAddress {
  type: typeof PROFILE_CHANGE_ADDRESS;
  payload: IProfileAddress;
}

export function updateAddress(address: IProfileAddress) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.updateAddress({
      country: address.country,
      postal_code: address.postalCode,
      city: address.city,
      address_line_1: address.addressLine1,
      address_line_2: address.addressLine2,
    }).then(() => {
      dispatch(hideRequestSpinner());
      dispatch({
        type: PROFILE_CHANGE_ADDRESS,
        payload: address,
      });
    });
  });
}

export type ProfileActions = IChangePassword | IChangePasswordError | IChangePasswordCleanError | IProfileGet | IUpdateAddress;
