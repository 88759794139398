import * as React from 'react';

import classnames from 'classnames';
import Icon from '../../Icon/Icon';

export interface IInputProps {
  className?: any;

  value?: any;
  onChange?: (newValue: string, event?: any) => any;
  onClick?: (event?: any) => any;
  onEnter?: () => any;

  disabled?: boolean;
  autoFocusDelay?: number;
  required?: boolean;
  type?: string;

  label?: string;
  placeholder?: string;
  errorText?: any;
  hintText?: string;
  icon?: string;
  widget?: React.ReactElement;
}

interface IInputState {
  focused: boolean;
}

class Input extends React.Component <IInputProps, IInputState> {

  inputRef;
  focusTimer;

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };

    this.refInputElement = this.refInputElement.bind(this);
    this.handleFocusDelay = this.handleFocusDelay.bind(this);
    this.onInputKeyPress = this.onInputKeyPress.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
  }

  componentDidMount(): void {
    const { autoFocusDelay } = this.props;
    if (autoFocusDelay) {
      this.handleFocusDelay(autoFocusDelay);
    }
  }

  componentWillReceiveProps(nextProps: IInputProps) {
    const { autoFocusDelay } = nextProps;
    if (autoFocusDelay && autoFocusDelay !== this.props.autoFocusDelay) {
      // If previous timer haven't completed - refresh it with new timer
      clearTimeout(this.focusTimer);
      this.handleFocusDelay(autoFocusDelay);
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.focusTimer);
  }

  handleFocusDelay(timeout: number) {
    if (Number.isInteger(timeout)) {
      this.focusTimer = setTimeout(() => {
        this.setState({
          focused: true,
        });
        this.inputRef.focus();
      }, timeout);
    }
  }

  refInputElement(element) {
    this.inputRef = element;
  }

  onInputKeyPress(event) {
    const { onEnter } = this.props;
    if (event.key === 'Enter') {
      return onEnter && onEnter();
    }
  }

  onInputChange(event) {
    this.props.onChange(event.target.value, event);
  }
  onInputFocus() {
    this.setState({ focused: true });
  }
  onInputBlur() {
    this.setState({ focused: false });
  }

  render() {
    const {className, value, disabled, required, type, label, placeholder, errorText,
      hintText, icon, widget, onClick} = this.props;

    const { focused } = this.state;

    const wrapperClass = classnames({
      'input-wrapper': true,
      'input-disabled': disabled,
      'input-error': errorText,
      'input-focused': focused,
      'input-required': required,
      // tslint:disable-next-line:align
    }, className);

    // TODO: add wrapper to place hints and errors based on input appearance
    const hintRender = hintText && (
      <p className="input-hint"> { hintText } </p>
    );
    const errorRender = errorText && (
      <p className="input-hint-error"> { errorText } </p>
    );

    const requiredAsterisk = required ? (
      <span className="asterisk"> *</span>
    ) : null;

    return (
      <div className={wrapperClass} onClick={onClick}>
        {label && (
          <p className="input-label">{label}{requiredAsterisk}</p>
        )}

        <div className="input-inner">
          {icon && (
            <Icon name={icon} className="input-icon" />
          )}

          <input
            ref={this.refInputElement}
            className="input-element"
            type={type}

            value={value}
            placeholder={placeholder}
            disabled={disabled}

            onKeyPress={this.onInputKeyPress}
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
          />

          {widget && (
            <div className="input-widget">{widget}</div>
          )}
        </div>
        {hintRender}
        {errorRender}
      </div>
    );

  }
}

export default Input;
