export const UI_PRELOAD_SPINNER_SHOW = 'UI/PRELOAD_SPINNER/SHOW';
export const UI_PRELOAD_SPINNER_HIDE = 'UI/PRELOAD_SPINNER/HIDE';
export const UI_REQUEST_SPINNER_SHOW = 'UI/REQUEST_SPINNER/SHOW';
export const UI_REQUEST_SPINNER_HIDE = 'UI/REQUEST_SPINNER/HIDE';

interface IPreloadSpinnerShow {
  type: typeof UI_PRELOAD_SPINNER_SHOW;
  payload: number;
}

export function showPreloadSpinner(counter: number) {
  return (dispatch) => {
    dispatch({
      type: UI_PRELOAD_SPINNER_SHOW,
      payload: counter,
    });
  };
}

interface IPreloadSpinnerHide {
  type: typeof UI_PRELOAD_SPINNER_HIDE;
}

export function hidePreloadSpinner() {
  return (dispatch) => {
    dispatch({
      type: UI_PRELOAD_SPINNER_HIDE,
    });
  };
}

interface IRequestSpinnerShow {
  type: typeof UI_REQUEST_SPINNER_SHOW;
}

export function showRequestSpinner() {
  return (dispatch) => {
    dispatch({
      type: UI_REQUEST_SPINNER_SHOW,
    });
  };
}

interface IRequestSpinnerHide {
  type: typeof UI_REQUEST_SPINNER_HIDE;
}

export function hideRequestSpinner() {
  return (dispatch) => {
    dispatch({
      type: UI_REQUEST_SPINNER_HIDE,
    });
  };
}

export type SpinnerActions = IPreloadSpinnerShow | IPreloadSpinnerHide | IRequestSpinnerShow | IRequestSpinnerHide;
