import * as React from 'react';

import { InjectedProps, withRipple } from '@material/react-ripple';
import classnames from 'classnames';
import * as History from 'history';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import Icon from '../Icon/Icon';

interface IMenuItemProps extends InjectedProps<HTMLDivElement> {
  icon?: string;
  iconWidget?: React.ReactElement;
  title: string | React.ReactElement;
  subtitle?: string;
  children?: React.ReactElement;
  to?: History.LocationDescriptor;
  bottomWidget?: React.ReactElement;
  onClick?: () => any;
  className?: any;
  initRipple?: React.Ref<HTMLDivElement>;
}

class MenuItem extends React.Component <IMenuItemProps, {}> {
  render() {
    // tslint:disable-next-line:max-line-length
    const { icon, iconWidget, title, subtitle, children, to, onClick,
      initRipple, className, bottomWidget, ...other } = this.props;

    const isClickable = onClick || to;
    const wrapperClass = classnames({
      'menu-item-wrapper': true,
      'menu-item-non-clickable': !isClickable,
    });
    const rippleWrapperClasses = classnames({
      'ripple-wrapper': isClickable,
    }, className);

    return (
      <ConditionalWrapper
        className={wrapperClass}
        onClick={onClick}
        to={to}
      >
        <div
          className={rippleWrapperClasses}
          ref={initRipple}
          {...other}
        >
          <div className="menu-item-wrapper-inner">
            {icon && (
              <div className="icon-wrapper">
                <Icon name={icon} />
              </div>
            )}
            {!icon && iconWidget}
            <div className="menu-item-body">
              <h5 className="menu-item-title"> {title} </h5>
              {subtitle && (
                <p className="menu-item-subtitle"> {subtitle} </p>
              )}
            </div>
            {
              children &&
              <div className="menu-item-widget">
                {children}
              </div>
            }
          </div>
          {bottomWidget && (
            <div className="menu-item-bottom-widget">
              {bottomWidget}
            </div>
          )}
        </div>
      </ConditionalWrapper>
    );
  }
}

export default withRipple(MenuItem);
