import * as moment from 'moment';

// import api from '../api/api';
import api from '../api/fakeApi';

import { texts } from '../constants/lang';
import { singleTransactionTypes } from '../constants/transactions';
import { ISingleTransaction } from '../models/transactions';
import { handleErrors } from './error';
import { hidePreloadSpinner } from './ui/spinner';

export const TRANSACTIONS_UPDATE_LIST = 'TRANSACTIONS/UPDATE_LIST';
export const TRANSACTIONS_UPDATE_DONE = 'TRANSACTIONS/UPDATE_DONE';
export const TRANSACTIONS_LOAD_LIST = 'TRANSACTIONS/LOAD_LIST';

interface IAccountTransactionsInitial {
  type: typeof TRANSACTIONS_LOAD_LIST;
  payload: ISingleTransaction[];
}

export function initialAccountTransactions() {
  return handleErrors((dispatch) => {
    return accountTransactions(0, 25, null, new Date()).then((transactions) => {
      if (transactions) {
        dispatch({
          type: TRANSACTIONS_LOAD_LIST,
          payload: transactions,
        });
      } else {
        dispatch({
          type: TRANSACTIONS_UPDATE_DONE,
        });
      }
      dispatch(hidePreloadSpinner());
    });
  // tslint:disable-next-line:align
  }, hidePreloadSpinner);
}

interface IAccountTransactionsAdditional {
  type: typeof TRANSACTIONS_UPDATE_LIST;
  payload: ISingleTransaction[];
}

interface IAccountTransactionsDone {
  type: typeof TRANSACTIONS_UPDATE_DONE;
}

export function getAdditionalAccountTransactions(toDate: Date) {
  return handleErrors((dispatch) => {
    const updatedDate = moment(toDate).subtract(1, 'seconds').toDate();
    return accountTransactions(0, 25, null, updatedDate).then((transactions) => {
      if (transactions) {
        dispatch({
          type: TRANSACTIONS_UPDATE_LIST,
          payload: transactions,
        });
      } else {
        dispatch({
          type: TRANSACTIONS_UPDATE_DONE,
        });
      }
    });
  });
}

export function getAdditionalSearchTransactions(toDate: Date, filter: string) {
  return getAdditionalAccountTransactions(toDate);
}

function accountTransactions(fromRecord: number, recordCount: number, fromDate?: Date, toDate?: Date) {
  return api.accountTransactions(fromRecord, recordCount, fromDate, toDate)
    .then((transactions) => {
      // @ts-ignore
      if (!transactions || transactions.length === 0) {
        return null;
      }

      // @ts-ignore
      return transactions.map((tx) => {
        // @ts-ignore
        return {
          id: tx.id,
          amount: tx.account_amount || 0,
          originalAuthorizationId: tx.original_authorization_id || '',
          currency: tx.account_currency_code || '',
          cardNumber: tx.card_number || '',
          cardID: tx.card_id || '',
          type: tx.type || '',
          details: tx.additional_information || '',
          date: new Date(Date.parse(tx.date)),
          alternativeCurrency: {
            amount: tx.amount || 0,
            currency: tx.currency_code || '',
          },
          merchant: {
            category: tx.merchant_category || '',
            name: tx.merchant_name || '',
          },
        };
      }).sort((a: ISingleTransaction, b: ISingleTransaction) => {
        return b.date.getTime() - a.date.getTime();
      }).map(setTitle);
    });
}

function setTitle(transaction) {
  const title = [singleTransactionTypes.deposit, singleTransactionTypes.withdraw].includes(transaction.type)
    ? texts.accountPage.transactions.statusTags.adjustment : transaction.merchant.name;
  return {
    ...transaction,
    title,
  };
}

export type TransactionsActions = IAccountTransactionsInitial | IAccountTransactionsAdditional | IAccountTransactionsDone;
