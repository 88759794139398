import { ReactElement } from 'react';

export const UI_MODAL_OPEN = 'UI/MODAL/OPEN';
export const UI_MODAL_CLOSE = 'UI/MODAL/CLOSE';

interface IModalOpen {
  type: typeof UI_MODAL_OPEN;
  payload: {
    id: string,
    content: ReactElement,
  };
}

export function openModal(id: string, modalContent: ReactElement) {
  return (dispatch) => {
    dispatch({
      type: UI_MODAL_OPEN,
      payload: {
        id,
        content: modalContent,
      },
    });
  };
}

interface IModalClose {
  type: typeof UI_MODAL_CLOSE;
}

export function closeModal() {
  return (dispatch) => {
    dispatch({
      type: UI_MODAL_CLOSE,
    });
  };
}

export function openNotificationModal(modalContent: ReactElement) {
  return (dispatch) => dispatch(openModal('notification-modal', modalContent));
}

export type ModalActions = IModalOpen | IModalClose;
