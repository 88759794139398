import * as React from 'react';

import classnames from 'classnames';

import { singleTransactionTypes } from '../../../../constants/transactions';
import Icon from '../../Icon/Icon';

interface ISingleTransactionIconProps {
  name: string;
  className: any;
  type: string;
}

class SingleTransactionIcon extends React.Component <ISingleTransactionIconProps, {}> {

  statusIconRender (): React.ReactElement {
    const statusIconName = {
      [singleTransactionTypes.authorization]: 'circle-slice-5',
      [singleTransactionTypes.declined]: 'close-circle',
      [singleTransactionTypes.reverted]: 'minus-circle',
    }[this.props.type];

    return statusIconName && (
      <div className="status-icon-wrapper">
        <Icon name={statusIconName} className="transaction-status-icon" />
      </div>
    );
  }

  render() {
    const { name, className, type } = this.props;
    const wrapperClassName = classnames({
      'transaction-icon-wrapper': true,
      'transaction-icon-regular': type === singleTransactionTypes.transaction,
      'transaction-icon-authorization': type === singleTransactionTypes.authorization,
      'transaction-icon-declined': type === singleTransactionTypes.declined,
      'transaction-icon-reverted': type === singleTransactionTypes.reverted,
    },                                  className);

    return (
      <div className={wrapperClassName}>
        <Icon name={name} className="transaction-icon" />
        {this.statusIconRender()}
      </div>
    );
  }

}

export default SingleTransactionIcon;
