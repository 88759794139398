import {
  PROFILE_CHANGE_ADDRESS,
  PROFILE_CHANGE_PASSWORD_CLEAN_ERROR,
  PROFILE_CHANGE_PASSWORD_ERROR,
  PROFILE_GET,
  ProfileActions,
} from '../actions/profile';
import { IProfile, IProfileReducer, profileErrorCodes } from '../models/profile';

const initialProfile: IProfile = {
  avatar: '',
  id: '',
  displayName: '',
  address: {
    country: '',
    postalCode: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
  },
  email: '',
  phone: '',
  timezone: '',
};

const initialState: IProfileReducer = {
  profile: initialProfile,
  error: undefined,
};

export default function profileReducer(state = initialState, action: ProfileActions) {
  // @ts-ignore
  switch (action.type) {
    case PROFILE_CHANGE_ADDRESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          address: action.payload,
        },
      };
    }
    case PROFILE_GET: {
      return {
        profile: action.payload,
        error: state.error,
      };
    }

    case PROFILE_CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        error: profileErrorCodes.passwordInvalid,
      };
    }

    case PROFILE_CHANGE_PASSWORD_CLEAN_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }

    default:
      return state;
  }
}
