import * as React from 'react';

import classnames from 'classnames';
import { List } from 'immutable';
import { connect } from 'react-redux';

import { ICard } from '../../../../models/cards';

import { cardTypes } from '../../../../constants/uiConfig';

interface ICardBackgroundProps {
  activeCard: string;
  cards: List<ICard>;
}

class CardBackground extends React.Component <ICardBackgroundProps, {}> {
  constructor(props) {
    super(props);
    this.renderSingleBackground = this.renderSingleBackground.bind(this);
  }

  renderSingleBackground (card: ICard) {
    const wrapperClass = classnames({
      'card-background-item': true,
      'card-background-item-active': card.id === this.props.activeCard,
      'card-background-physical': card.type === cardTypes.chipPin,
      'card-background-sticker' : card.type === cardTypes.sticker,
      'card-background-virtual' : card.type === cardTypes.virtual || card.type === cardTypes.creating,
    });

    return (
      <div key={`background-${card.id}`} className={wrapperClass}>
        <div className="gradient-element" />
        <div className="linear-element" />
      </div>
    );
  }

  render() {
    const contentRender = this.props.cards.map(this.renderSingleBackground);
    return (
      <div className="card-background-wrapper">
        { contentRender }
      </div>
    );
  }

}

function inject({}) {
  return {
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(CardBackground);
