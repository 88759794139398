
require.register("cheerio/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "cheerio");
  (function() {
    /**
 * Export cheerio (with )
 */

exports = module.exports = require('./lib/cheerio');

/*
  Export the version
*/

exports.version = require('./package.json').version;
  })();
});