import { List, Set } from 'immutable';

import { ISingleTransaction } from '../models/transactions';

import {
  TRANSACTIONS_LOAD_LIST,
  TRANSACTIONS_UPDATE_DONE,
  TRANSACTIONS_UPDATE_LIST,
  TransactionsActions,
} from '../actions/transactions';

interface ITransactionsReducer {
  transactions: List<ISingleTransaction>;
  revertedTransactions: Set<string>;
}

const initialState: ITransactionsReducer = {
  transactions: List([]),
  revertedTransactions: Set([]),
};

export default function transactionsReducer(state = initialState, action: TransactionsActions) {
  switch (action.type) {
    case TRANSACTIONS_LOAD_LIST: {
      return {
        ...state,
        transactions: List(action.payload),
      };
    }
    case TRANSACTIONS_UPDATE_LIST: {
      return {
        ...state,
        transactions: state.transactions.concat(List(action.payload)),
      };
    }
    case TRANSACTIONS_UPDATE_DONE: {
      return {
        ...state,
        done: true,
      };
    }
    default:
      return state;
  }
}
