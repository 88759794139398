import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Icon from '../../../Basic/Icon/Icon';
import IconButton from '../../../Basic/IconButton/IconButton';
import Input from '../../../Basic/Inputs/Input/Input';
import Modal from '../../../Layout/Modal/Modal';
import FaqSection from './FaqSection/FaqSection';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { faqTexts } from '../../../../constants/faqTexts';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';

// tslint:disable-next-line:no-empty-interface
interface IFaqPageProps extends IWithRouterModalChild {

}

interface IFaqPageState {
  searchRequest: string;
  searchActive: boolean;
}

class FaqPage extends React.Component <IFaqPageProps, IFaqPageState> {
  constructor(props) {
    super(props);
    this.state = {
      searchRequest: '',
      searchActive: false,
    };

    this.toggleSearchState = this.toggleSearchState.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  toggleSearchState() {
    this.setState({
      searchActive: !this.state.searchActive,
      searchRequest: '',
    });
  }

  handleSearchInputChange(value) {
    this.setState({
      searchRequest: value,
    });
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.settings.root);
  }

  renderModal(): React.ReactElement {
    const { withoutUserRoute } = this.props;
    const { searchRequest, searchActive } = this.state;
    const modalTexts = texts.settingsPage.faqPage;
    const modalTitle = searchActive ? null : modalTexts.title;

    const searchButtonRender = (
      <IconButton
        onClick={this.toggleSearchState}
      >
        <Icon
          name={searchActive ? 'close' : 'magnify'}
        />
      </IconButton>
    );

    const searchInputRender = searchActive ? (
      <Input
        icon="magnify"
        autoFocusDelay={400}
        placeholder={modalTexts.searchPlaceholder}
        value={searchRequest}
        onChange={this.handleSearchInputChange}
      />
    ) : null;

    const sectionsRender = faqTexts.map((content) => (
      <FaqSection key={content.id} sectionContent={content} searchRequest={searchRequest}/>
    ));

    return (
      <Modal
        className="faq-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalTitle={modalTitle}
        modalWidget={searchInputRender}
        asideWidget={searchButtonRender}
        hideBackArrow={searchActive}
      >
        {sectionsRender}
      </Modal>
    );
  }

  render() {
    this.props.onModalOpen(this.renderModal());
    return (
      <React.Fragment />
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(FaqPage);
