import * as React from 'react';

import classnames from 'classnames';

interface ISwitcherProps {
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => any;
}

// tslint:disable-next-line:function-name
function Switcher(props: ISwitcherProps) {
  const { checked, disabled, onClick } = props;
  const wrapperClass = classnames({
    switcher: true,
    'switcher-active': checked,
    'switcher-disabled': disabled,
  });
  return (
    <div
      className={wrapperClass}
      onClick={onClick}
    >
      <div className="caret" />
    </div>
  );
}

export default Switcher;
