
require.register("string.prototype.trim/polyfill.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "string.prototype.trim");
  (function() {
    'use strict';

var implementation = require('./implementation');

var zeroWidthSpace = '\u200b';

module.exports = function getPolyfill() {
	if (String.prototype.trim && zeroWidthSpace.trim() === zeroWidthSpace) {
		return String.prototype.trim;
	}
	return implementation;
};
  })();
});