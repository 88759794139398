import * as React from 'react';

import { connect } from 'react-redux';
import { activateCard } from '../../../../../../actions/cards';
import { texts } from '../../../../../../constants/lang';

import CardMessageMenu from '../CardMessageMenu/CardMessageMenu';

interface ICardActivateProps {
  id: string;
  onActivate: (id: string) => any;
}

class CardActivate extends React.Component <ICardActivateProps, {}> {
  constructor(props) {
    super(props);

    this.onActivate = this.onActivate.bind(this);
  }

  onActivate() {
    const { onActivate, id } = this.props;
    onActivate(id);
  }

  render() {
    const activationTexts = texts.cardPage.card.activating;
    return (
      <CardMessageMenu
        title={activationTexts.title}
        descriptionHtml={activationTexts.description}
        label={activationTexts.action}
        action={this.onActivate}
      />
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onActivate: (id) => dispatch(activateCard(id)),
  };
}

export default connect(inject, actions)(CardActivate);
