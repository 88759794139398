import { applyMiddleware, combineReducers, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

// Reducers import
import accountReducer from './accountReducer';
import authReducer from './authReducer';
import cardsReducer from './cardsReducer';
import profileReducer from './profileReducer';
import transactionsReducer from './transactionsReducer';
import uiReducer from './uiReducer';

const reducers = {
  account: accountReducer,
  transactions: transactionsReducer,
  cards: cardsReducer,
  ui: uiReducer,
  auth: authReducer,
  profile: profileReducer,
};

export default applyMiddleware(reduxThunk)(createStore)(combineReducers(reducers));
