import * as React from 'react';

import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import ModalHandler from '../../Layout/ModalHandler/ModalHandler';
import SnackbarHandler from '../../Layout/SnackbarHandler/SnackbarHandler';
import LoginForm from './LoginForm/LoginForm';
import RecoverPassword from './RecoverPassword/RecoverPassword';
import SendRecoveryEmail from './SendRecoveryEmail/SendRecoveryEmail';

import { routingPaths } from '../../../constants/routing';

class LoginPage extends React.Component <{}, {}> {
  render() {
    return (
      <div className="login-page-wrapper">
        <div className="login-page-inner-wrapper">
          <LoginForm />
        </div>
        <Switch>
          <Route
            path={routingPaths.login.forgotPassword}
            component={SendRecoveryEmail}
          />
          <Route
            path={`${routingPaths.login.recoverPassword}/:token`}
            component={RecoverPassword}
          />
        </Switch>

        {/*Flight-out elements*/}
        <ModalHandler />
        <SnackbarHandler />
      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(LoginPage);
