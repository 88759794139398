
require.register("parse5/lib/extensions/position_tracking/preprocessor_mixin.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "parse5");
  (function() {
    'use strict';

var Mixin = require('../../utils/mixin'),
    inherits = require('util').inherits,
    UNICODE = require('../../common/unicode');

//Aliases
var $ = UNICODE.CODE_POINTS;

var PositionTrackingPreprocessorMixin = module.exports = function (preprocessor) {
    // NOTE: avoid installing tracker twice
    if (!preprocessor.__locTracker) {
        preprocessor.__locTracker = this;

        Mixin.call(this, preprocessor);

        this.preprocessor = preprocessor;
        this.isEol = false;
        this.lineStartPos = 0;
        this.droppedBufferSize = 0;

        this.col = -1;
        this.line = 1;
    }

    return preprocessor.__locTracker;
};

inherits(PositionTrackingPreprocessorMixin, Mixin);

Object.defineProperty(PositionTrackingPreprocessorMixin.prototype, 'offset', {
    get: function () {
        return this.droppedBufferSize + this.preprocessor.pos;
    }
});

PositionTrackingPreprocessorMixin.prototype._getOverriddenMethods = function (mxn, orig) {
    return {
        advance: function () {
            var cp = orig.advance.call(this);

            //NOTE: LF should be in the last column of the line
            if (mxn.isEol) {
                mxn.isEol = false;
                mxn.line++;
                mxn.lineStartPos = mxn.offset;
            }

            if (cp === $.LINE_FEED)
                mxn.isEol = true;

            mxn.col = mxn.offset - mxn.lineStartPos + 1;

            return cp;
        },

        retreat: function () {
            orig.retreat.call(this);
            mxn.isEol = false;

            mxn.col = mxn.offset - mxn.lineStartPos + 1;
        },

        dropParsedChunk: function () {
            var prevPos = this.pos;

            orig.dropParsedChunk.call(this);

            mxn.droppedBufferSize += prevPos - this.pos;
        }
    };
};
  })();
});