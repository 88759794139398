import * as React from 'react';

import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Icon from '../../Icon/Icon';
import Input, { IInputProps } from '../Input/Input';

import { showSnackbarMessage } from '../../../../actions/ui/snackbar';
// tslint:disable-next-line:import-spacing
import withCopySnackbar, { IWithCopySnackbarChild } from  '../../../Decorators/withCopySnackbar';

interface IFixedInputProps extends IInputProps, IWithCopySnackbarChild {
  copiedMessage: string;
  onSnackbarMessage: (type: number, content: string) => any;
}

class FixedInput extends React.Component <IFixedInputProps, {}> {

  constructor(props) {
    super(props);
    this.toggleCopy = this.toggleCopy.bind(this);
  }

  toggleCopy() {
    const { copiedMessage, onCopy } = this.props;
    onCopy(copiedMessage);
  }

  render() {
    // const { copied } = this.state;
    const { value, className, isCopied } = this.props;

    const wrapperClass = classnames('input-fixed', className);

    const iconName = isCopied ? 'check' : 'content-copy';
    const iconClass = classnames({
      'show-icon': true,
      'show-icon-active': !isCopied,
    });

    const iconRender = (
      <div className="icon">
        <Icon name={iconName} />
      </div>
    );

    const copyContent = isCopied ? null : value;

    const inputRender = (
      <Input
        {...this.props}
        className={wrapperClass}
        disabled
        widget={iconRender}
      />
    );

    const isContentCopyable = copyContent && copyContent.length > 0;

    return isContentCopyable ? (
      <CopyToClipboard
        text={copyContent}
        onCopy={this.toggleCopy}
      >
        {inputRender}
      </CopyToClipboard>
    ) : inputRender;
  }

}

function inject({}) {
  return {
  };
}

function actions(dispatch) {
  return {
    // tslint:disable-next-line:max-line-length
    onSnackbarMessage: (type: number, content: string) => dispatch(showSnackbarMessage(type, content)),
  };
}

export default compose(
  withCopySnackbar,
  connect(inject, actions),
// @ts-ignore
)(FixedInput);
