import * as React from 'react';

import CardMessageMenu from '../CardMessageMenu/CardMessageMenu';

import { texts } from '../../../../../../constants/lang';

class CardProducing extends React.Component <{}, {}> {
  render() {
    const producingTexts = texts.cardPage.card.producing;
    return (
      <CardMessageMenu
        title={producingTexts.title}
        descriptionHtml={producingTexts.description}
      />
    );
  }
}

export default CardProducing;
