import * as React from 'react';

import classnames from 'classnames';
import { List } from 'immutable';
import * as moment from 'moment';
import { connect } from 'react-redux';

import ListItem from '../../ListItem/ListItem';
import SingleTransactionIcon from '../SingleTransactionIcon/SingleTransactionIcon';

import { ICard } from '../../../../models/cards';
import { ISingleTransaction } from '../../../../models/transactions';

import { texts } from '../../../../constants/lang';
import { getMerchantTypeConfig, singleTransactionTypes } from '../../../../constants/transactions';
import { outputAlternativeCurrency } from '../../../../utils/cards';
import { transactionDateFormat } from '../../../../utils/transactions';

interface ISingleTransactionProps {
  content: ISingleTransaction;
  cards: List<ICard>;
  lastOfDay: boolean;
  showFullDate?: boolean;
}

class SingleTransaction extends React.Component <ISingleTransactionProps, {}> {

  render() {
    const { content, cards, lastOfDay, showFullDate } = this.props;
    const merchantTypeConfig = getMerchantTypeConfig(content.merchant.category);
    const iconRender: React.ReactElement = (
      <SingleTransactionIcon
        name={merchantTypeConfig.icon}
        className={merchantTypeConfig.cssName}
        type={content.type}
      />
    );

    const card = cards.find(item => item.id === content.cardID);
    const cardNumber = card ? card.number : '';

    const timeFormat = transactionDateFormat(showFullDate);
    const time = moment(content.date).format(timeFormat);
    const revertedSign = content.type === singleTransactionTypes.reverted;

    const alternativeCurrency = outputAlternativeCurrency(content.alternativeCurrency, content.currency, revertedSign);

    const transactionClassName = classnames({
      'single-transaction': true,
      'single-transaction-basic': content.type === singleTransactionTypes.transaction,
      'single-transaction-authorization': content.type === singleTransactionTypes.authorization,
      'single-transaction-reverted': content.type === singleTransactionTypes.reverted,
      'single-transaction-declined': content.type === singleTransactionTypes.declined,
      'single-transaction-last-of-day': lastOfDay,
    });

    const tagText = texts.accountPage.transactions.statusTags;

    const tagElement =
      content.type === singleTransactionTypes.reverted ? (
        <span
          key="tag"
          className="status-tag status-reverted"
        >
          {tagText.reverted}
        </span>

      ) : content.type === singleTransactionTypes.declined ? (
        <span
          key="tag"
          className="status-tag status-declined"
        >
          {tagText.declined}
        </span>

      ) : null;

    const amount = `${revertedSign ? '+' : '-'}${content.amount.toFixed(2)}`;

    return (
      <ListItem
        className={transactionClassName}
        leftElement={iconRender}
        title={content.title}
        tag={tagElement}
        contentLine1={cardNumber}
        contentLine2={time}
        rightPrimary={amount}
        rightSecondary={alternativeCurrency}
        onClick={() => { /* TODO: add single transaction route */ }}
      />
    );
  }
}

function inject({ cards }) {
  return {
    cards: cards.cards,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(SingleTransaction);
