import * as React from 'react';

import reactTestRenderer from 'react-test-renderer';

import Button from './Button';

describe('<Button />', () => {
  it('renders correctly', () => {
    const tree = reactTestRenderer
                    .create(<Button label={'hello'}/>)
                    .toJSON();
    expect(tree).toMatchSnapshot();
  });
});
