import * as React from 'react';

import { connect } from 'react-redux';
import { texts } from '../../../../../constants/lang';
import Button from '../../../../Basic/Button/Button';
import Input from '../../../../Basic/Inputs/Input/Input';

interface IVerifyPasswordFormProps {
  email: string;
  onChange: () => any;
}

interface IVerifyPasswordFormState {
  verificationCode: string;
  verificationCodeErrorPrompt: string;
}

class VerifyPasswordForm extends React.Component <IVerifyPasswordFormProps, IVerifyPasswordFormState> {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: '',
      verificationCodeErrorPrompt: '',
    };
    this.onVerificationCodeChange = this.onVerificationCodeChange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onVerificationCodeChange(value) {
    this.setState({
      verificationCode: value,
      verificationCodeErrorPrompt: '',
    });
  }

  validateForm() {
    if (this.state.verificationCode.length === 0) {
      this.setState({
        verificationCodeErrorPrompt: texts.settingsPage.passwordPage.verifyForm.input.errorPrompt,
      });
      return false;
    }
    // TODO: add verification code validation here (code size, symbols used etc
    return true;
  }

  onSubmitForm() {
    if (this.validateForm()) {
      // TODO: add verification action call
      this.props.onChange();
    }
  }

  render() {
    const { email } = this.props;
    const { verificationCode, verificationCodeErrorPrompt } = this.state;
    const verifyTexts = texts.settingsPage.passwordPage.verifyForm;

    return (
      <div className="verify-form">
        <div className="stepper"> {verifyTexts.stepper} </div>
        <h2 className="title"> {verifyTexts.title} </h2>
        <p className="description"> {verifyTexts.description} </p>
        <div className="email-wrapper"> {email} </div>
        <Input
          value={verificationCode}
          label={verifyTexts.input.label}
          placeholder={verifyTexts.input.placeholder}
          errorText={verificationCodeErrorPrompt}
          onEnter={this.onSubmitForm}
          onChange={this.onVerificationCodeChange}
        />
        <Button
          width="block"
          pinnedBottom
          label={verifyTexts.action}
          onClick={this.onSubmitForm}
        />
      </div>
    );
  }
}

function inject({ profile }) {
  return {
    email: profile.profile.email,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(VerifyPasswordForm);
