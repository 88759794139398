
require.register("string.prototype.trim/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "string.prototype.trim");
  (function() {
    'use strict';

var bind = require('function-bind');
var define = require('define-properties');

var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var shim = require('./shim');

var boundTrim = bind.call(Function.call, getPolyfill());

define(boundTrim, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = boundTrim;
  })();
});