
require.register("gud/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "gud");
  (function() {
    // @flow
'use strict';

var key = '__global_unique_id__';

module.exports = function() {
  return global[key] = (global[key] || 0) + 1;
};
  })();
});