export const UI_ANCHOR_PAGE_SET = 'UI/ANCHOR_PAGE/SET';
export const UI_ANCHOR_PAGE_REMOVE = 'UI/ANCHOR_PAGE/REMOVE';

interface IAnchorPageSet {
  type: typeof UI_ANCHOR_PAGE_SET;
  payload: any;
}

export function setAnchorPage(page) {
  return (dispatch) => {
    dispatch({
      type: UI_ANCHOR_PAGE_SET,
      payload: page,
    });
  };
}

interface IAnchorPageRemove {
  type: typeof UI_ANCHOR_PAGE_REMOVE;
}

export function removeAnchorPage() {
  return (dispatch) => {
    dispatch({
      type: UI_ANCHOR_PAGE_REMOVE,
    });
  };
}

export type ScrollActions = IAnchorPageSet | IAnchorPageRemove;
