import * as React from 'react';

import Button from '../../../../Basic/Button/Button';
import GlobeIcon from '../../../../Basic/SVG/GlobeIcon';

import { texts } from '../../../../../constants/lang';

interface IRecoverTokenInvalidProps {
  onClose: () => any;
}

class RecoverTokenInvalid extends React.Component <IRecoverTokenInvalidProps, {}> {
  render() {
    const invalidTokenTexts = texts.login.recoverPassword.invalidToken;

    return (
      <div className="recover-password-token-invalid">
        <GlobeIcon />
        <h2 className="title"> {invalidTokenTexts.title} </h2>
        <p className="description"> {invalidTokenTexts.description} </p>
        <Button
          rounded
          icon="arrow-left"
          label={invalidTokenTexts.action}
          onClick={this.props.onClose}
        />
      </div>
    );
  }
}

export default RecoverTokenInvalid;
