import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Modal from '../../../Layout/Modal/Modal';
import RecoverPasswordForm from './RecoverPasswordForm/RecoverPasswordForm';
import RecoverPasswordSuccess from './RecoverPasswordSuccess/RecoverPasswordSuccess';
import RecoverTokenInvalid from './RecoverTokenInvalid/RecoverTokenInvalid';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { logout, recoverPasswordTokenValidate } from '../../../../actions/auth';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { authErrorCodes } from '../../../../models/auth';

interface IRecoverPasswordProps extends IWithRouterModalChild  {
  token?: string;
  isTokenValid?: boolean;
  validateToken?: (token: string) => any;
  onLogout?: () => any;
}

interface IRecoverPasswordState {
  step: number;
}

const recoverySteps = {
  passwordForm: 1,
  success: 2,
};

class RecoverPassword extends React.Component <IRecoverPasswordProps, IRecoverPasswordState> {
  constructor(props) {
    super(props);
    this.state = {
      step: recoverySteps.passwordForm,
    };

    this.showSuccessScreen = this.showSuccessScreen.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  componentDidMount(): void {
    const { validateToken, match } = this.props;
    validateToken(match.params.token);
  }

  componentWillReceiveProps(nextProps: Readonly<IRecoverPasswordProps>, nextContext: any): void {
    // Handle if token changed by user in window location bar
    const { validateToken, match } = nextProps;
    if (match.params.token !== this.props.match.params.token) {
      validateToken(match.params.token);
    }
  }

  showSuccessScreen() {
    this.setState({
      step: recoverySteps.success,
    });
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.home);
    if (this.state.step === recoverySteps.success) {
      this.props.onLogout();
    }
  }

  renderModal(): React.ReactElement {
    const { onModalClose, match, isTokenValid } = this.props;
    const token = this.props.match.params.token;
    const { step } = this.state;
    return (
      <Modal
        className="recover-password-modal"
        hideAppearTransition={true}
        hideBackArrow={true}
        modalTitle={texts.login.recoverPassword.title}
      >
        {isTokenValid && step === recoverySteps.passwordForm && (
          <RecoverPasswordForm
            recoveryToken={token}
            onComplete={this.showSuccessScreen}
            onModalClose={this.onModalClose}
          />
        )}
        {isTokenValid && step === recoverySteps.success && (
          <RecoverPasswordSuccess
            onClose={this.onModalClose}
          />
        )}
        {!isTokenValid && (
          <RecoverTokenInvalid
            onClose={this.onModalClose}
          />
        )}
      </Modal>
    );
  }

  render() {
    this.props.onModalOpen(this.renderModal());

    return (
      <React.Fragment />
    );
  }
}

function inject({ auth }) {
  return {
    isTokenValid: auth.error !== authErrorCodes.recoverTokenOutdated,
  };
}

function actions(dispatch) {
  return {
    validateToken: (token: string) => dispatch(recoverPasswordTokenValidate(token)),
    onLogout: () => dispatch(logout()),
  };
}

// @ts-ignore
export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(RecoverPassword);
