import * as React from 'react';

import { IFaqQuestion } from '../../../../../models/faq';
import Accordion from '../../../../Basic/Accordion/Accordion';

interface IFaqQuestionProps {
  content: IFaqQuestion;
  searchRequest: string;
}

class FaqQuestion extends React.Component <IFaqQuestionProps, {}> {

  render() {
    const { content, searchRequest } = this.props;
    const titleRender = (
      <div
        className="faq-question-title"
        dangerouslySetInnerHTML={{ __html: content.question }}
      />
    );

    const answerRender = (
      <div
        className="faq-question-answer-content"
        dangerouslySetInnerHTML={{ __html: content.answer }}
      />
    );

    return (
      <Accordion
        title={titleRender}
        icon={searchRequest ? 'comment-question-outline' : null}
        className={searchRequest ? 'faq-question-search-wrapper' : 'faq-question-default-wrapper'}
        isOpen={!!searchRequest}
      >
        {answerRender}
      </Accordion>
    );
  }
}

export default FaqQuestion;
