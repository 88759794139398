
require.register("functions-have-names/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "functions-have-names");
  (function() {
    'use strict';

var functionsHaveNames = function functionsHaveNames() {
	return typeof function f() {}.name === 'string';
};

var gOPD = Object.getOwnPropertyDescriptor;

functionsHaveNames.functionsHaveConfigurableNames = function functionsHaveConfigurableNames() {
	return functionsHaveNames() && gOPD && !!gOPD(function () {}, 'name').configurable;
};

module.exports = functionsHaveNames;
  })();
});