import { ICard } from '../models/cards';
import { cardBrandingData, cardTypes } from './uiConfig';

export const singleCardLimitPeriods = {
  transaction: 'transaction',
  day: 'daily',
  month: 'monthly',
};

export const cardStatus = {
  ordered: 'Ordered',
  created: 'Created',
  personalized: 'Personalized',
  produced: 'Produced',
  dispatched: 'Dispatched',
  active: 'Active',
  blocked: 'Blocked',
  closing: 'Closing',
  closed: 'Closed',
};

export const cardProducingStatuses = [cardStatus.ordered, cardStatus.created, cardStatus.produced, cardStatus.personalized];

export const blockType = {
  lost: 'Lost',
  stolen: 'Stolen',
  notDelivered: 'Not delivered',
  counterfeit: 'Counterfeit',
  fraudulent: 'Fraudulent',
  blockedByIssuer: 'Blocked by issuer',
  blockedByClient: 'Blocked by client',
  blockedByCardholder: 'Blocked by cardholder',
  maxInvalidPINTriesReached: 'Max invalid PIN tries reached',
  maxInvalidCVV2TriesReached: 'Max invalid CVV2 tries reached',
};

// @ts-ignore
export const virtualCardCreatingPredefined: ICard = {
  id: 'new',
  type: cardTypes.creating,
  number: '**** **** **** ****',
  expires: {
    month: '**',
    year: '**',
  },
  preview: cardBrandingData,
};
