export const uiConfig = {
  navigation: {
    account: {
      defaultIcon: 'wallet-outline',
      activeIcon: 'wallet',
    },
    analytics: {
      defaultIcon: 'chart-arc',
      activeIcon: 'chart-pie',
    },
    cards: {
      defaultIcon: 'credit-card',
      activeIcon: 'credit-card',
    },
    other: {
      defaultIcon: 'apps',
      activeIcon: 'apps-box',
    },
  },
  account: {
    topUp: {
      conditions: [
        'currency-eur',
        'av-timer',
        'alert',
      ],
      additionalInfo: [
        // TODO(w2lker): correct icon
        'map-marker',
      ],
    },
  },
  snackbar: {
    copy: 'content-copy',
    serverError: 'alert-octagon',
    success: 'check-bold',
    unknown: 'information-variant',
  },
};

export const cardBrandingData = {
  issuerLogo: '/img/card-logo-issuer-white.svg',
  schemeLogo: '/img/card-logo-scheme-white.svg',
};

export const cardTypes = {
  chipPin: 'ChipAndPin',
  sticker: 'Sticker',
  virtual: 'Virtual',
  creating: 'Creating',
};

export const snackbarMessageTypes = {
  copied: 1,
  success: 2,
  serverError: -1,
};
