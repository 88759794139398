import * as React from 'react';

interface IAccordionBodyProps {
  isOpen: boolean;
  children: any;
}

interface IAccordionBodyState {
  isDefaultOpen: boolean;
}

class AccordionBody extends React.Component <IAccordionBodyProps, IAccordionBodyState> {
  contentRef;

  constructor(props) {
    super(props);
    this.state = {
      isDefaultOpen: this.props.isOpen,
    };
    this.refContentWrapper = this.refContentWrapper.bind(this);
  }

  refContentWrapper(element) {
    this.contentRef = element;
    this.setState({});
  }

  render() {
    const { isOpen, children } = this.props;

    const defaultHeight = !this.contentRef && this.state.isDefaultOpen ? 'auto' : 0;

    const externalWrapperStyle = {
      height: (isOpen && this.contentRef) ? this.contentRef.offsetHeight : defaultHeight,
    };

    return (
      <div
        className="accordion-body-external-wrapper"
        style={externalWrapperStyle}
      >
        <div
          ref={this.refContentWrapper}
          className="accordion-body-internal-wrapper"
        >
          {children}
        </div>
      </div>
    );
  }
}

export default AccordionBody;
