import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from '../../../Basic/Icon/Icon';
import IconButton from '../../../Basic/IconButton/IconButton';

import { logout } from '../../../../actions/auth';
import currencySymbols from '../../../../constants/currencySymbols';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { urlImage } from '../../../../utils/other';
import { getRoutedByUserLink } from '../../../../utils/routing';

interface IAccountDetailsProps {
  logo?: string;
  currencySymbol?: string;
  accountAmount?: number;
  forwardRef: (element) => any;
  onTopUpClick: () => any;
  logout: () => any;
}

class AccountDetails extends React.Component <IAccountDetailsProps, {}> {
  render() {
    const { logo, currencySymbol, accountAmount, forwardRef } = this.props;

    const topUpLink = routingPaths.account.root + routingPaths.account.topUp;
    const searchLink = routingPaths.account.root + routingPaths.account.search;

    return (
      <div className="account-details" ref={forwardRef}>
        <div className="logo-wrapper">
          <div
            className="logo"
            style={{
              backgroundImage: urlImage(logo),
            }}
            onClick={this.props.logout}
          />
          <Link to={getRoutedByUserLink(searchLink)}>
            <IconButton className="search-button">
              <Icon name="magnify" />
            </IconButton>
          </Link>
        </div>
        <div className="amount-wrapper">
          <p className="description"> {texts.accountPage.accountTitle.balance} </p>
          <div className="amount-wrapper-sum">
            <h4 className="amount"> {currencySymbol} {accountAmount.toFixed(2)} </h4>
            <Link to={getRoutedByUserLink(topUpLink)}>
              <IconButton className="top-up-button">
              <div className="highlight">
                <Icon name="plus" />
              </div>
            </IconButton>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function inject({ ui, account }) {
  return {
    logo: ui.logo,
    currencySymbol: currencySymbols[account.currency],
    accountAmount: account.amount,
  };
}

function actions(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

export default connect(inject, actions)(AccountDetails);
