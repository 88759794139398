import * as React from 'react';
import { Provider } from 'react-redux';

import store from '../../reducers/store';

import Switch from './Switch';

export default class Root extends React.Component<{}> {
  render() {
    return (
      <Provider store={store}>
        <Switch />
      </Provider>
    );

  }
}
