import { List } from 'immutable';

import { IUIReducerInterface } from '../models/ui';

import { ModalActions, UI_MODAL_CLOSE, UI_MODAL_OPEN } from '../actions/ui/modals';
import { ScrollActions, UI_ANCHOR_PAGE_REMOVE, UI_ANCHOR_PAGE_SET } from '../actions/ui/scroll';
import { SnackbarActions, UI_SNACKBAR_HIDE, UI_SNACKBAR_SHOW } from '../actions/ui/snackbar';
import {
  SpinnerActions,
  UI_PRELOAD_SPINNER_HIDE,
  UI_PRELOAD_SPINNER_SHOW,
  UI_REQUEST_SPINNER_HIDE,
  UI_REQUEST_SPINNER_SHOW,
} from '../actions/ui/spinner';

const initialState: IUIReducerInterface = {
  logo: '/img/logo.svg',
  logoMini: '/img/logo-mini.svg',
  pageAnchor: null,
  modalStack: List(),
  snackbarStack: List(),
  emptyScreens: {
    emptySearchImage: '/img/empty-screens/empty-search-light.svg',
  },
  preloadSpinner: 0,
  requestSpinner: 0,
};

export default function sampleReducer(state = initialState, action: ModalActions | ScrollActions | SnackbarActions | SpinnerActions) {

  switch (action.type) {
    case UI_ANCHOR_PAGE_SET: {
      return {
        ...state,
        pageAnchor: action.payload,
      };
    }
    case UI_ANCHOR_PAGE_REMOVE: {
      return {
        ...state,
        pageAnchor: null,
      };
    }
    case UI_SNACKBAR_SHOW: {
      return {
        ...state,
        snackbarStack: state.snackbarStack.push(action.payload),
      };
    }
    case UI_SNACKBAR_HIDE: {
      const currentSnackbarIndex = state.snackbarStack
        .findIndex((snackbar) => snackbar.timestamp === action.payload);
      const newSnackbarStack = state.snackbarStack.delete(currentSnackbarIndex);
      return {
        ...state,
        snackbarStack: newSnackbarStack,
      };
    }
    case UI_MODAL_OPEN: {
      const { payload } = action;
      const currentModalIndex = state.modalStack.findIndex((modal) => modal.id === payload.id);
      let newModalStack;
      if (currentModalIndex > -1) {
        newModalStack = state.modalStack.set(currentModalIndex, payload);
      } else {
        newModalStack = state.modalStack.push(payload);
      }
      return {
        ...state,
        modalStack: newModalStack,
      };
    }
    case UI_MODAL_CLOSE: {
      return {
        ...state,
        modalStack: state.modalStack.pop(),
      };
    }

    case UI_PRELOAD_SPINNER_SHOW: {
      return {
        ...state,
        preloadSpinner: action.payload,
      };
    }

    case UI_PRELOAD_SPINNER_HIDE: {
      const { preloadSpinner } = state;
      return {
        ...state,
        preloadSpinner: preloadSpinner > 0 ? preloadSpinner - 1 : 0,
      };
    }

    case UI_REQUEST_SPINNER_SHOW: {
      return {
        ...state,
        requestSpinner: state.requestSpinner + 1,
      };
    }

    case UI_REQUEST_SPINNER_HIDE: {
      const { requestSpinner } = state;
      return {
        ...state,
        requestSpinner: requestSpinner > 0 ? requestSpinner - 1 : 0,
      };
    }

    default:
      return state;
  }
}
