import * as React from 'react';

import classnames from 'classnames';
import Button from '../../../../../Basic/Button/Button';

interface ICardMessageMenuProps {
  title: string;
  description?: string;
  descriptionHtml?: string;
  className?: string;
  label?: string;
  action?: () => any;
}

class CardMessageMenu extends React.Component <ICardMessageMenuProps, {}> {
  render() {
    const { title, description, descriptionHtml, className, label, action } = this.props;
    const wrapperClass = classnames({
      'card-message-menu': true,
    },                              className);

    const contentClass = classnames({
      description: true,
      'html-content': descriptionHtml,
    });

    const content = description ? (
      <div className={contentClass}> {description} </div>
    ) : (
      <div
        className={contentClass}
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
      />
    );

    const button = label ? (
      <Button
        width="block"
        label={label}
        onClick={action}
      />
    ) : null;

    return (
      <div className={wrapperClass}>
        <h4 className="title"> {title} </h4>
        {content}
        {button}
      </div>
    );
  }
}

export default CardMessageMenu;
