import * as React from 'react';

import { InjectedProps, withRipple } from '@material/react-ripple';
import classnames from 'classnames';

interface IIconButtonProps extends InjectedProps<HTMLDivElement> {
  children?: React.ReactNode;
  className: string;
  initRipple: React.Ref<HTMLDivElement>;
  onClick?: (event) => any;
}

class IconButton extends React.Component <IIconButtonProps, {}> {
  render() {
    const { children, className, initRipple, ...other } = this.props;
    const classes = classnames('icon-button', className);

    return (
      <div
        className={classes}
        ref={initRipple}
        {...other}
      >
        {children}
      </div>
    );
  }
}

export default withRipple(IconButton);
