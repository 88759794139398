import { api } from '../constants/api';

export class HttpError {

  code?: number;
  text?: string;
  constructor(code, text) {
    this.code = code;
    this.text = text;
  }
}

// tslint:disable-next-line:max-classes-per-file
class Api {
  login(email, password) {
    return this.api('POST', api.authLogin(), { email, password });
  }

  logout() {
    return this.api('DELETE', api.authLogout());
  }

  me() {
    return this.api('GET', api.authMe());
  }

  account() {
    return this.api('GET', api.getAccount());
  }

  remindPin(cardId) {
    return this.api('POST', api.remindCardPin(cardId));
  }

  remindCvv(cardId) {
    return this.api('POST', api.remindCardCvv(cardId));
  }

  cards() {
    return this.api('GET', api.listCards());
  }

  blockCard(cardId) {
    return this.api('POST', api.blockCard(cardId));
  }

  unblockCard(cardId) {
    return this.api('POST', api.unblockCard(cardId));
  }

  activateCard(cardId) {
    return this.api('POST', api.activateCard(cardId));
  }

  createVirtualCard() {
    return this.api('POST', api.createVirtualCard());
  }

  setCardLimit(cardId: string, period: string, type: string, value: number) {
    return this.api('PATCH', api.updateCardLimit(cardId), {
      period, type, value,
    });
  }

  listCardLimits(cardId: string) {
    return this.api('GET', api.listCardLimits(cardId));
  }

  updateCardSecurity(cardId, cardSecurity) {
    return this.api('POST', api.updateCardSecurity(cardId), cardSecurity);
  }

  getCardNumber(cardId: string) {
    return this.api('GET', api.getCardNumber(cardId));
  }

  getAccountTopUpCredentials() {
    return this.api('GET', api.getAccountTopUpCredentials());
  }

  changePassword(current: string, newPassword: string) {
    return this.api('POST', api.changePassword(), {
      current,
      new: newPassword,
    });
  }

  updateAddress(address) {
    return this.api('POST', api.updateAddress(), address);
  }

  sendRecoveryEmail(email: string) {
    return this.api('POST', api.sendRecoveryEmail(), {
      email,
    });
  }

  recoverPasswordByToken(token: string, password: string) {
    return this.api('POST', api.recoverPasswordByToken(token), {
      password,
    });
  }

  checkPasswordRecoveryToken(token: string) {
    return this.api('GET', api.checkPasswordRecoveryToken(token));
  }

  accountTransactions(fromRecord: number, recordCount: number, fromDate?: Date, toDate?: Date) {
    const args = {
      from_record: fromRecord,
      record_count: recordCount,
      from_date: null,
      to_date: null,
    };

    if (fromDate) {
      args.from_date = Math.round(fromDate.getTime() / 1000);
    }

    if (toDate) {
      args.to_date = Math.round(toDate.getTime() / 1000);
    }

    return this.api('GET', api.listAccountTransactions(), args);
  }

  api(method: string, url: string, body?: any) {
    const options: RequestInit = { method };
    let fetchUrl = url;
    if (method === 'GET') {
      if (body) {
        fetchUrl = `${url}?${params(body)}`;
      }
    } else {
      if (body) {
        options.body = JSON.stringify(body);
      }
      options.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
      };
    }

    return fetch(fetchUrl, options).then((response) => {
      return response.text().then((value) => {
        if (response.status !== 200 && response.status !== 204) {
          throw new HttpError(response.status, value);
        } else {
          if (value) {
            return JSON.parse(value);
          }
          return null;
        }
      });
    });
  }
}

function params(data) {
  return Object
      .keys(data)
      .filter(key => data[key] !== null)
      .map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
}

export default new Api();
