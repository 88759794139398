import * as React from 'react';

import Button from '../../../../Basic/Button/Button';
import KeyIcon from '../../../../Basic/SVG/KeyIcon';

import { texts } from '../../../../../constants/lang';

interface IRecoverPasswordSuccessProps {
  onClose: () => any;
}

class RecoverPasswordSuccess extends React.Component <IRecoverPasswordSuccessProps, {}> {
  render() {
    const recoveryTexts = texts.login.recoverPassword.step2;

    return (
      <div className="recover-password-success">
        <KeyIcon />
        <h2 className="title"> {recoveryTexts.title} </h2>
        <p className="description"> {recoveryTexts.description} </p>
        <Button
          rounded
          icon="arrow-left"
          label={recoveryTexts.action}
          onClick={this.props.onClose}
        />
      </div>
    );
  }
}

export default RecoverPasswordSuccess;
