
require.register("parse5/lib/extensions/location_info/open_element_stack_mixin.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "parse5");
  (function() {
    'use strict';

var Mixin = require('../../utils/mixin'),
    inherits = require('util').inherits;

var LocationInfoOpenElementStackMixin = module.exports = function (stack, options) {
    Mixin.call(this, stack);

    this.onItemPop = options.onItemPop;
};

inherits(LocationInfoOpenElementStackMixin, Mixin);

LocationInfoOpenElementStackMixin.prototype._getOverriddenMethods = function (mxn, orig) {
    return {
        pop: function () {
            mxn.onItemPop(this.current);
            orig.pop.call(this);
        },

        popAllUpToHtmlElement: function () {
            for (var i = this.stackTop; i > 0; i--)
                mxn.onItemPop(this.items[i]);

            orig.popAllUpToHtmlElement.call(this);
        },

        remove: function (element) {
            mxn.onItemPop(this.current);
            orig.remove.call(this, element);
        }
    };
};
  })();
});