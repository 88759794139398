import * as React from 'react';

import classnames from 'classnames';
import Icon from '../../Icon/Icon';
import Input, { IInputProps } from '../Input/Input';

interface IPasswordInputState {
  hidden: boolean;
}

class PasswordInput extends React.Component <IInputProps, IPasswordInputState> {

  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
    this.toggleHidden = this.toggleHidden.bind(this);
  }

  toggleHidden() {
    this.setState({
      hidden: !this.state.hidden,
    });
  }

  render() {
    const { hidden } = this.state;
    const iconName = hidden ? 'eye' : 'eye-off';
    const iconClass = classnames({
      'show-icon': true,
      'show-icon-active': !hidden,
    });
    const iconRender = (
      <div
        className={iconClass}
        onClick={this.toggleHidden}
      >
        <Icon name={iconName} />
      </div>
    );
    const inputType = hidden ? 'password' : 'text';

    return (
      <Input
        {...this.props}
        type={inputType}
        widget={iconRender}
      />
    );
  }

}

export default PasswordInput;
