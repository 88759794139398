import * as React from 'react';

import { mount } from 'enzyme';

import Button from './Button';

describe('<Button />', () => {
  it('renders correctly', () => {
    const text = 'hello!';
    const wrapper = mount(<Button label={text}/>);

    expect(wrapper.contains(text)).toEqual(true);
  });
});
