
require.register("react-test-renderer/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-test-renderer");
  (function() {
    'use strict';

if ('development' === 'production') {
  module.exports = require('./cjs/react-test-renderer.production.min.js');
} else {
  module.exports = require('./cjs/react-test-renderer.development.js');
}
  })();
});