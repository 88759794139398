import {
  AuthActions,
  CLEAR_LOGIN_ERROR,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  RECOVER_PASSWORD_BY_TOKEN,
  RECOVER_PASSWORD_TOKEN_CLEAR_ERROR,
  RECOVER_PASSWORD_TOKEN_ERROR,
} from '../actions/auth';

import { authErrorCodes, IAuthReducerState } from '../models/auth';

const initialState: IAuthReducerState = {
  loggedIn: null,
};

export default function (state = initialState, action: AuthActions): IAuthReducerState {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        loggedIn: true,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        loggedIn: false,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        error: authErrorCodes.invalidCredentials,
      };
    }
    case CLEAR_LOGIN_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }

    case RECOVER_PASSWORD_BY_TOKEN: {
      return state;
    }

    case RECOVER_PASSWORD_TOKEN_ERROR: {
      return {
        ...state,
        error: authErrorCodes.recoverTokenOutdated,
      };
    }

    case RECOVER_PASSWORD_TOKEN_CLEAR_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }

    default:
      return state;
  }
}
