import { List, Set } from 'immutable';

import { ITransactionMerchantTypeConfig } from '../models/transactions';

export const singleTransactionTypes = {
  transaction: 'transaction',
  authorization: 'authorization',
  reverted: 'reverted',
  deposit: 'deposit',
  withdraw: 'withdraw',
  declined: 'declined',
};

const singleTransactionMerchantTypes: List<ITransactionMerchantTypeConfig> = List([
  // E-commerce
  {
    id: '1',
    name: 'E-commerce',
    merchantCategories: Set([1, 2, 3]),
    icon: 'cart',
    cssName: 'ecommerce',
  },
  // Grocery
  {
    id: '2',
    name: 'Grocery',
    merchantCategories: Set([4, 5, 6]),
    icon: 'cart',
    cssName: 'grocery',
  },
  // Cafes and restaurants
  {
    id: '3',
    name: 'Cafes and restaurants',
    merchantCategories: Set([7, 8, 9]),
    icon: 'food-fork-drink',
    cssName: 'food',
  },
  // Appliances
  {
    id: '4',
    name: 'Appliances',
    merchantCategories: Set([10, 11, 12]),
    icon: 'disc-player',
    cssName: 'appliances',
  },
  // Travels
  {
    id: '5',
    name: 'Travels',
    merchantCategories: Set([13, 14, 15]),
    icon: 'airplane',
    cssName: 'travels',
  },
  // Taxi
  {
    id: '6',
    name: 'Taxi',
    merchantCategories: Set([16, 17, 18]),
    icon: 'car',
    cssName: 'taxi',
  },
  // Beauty and medicine
  {
    id: '7',
    name: 'Beauty and medicine',
    merchantCategories: Set([19, 20, 21]),
    icon: 'medical-bag',
    cssName: 'medicine',
  },
  // Pets
  {
    id: '8',
    name: 'Pets',
    merchantCategories: Set([22, 23, 24]),
    icon: 'dog-side',
    cssName: 'pets',
  },
  // Renovation
  {
    id: '9',
    name: 'Renovation',
    merchantCategories: Set([25, 26, 27]),
    icon: 'settings',
    cssName: 'renovation',
  },
  // Clothes and shoes
  {
    id: '10',
    name: 'Clothes and shoes',
    merchantCategories: Set([28, 29, 30]),
    icon: 'shoe-heel',
    cssName: 'shoe',
  },
  // Cash withdraw
  {
    id: '11',
    name: 'Cash withdraw',
    merchantCategories: Set([28, 29, 30]),
    icon: 'home',
    cssName: 'withdraw',
  },
  // Entertainment and sport
  {
    id: '12',
    name: 'Entertainment and sport',
    merchantCategories: Set([31, 32, 33]),
    icon: 'star-face',
    cssName: 'entertainment',
  },
]);

const uncategorized: ITransactionMerchantTypeConfig = {
  id: '-1',
  name: 'Other',
  merchantCategories: Set(),
  icon: 'credit-card',
  cssName: 'other',
};

export function getMerchantTypeConfig(merchantID: number): ITransactionMerchantTypeConfig {
  const config = singleTransactionMerchantTypes
                  .find(item => item.merchantCategories.has(merchantID));
  return config || uncategorized;
}

export const withdrawTransaction: ITransactionMerchantTypeConfig = {
  id: '-3',
  name: 'Withdraw',
  icon: 'arrow-up-bold',
  cssName: 'withdraw',
  merchantCategories: null,
};

export const depositTransaction: ITransactionMerchantTypeConfig = {
  id: '-2',
  name: 'Deposit',
  icon: 'arrow-down-bold',
  cssName: 'deposit',
  merchantCategories: null,
};
