import * as React from 'react';

import { connect } from 'react-redux';

import Button from '../../../Basic/Button/Button';
import NotificationModal from '../../../Layout/NotificationModal/NotificationModal';

import { closeModal } from '../../../../actions/ui/modals';

interface ISecureCredentialsNotificationModalProps {
  title: string;
  description: string;
  actionText: string;
  onClose?: () => any;
}

class SecureCredentialsNotificationModal extends React.Component <ISecureCredentialsNotificationModalProps, {}> {
  render() {
    const { title, description, actionText, onClose } = this.props;
    return (
      <NotificationModal
        title={title}
        onClose={onClose}
      >
        <div className="secure-credentials-modal">
          <p className="text">
            {description}
          </p>
          <Button
            label={actionText}
            onClick={onClose}
          />
        </div>
      </NotificationModal>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onClose: () => dispatch(closeModal()),
  };
}

export default connect(inject, actions)(SecureCredentialsNotificationModal);
