import * as React from 'react';
import { connect } from 'react-redux';

import Button from '../../../Basic/Button/Button';
import Input from '../../../Basic/Inputs/Input/Input';
import PasswordInput from '../../../Basic/Inputs/PasswordInput/PasswordInput';

import { clearLoginError, login } from '../../../../actions/auth';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { authErrorCodes } from '../../../../models/auth';
import { getRoutedByUserLink } from '../../../../utils/routing';

interface ILoginPageProps {
  login: (login: string, password: string) => any;
  loginError: boolean;
  clearLoginError: () => any;
}

interface ILoginPageState {
  login: string;
  password: string;
}

class LoginForm extends React.Component <ILoginPageProps, ILoginPageState> {

  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
    };

    this.onLoginChange = this.onLoginChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onLoginChange(newValue) {
    this.setState({ login: newValue });
    if (this.props.loginError) {
      this.props.clearLoginError();
    }
  }
  onPasswordChange(newValue) {
    this.setState({ password: newValue });
    if (this.props.loginError) {
      this.props.clearLoginError();
    }
  }
  onFormSubmit() {
    this.props.login(this.state.login, this.state.password);
  }

  render() {
    // tslint:disable-next-line:no-shadowed-variable
    const { login, password } = this.state;
    const loginTexts = texts.login.loginForm;
    const loginErrorText = this.props.loginError ? texts.login.loginForm.invalid : undefined;
    const passwordErrorText = this.props.loginError ? ' ' : undefined;
    return (
      <div className="login-page-form">
        <h2 className="login-page-title"> {loginTexts.title} </h2>
        <p className="login-page-description"> {loginTexts.description} </p>
        <Input
          className="login-input"
          value={login}
          onChange={this.onLoginChange}
          type="email"
          placeholder={loginTexts.login.placeholder}
          icon="email"
          errorText={loginErrorText}
          onEnter={this.onFormSubmit}
        />
        <PasswordInput
          className="password-input"
          value={password}
          onChange={this.onPasswordChange}
          placeholder={loginTexts.password.placeholder}
          icon="lock"
          errorText={passwordErrorText}
          onEnter={this.onFormSubmit}
        />
        <div className="form-control">
          <Button
            label={loginTexts.action.title}
            onClick={this.onFormSubmit}
          />
          {/* TODO: add text constant here*/}
          <Button
            ghost
            label={loginTexts.action.forgotPassword}
            to={getRoutedByUserLink(routingPaths.login.forgotPassword)}
            />
        </div>
      </div>
    );
  }
}

function inject({ auth }) {
  return {
    // Define if API returns invalid credentials on logins
    loginError: auth.error === authErrorCodes.invalidCredentials,
  };
}

function actions(dispatch) {
  return {
    login: (email, password) => dispatch(login(email, password)),
    clearLoginError: () => dispatch(clearLoginError()),
  };
}

export default connect(inject, actions)(LoginForm);
