import * as React from 'react';

import * as ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from '../../../Basic/Icon/Icon';
import IconButton from '../../../Basic/IconButton/IconButton';

import currencySymbols from '../../../../constants/currencySymbols';
import { routingPaths } from '../../../../constants/routing';
import { urlImage } from '../../../../utils/other';
import { getRoutedByUserLink } from '../../../../utils/routing';

interface IAccountHeaderProps {
  logoMini: string;
  currencySymbol: string;
  accountAmount: number;
  visible: boolean;
}

class AccountHeader extends React.Component <IAccountHeaderProps, {}> {
  render() {
    const { logoMini, currencySymbol, accountAmount, visible } = this.props;
    const accountAmountString = typeof accountAmount === 'number' ? accountAmount.toFixed(2) : '';
    const searchLink = routingPaths.account.root + routingPaths.account.search;

    return (
      <ReactCSSTransitionGroup
        transitionName="slide"
        transitionEnter={true}
        transitionLeave={true}
        transitionEnterTimeout={400}
        transitionLeaveTimeout={400}
      >
        {
          !!visible &&
          <div className="account-header">
              <div className="account-header-inner-wrapper">
                  <div
                      className="logo"
                      style={{
                        backgroundImage: urlImage(logoMini),
                      }}
                  />
                  <Link to={getRoutedByUserLink(searchLink)}>
                    <IconButton>
                        <Icon name="magnify" />
                    </IconButton>
                  </Link>
              </div>

              <div className="amount-wrapper">
                  <h4 className="amount"> {currencySymbol} {accountAmountString} </h4>
              </div>
          </div>
        }
      </ReactCSSTransitionGroup>
    );
  }
}

function inject({ ui, account }) {
  return {
    logoMini: ui.logoMini,
    currencySymbol: currencySymbols[account.currency],
    accountAmount: account.amount,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(AccountHeader);
