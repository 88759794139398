
require.register("tiny-invariant/dist/tiny-invariant.cjs.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "tiny-invariant");
  (function() {
    'use strict';

var isProduction = 'development' === 'production';
var prefix = 'Invariant failed';
function invariant(condition, message) {
  if (condition) {
    return;
  }

  if (isProduction) {
    throw new Error(prefix);
  } else {
    throw new Error(prefix + ": " + (message || ''));
  }
}

module.exports = invariant;
  })();
});