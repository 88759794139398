import { HttpError } from '../api/api';
import { LOGOUT } from './auth';
import { hideRequestSpinner } from './ui/spinner';

export function handleErrors(promise, additionalAction: () => any = null) {
  return (dispatch) => {
    promise(dispatch).catch(error => {
      if (error instanceof HttpError) {
        if (error.code === 403) {
          dispatch({ type: LOGOUT });
        }
      }
      // tslint:disable-next-line:no-console
      console.error(error); // TODO(1cfry8): how to display errors on the UI?

      if (additionalAction) {
        dispatch(additionalAction);
      }
      dispatch(hideRequestSpinner());
    });
  };
}
