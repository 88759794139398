import * as React from 'react';

import { InjectedProps, withRipple } from '@material/react-ripple';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Icon from '../../../Basic/Icon/Icon';

interface IBottomNavigationItemProps extends InjectedProps<HTMLDivElement> {
  icon: string;
  iconActive?: string;
  name: string;
  className?: any;
  to?: string;
  active?: boolean;
  initRipple: any;
}

class BottomNavigationItem extends React.Component <IBottomNavigationItemProps, {}> {
  render() {
    const { icon, iconActive, className, name, to, active, initRipple, ...other } = this.props;
    const linkClass = classnames({
      'bottom-navigation-link': true,
      'bottom-navigation-link-active': active,
    });

    const wrapperClass = classnames('bottom-navigation-link-wrapper', className);
    const link = to || '/';

    return (
      <Link
        className={linkClass}
        to={link}
      >
        <div
          className={wrapperClass}
          ref={initRipple}
          {...other}
        >
          <Icon
            className="bottom-navigation-icon"
            name={active ? iconActive : icon}
          />
          <div className="bottom-navigation-text">
            {name}
          </div>
        </div>
      </Link>
    );
  }
}

export default withRipple(BottomNavigationItem);
