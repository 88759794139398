import * as React from 'react';

import * as InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import CircleSpinner from '../../../../../Basic/CircleSpinner/CircleSpinner';
import EmptySearch from '../../../../../Basic/EmptySearch/EmptySearch';
import Flag from '../../../../../Basic/Flag/Flag';
import Icon from '../../../../../Basic/Icon/Icon';
import IconButton from '../../../../../Basic/IconButton/IconButton';
import Input from '../../../../../Basic/Inputs/Input/Input';
import MenuItem from '../../../../../Basic/MenuItem/MenuItem';
import NotificationModal from '../../../../../Layout/NotificationModal/NotificationModal';

import { closeModal } from '../../../../../../actions/ui/modals';
import { countriesISO3toISO2 } from '../../../../../../constants/countryNames';
import { texts } from '../../../../../../constants/lang';
import { displayCountryName } from '../../../../../../utils/ui';

const iso3List = Object.keys(countriesISO3toISO2);

interface ICountrySelectModalProps {
  value: string;
  onChange: (newValue: string) => any;
  onClose: () => any;
}

interface ICountrySelectModalState {
  filter: string;
  displayCountriesAmount: number;
  isLoaded: boolean;
}

class CountrySelectModal extends React.Component <ICountrySelectModalProps, ICountrySelectModalState> {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      displayCountriesAmount: 0,
      isLoaded: false,
    };

    this.getMoreCountries = this.getMoreCountries.bind(this);
    this.handleSearchFilterChange = this.handleSearchFilterChange.bind(this);
    this.handleSearchFilterClear = this.handleSearchFilterClear.bind(this);
    this.renderCountry = this.renderCountry.bind(this);
  }

  componentDidMount() {
    // Delay lazy load functionality for initiating process
    // Infinite scroll's size validation use input's focus and block appearance transition
    setTimeout(() => {
      this.setState({
        isLoaded: true,
      });
    }, 400);
  }

  handleSearchFilterChange(value) {
    this.setState({
      filter: value,
    });
  }

  handleSearchFilterClear() {
    this.setState({
      filter: '',
    });
  }

  handleCountrySelect(iso3: string) {
    const { onClose, onChange } = this.props;
    return () => {
      onChange(iso3);
      onClose();
    };
  }

  getMoreCountries() {
    const amount = this.state.displayCountriesAmount + 15;
    this.setState({
      displayCountriesAmount: iso3List.length > amount ? amount : iso3List.length,
    });
  }

  renderHeaderWidget(): React.ReactElement {
    const countrySelectTexts = texts.settingsPage.deliveryAddress.countryPicker;
    const { filter } = this.state;
    return (
      <div className="country-select-widget">
        <Input
          icon="magnify"
          placeholder={countrySelectTexts.searchPlaceholder}
          value={filter}
          autoFocusDelay={400}
          onChange={this.handleSearchFilterChange}
        />
        <IconButton
          className="modal-close"
          onClick={filter ? this.handleSearchFilterClear : this.props.onClose}
        >
          <Icon name="close" />
        </IconButton>
      </div>
    );
  }

  renderCountry(iso3: string): React.ReactElement {
    const iconWidget = (
      <Flag
        className="menu-flag"
        type="rounded"
        country={iso3}
      />
    );
    // TODO: add selected country definition
    return (
      <MenuItem
        key={iso3}
        iconWidget={iconWidget}
        title={displayCountryName(iso3)}
        onClick={this.handleCountrySelect(iso3)}
      />
    );
  }

  render() {
    const { onClose } = this.props;
    const { displayCountriesAmount, isLoaded } = this.state;
    const filterCountry = (iso3: string): boolean => {
      const filter = this.state.filter.toUpperCase();
      if (filter.length === 0) {
        return true;
      }
      const displayName = displayCountryName(iso3).toUpperCase();
      const iso2 = countriesISO3toISO2[iso3] || '';
      return iso3.includes(filter) || iso2.includes(filter) || displayName.includes(filter);
    };

    const countriesRender = iso3List.slice(0, displayCountriesAmount)
      .filter(filterCountry)
      .map(this.renderCountry);

    const spinnerElement = (<CircleSpinner key={'spinner'} />);

    const isCountryListEmpty = countriesRender.length === 0 && isLoaded;

    return (
      <NotificationModal
        titleWidget={this.renderHeaderWidget()}
        className="country-select-modal"
        onClose={onClose}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={this.getMoreCountries}
          initialLoad={true}
          useWindow={false}
          hasMore={isLoaded && iso3List.length > displayCountriesAmount}
          threshold={300}
          loader={spinnerElement}
        >
          <div className="country-select-body-wrapper">
            {countriesRender}
          </div>
        </InfiniteScroll>
        {isCountryListEmpty && (
          <EmptySearch
            className="search-empty-screen"
            description={texts.settingsPage.deliveryAddress.countryPicker.emptySearch}
          />
        )}
      </NotificationModal>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onClose: () => dispatch(closeModal()),
  };
}

export default connect(inject, actions)(CountrySelectModal);
