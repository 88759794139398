import * as React from 'react';

import classnames from 'classnames';
import { List } from 'immutable';

export interface ISegmentControlItem {
  id: any;
  content: any;
}

interface ISegmentControlProps {
  active: string;
  items: List<ISegmentControlItem>;
  onChange: (active: string) => any;
}

class SegmentControl extends React.Component<ISegmentControlProps, {}> {
  constructor(props) {
    super(props);

    this.renderSingleItem = this.renderSingleItem.bind(this);
  }

  renderSingleItem(item: ISegmentControlItem) {
    const { active, onChange } = this.props;
    const itemClass = classnames({
      'segment-control-item': true,
      'segment-control-item-active': item.id === active,
    });

    return (
      <div
        key={item.id}
        className={itemClass}
        onClick={ () => onChange(item.id) }
      >
        {item.content}
      </div>
    );
  }

  render() {
    const { items } = this.props;
    const contentRender = items.map(this.renderSingleItem);
    return (
      <div className="segment-control-wrapper">
        {contentRender}
      </div>
    );
  }
}

export default SegmentControl;
