import * as React from 'react';

import { connect } from 'react-redux';

import Button from '../../../../Basic/Button/Button';
import Input from '../../../../Basic/Inputs/Input/Input';

import { sendRecoveryEmail } from '../../../../../actions/auth';
import { texts } from '../../../../../constants/lang';
import { IFormInputWithValidation } from '../../../../../models/forms';
import { emptyFormInputWithValidation, setFormInputWithValidation, validatedFormEmail } from '../../../../../utils/forms';

interface ISendRecoverFormProps {
  onComplete: () => any;
  sendRecoveryEmail: (email: string) => any;
}

interface ISendRecoverFormState {
  email: IFormInputWithValidation;
}

class SendRecoveryForm extends React.Component <ISendRecoverFormProps, ISendRecoverFormState> {
  constructor(props) {
    super(props);
    this.state = {
      email: emptyFormInputWithValidation,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleEmailChange(value) {
    this.setState({
      email: setFormInputWithValidation(value),
    });
  }

  validateForm(): boolean {
    const validateEmail = validatedFormEmail();
    const validateText = validateEmail(this.state.email.value);

    if (validateText) {
      this.setState({
        email: {
          ...this.state.email,
          errorPrompt: validateText,
        },
      });
      return false;
    }
    return true;
  }

  handleFormSubmit() {
    // tslint:disable-next-line:no-shadowed-variable
    const { sendRecoveryEmail, onComplete } = this.props;

    if (this.validateForm()) {
      sendRecoveryEmail(this.state.email.value);
      onComplete();
    }
  }

  render() {
    const recoverTexts = texts.login.sendRecoveryEmail.step1;
    const { email } = this.state;
    return (
      <div className="send-recover-email-form">
        <div className="stepper"> {recoverTexts.stepper} </div>
        <h2 className="title"> {recoverTexts.title} </h2>
        <p className="description"> {recoverTexts.description} </p>
        <Input
          value={email.value}
          label={recoverTexts.emailLabel}
          placeholder={recoverTexts.emailPlaceholder}
          errorText={email.errorPrompt}
          type="email"
          onEnter={this.handleFormSubmit}
          onChange={this.handleEmailChange}
        />
        <Button
          width="block"
          pinnedBottom
          label={recoverTexts.action}
          onClick={this.handleFormSubmit}
        />

      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    sendRecoveryEmail: (email: string) => dispatch(sendRecoveryEmail(email)),
  };
}

export default connect(inject, actions)(SendRecoveryForm);
