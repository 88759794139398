import { List } from 'immutable';
import { blockType, cardStatus, virtualCardCreatingPredefined } from '../constants/cards';
import { routingPaths } from '../constants/routing';
import { cardTypes } from '../constants/uiConfig';
import { ICard } from '../models/cards';

export function maskCardNumber(cardNumber: string): string {
  return `${cardNumber.slice(0, 7)}** **** ${cardNumber.slice(15, 19)}`;
}

export function isCardMasked(cardNumber: string): boolean {
  return cardNumber.indexOf('****') > -1;
}

export function outputAlternativeCurrency(item, accountCurrency, revertedSign: boolean = false) {
  if (item.currency !== accountCurrency) {
    return `${revertedSign ? '+' : '-'} ${item.currency} ${item.amount.toFixed(2)}`;
  }
  return null;
}

export function createLinkToCard(id: string): string {
  return `${routingPaths.card.root}/${id}`;
}

export function formatCardNumber(cardNumber: string): string {
  const groups = [];
  for (let i = 0; i < cardNumber.length; i += 4) {
    let end = i + 4;
    if (end > cardNumber.length) {
      end = cardNumber.length;
    }
    groups.push(cardNumber.slice(i, end));
  }

  return groups.join(' ');
}

const blockedTypesToHide = [
  blockType.lost,
  blockType.stolen,
  blockType.counterfeit,
  blockType.fraudulent,
];

export function modifyCardList(cards: List<ICard>): List<ICard> {
  return addCreateCardScreen(filterOutBlockedCards(cards));
}

function addCreateCardScreen(cards: List<ICard>): List<ICard> {
  const canCreateCard = cards.filter(card => card.type === cardTypes.virtual).size === 0;
  return canCreateCard ? cards.push(virtualCardCreatingPredefined) : cards;
}

function filterOutBlockedCards(cards: List<ICard>): List<ICard> {
  return cards.filter(card => ! ((card.status === cardStatus.blocked) && (blockedTypesToHide.includes(card.blockType))));
}
