import * as React from 'react';

import { connect } from 'react-redux';

import Button from '../../../../Basic/Button/Button';
import PasswordInput from '../../../../Basic/Inputs/PasswordInput/PasswordInput';

import { changePassword } from '../../../../../actions/profile';
import { texts } from '../../../../../constants/lang';
import { IFormInputWithValidation } from '../../../../../models/forms';
import { profileErrorCodes } from '../../../../../models/profile';
import {
  changeFormField,
  emptyFormInputWithValidation,
  validateFormFieldIsEntered,
  validateFormPasswordComplexity,
  validateFormPasswordConfirmation,
} from '../../../../../utils/forms';

interface IPasswordFormProps {
  passwordInvalid: boolean;
  onChange: (currentPassword: string, newPassword: string, onSuccess: () => any) => any;
  onStepChange: () => any;
}

interface IPasswordFormState {
  previousPassword: IFormInputWithValidation;
  newPassword: IFormInputWithValidation;
  confirmPassword: IFormInputWithValidation;
}

type formFieldName = 'previousPassword' | 'newPassword' | 'confirmPassword';

class PasswordForm extends React.Component <IPasswordFormProps, IPasswordFormState> {

  fieldList: formFieldName[] = ['previousPassword' , 'newPassword' , 'confirmPassword'];
  onPreviousPasswordChange = this.onFormFieldChange('previousPassword');
  onNewPasswordChange = this.onFormFieldChange('newPassword');
  onConfirmationPasswordChange = this.onFormFieldChange('confirmPassword');

  constructor(props) {
    super(props);
    // @ts-ignore
    this.state = {};
    // @ts-ignore
    this.fieldList.forEach((key: formFieldName) => this.state[key] = emptyFormInputWithValidation);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps: IPasswordFormProps) {
    if (nextProps.passwordInvalid) {
      this.setState({
        previousPassword: {
          ...this.state.previousPassword,
          errorPrompt: texts.settingsPage.passwordPage.passwordForm.errorMessages.passwordDoesntMatch,
        },
      });
    }
  }

  onFormFieldChange(key: formFieldName) {
    return changeFormField(key, this);
  }

  // TODO: add UI to validate incorrect previous password
  validateForm() {
    const errorTexts = texts.settingsPage.passwordPage.passwordForm.errorMessages;
    let isValid = true;
    // @ts-ignore;
    const newState: IPasswordFormState = {};

    // Validate functions
    const validateFieldEntered = validateFormFieldIsEntered();
    const validateFieldComplexity = validateFormPasswordComplexity();
    const validateFieldMatch = validateFormPasswordConfirmation(this.state.newPassword.value);

    const validationsMap = {
      previousPassword: [
        validateFieldEntered,
      ],
      newPassword: [
        validateFieldComplexity,
        validateFieldEntered,
      ],
      confirmPassword: [
        validateFieldMatch,
        validateFieldEntered,
      ],
    };

    this.fieldList.forEach((key) =>  {
      validationsMap[key].forEach((validateFunction) => {
        const validateText = validateFunction(this.state[key].value);
        if (validateText && validateText.length !== 0) {
          isValid = false;
          newState[key] = {
            ...this.state[key],
            errorPrompt: validateText,
          };
        }
      });
    });

    if (!isValid) {
      this.setState(newState);
    }

    return isValid;
  }

  onFormSubmit() {
    if (this.validateForm()) {
      const { previousPassword, newPassword } = this.state;
      const { onStepChange } = this.props;
      this.props.onChange(previousPassword.value, newPassword.value, onStepChange);
    }
  }

  render() {
    const { previousPassword, confirmPassword, newPassword } = this.state;
    const passwordTexts = texts.settingsPage.passwordPage.passwordForm;

    return (
      <div className="password-form">
        <div
            className="password-form-description"
            dangerouslySetInnerHTML={{ __html: passwordTexts.description }}
        />
        <div className="form-wrapper">
          <PasswordInput
            label={passwordTexts.oldPassword}
            value={previousPassword.value}
            onChange={this.onPreviousPasswordChange}
            onEnter={this.onFormSubmit}
            errorText={previousPassword.errorPrompt}
          />
          <PasswordInput
            label={passwordTexts.newPassword}
            value={newPassword.value}
            onChange={this.onNewPasswordChange}
            onEnter={this.onFormSubmit}
            hintText={newPassword.errorPrompt ? null : passwordTexts.tooltip}
            errorText={newPassword.errorPrompt}
          />
          <PasswordInput
            label={passwordTexts.confirmPassword}
            value={confirmPassword.value}
            onChange={this.onConfirmationPasswordChange}
            onEnter={this.onFormSubmit}
            errorText={confirmPassword.errorPrompt}
          />

          <Button
            width="large"
            label={passwordTexts.update}
            onClick={this.onFormSubmit}
          />

        </div>
      </div>
    );
  }
}

function inject({ profile }) {
  return {
    // Define if API returns invalid previous password message
    passwordInvalid: profile.error === profileErrorCodes.passwordInvalid,
  };
}

function actions(dispatch) {
  return {
    onChange: (currentPassword, newPassword, onSuccess) => dispatch(changePassword(currentPassword, newPassword, onSuccess)),
  };
}

export default connect(inject, actions)(PasswordForm);
