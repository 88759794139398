
require.register("boolbase/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "boolbase");
  (function() {
    module.exports = {
	trueFunc: function trueFunc(){
		return true;
	},
	falseFunc: function falseFunc(){
		return false;
	}
};
  })();
});