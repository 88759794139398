import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Modal from '../../../Layout/Modal/Modal';
import CompletenessForm from './CompletenessForm/CompletenessForm';
import PasswordForm from './PasswordForm/PasswordForm';
import VerifyForm from './VerifyForm/VerifyForm';

import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

// tslint:disable-next-line:no-empty-interface
interface IPasswordPageProps extends IWithRouterModalChild {

}

interface IPasswordPageState {
  step: number;
}

class PasswordPage extends React.Component <IPasswordPageProps, IPasswordPageState> {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };

    this.onStepChange = this.onStepChange.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.settings.root);
  }

  onStepChange() {
    const previousStep = this.state.step;
    // TODO: don't forget to change after validation appear
    if (previousStep < 2) {
      this.setState({ step: previousStep + 1 });
    } else {
      this.onModalClose();
    }
  }

  renderModal(): React.ReactElement {
    const { withoutUserRoute } = this.props;
    const passwordText = texts.settingsPage.passwordPage;
    return (
      <Modal
        className="password-change-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalTitle={passwordText.title}
      >
        {this.state.step === 1 && (
          <PasswordForm onStepChange={this.onStepChange}/>
        )}
        {/* TODO: add validation step for password change function after testing phase */}
        {/*{this.state.step === 2 && (*/}
        {/*  <VerifyForm onChange={this.onStepChange} />*/}
        {/*)}*/}
        {this.state.step === 2 && (
          <CompletenessForm onChange={this.onStepChange} />
        )}
      </Modal>
    );
  }
  render() {
    this.props.onModalOpen(this.renderModal());
    return (
      <React.Fragment />
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(PasswordPage);
