import * as React from 'react';

import { List } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';

import MenuItem from '../../../Basic/MenuItem/MenuItem';
import Switcher from '../../../Basic/Switcher/Switcher';
import Modal from '../../../Layout/Modal/Modal';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { ICard, ICardSecurity } from '../../../../models/cards';

import { setCardSecuritySettings } from '../../../../actions/cards';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { cardTypes } from '../../../../constants/uiConfig';
import { createLinkToCard } from '../../../../utils/cards';

interface ICardSecurityProps extends IWithRouterModalChild {
  id: string;

  cards: List<ICard>;
  setSecuritySettings?: (id: string, updated: ICardSecurity) => any;
}

class CardSecurityPage extends React.Component <ICardSecurityProps, {}> {
  constructor(props) {
    super(props);

    this.storeUpdatedSettings = this.storeUpdatedSettings.bind(this);

    this.handleECommerce = this.handleECommerce.bind(this);
    this.handleStripe = this.handleStripe.bind(this);
    this.handleNFC = this.handleNFC.bind(this);
    this.handleATM = this.handleATM.bind(this);
    this.handleCountries = this.handleCountries.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  storeUpdatedSettings(settingName: string) {

    const { id, setSecuritySettings, cards } = this.props;
    const content = cards.find(card => card.id === id);
    const previousSettings = content.security;
    const updatedSettings: ICardSecurity = {
      ...previousSettings,
      [settingName]: !previousSettings[settingName],
    };

    setSecuritySettings(content.id, updatedSettings);
  }

  handleECommerce () {
    this.storeUpdatedSettings('internetPurchase');
  }
  handleStripe() {
    this.storeUpdatedSettings('magneticStripe');
  }
  handleNFC() {
    this.storeUpdatedSettings('nfc');
  }
  handleATM() {
    this.storeUpdatedSettings('withdrawal');
  }
  handleCountries() {
    this.storeUpdatedSettings('countries');
  }

  onModalClose() {
    const { id, onModalClose } = this.props;
    onModalClose(createLinkToCard(id));
  }

  renderModal(): React.ReactElement {
    const { id, withoutUserRoute, cards } = this.props;
    const content = cards.find(card => card.id === id);
    const cardType = content.type;

    const securityScreenTexts = texts.cardPage.card.cardSecurity;
    const defaultSettings: ICardSecurity = {
      internetPurchase: false,
      magneticStripe: false,
      nfc: false,
      withdrawal: false,
      countries: false,
    };

    const securitySettings = content.security || defaultSettings;

    return (
      <Modal
        className="card-security-modal"
        hideAppearTransition={withoutUserRoute}
        modalTitle={securityScreenTexts.title}
        onBack={this.onModalClose}
      >
        <div className="card-security-modal-wrapper">
          <p className="modal-description card-security-modal-description">
            {securityScreenTexts.description}
          </p>
          <div className="card-security-modal-menu">
            <MenuItem
              icon="web"
              title={securityScreenTexts.config.internetPurchase.title}
              subtitle={securityScreenTexts.config.internetPurchase.description}
              onClick={this.handleECommerce}
            >
              <Switcher checked={securitySettings.internetPurchase} />
            </MenuItem>

            { cardType === cardTypes.chipPin && (
              <MenuItem
                icon="credit-card"
                title={securityScreenTexts.config.magnetic.title}
                subtitle={securityScreenTexts.config.magnetic.description}
                onClick={this.handleStripe}
              >
                <Switcher checked={securitySettings.magneticStripe} />
              </MenuItem>
            )}

            <MenuItem
              icon="contactless-payment"
              title={securityScreenTexts.config.nfc.title}
              subtitle={securityScreenTexts.config.nfc.description}
              onClick={this.handleNFC}
            >
              <Switcher checked={securitySettings.nfc} />
            </MenuItem>
            { cardType !== cardTypes.virtual && (
              <MenuItem
                icon="cash"
                title={securityScreenTexts.config.withdrawal.title}
                subtitle={securityScreenTexts.config.withdrawal.description}
                onClick={this.handleATM}
              >
                <Switcher checked={securitySettings.withdrawal} />
              </MenuItem>
            )}
            {/* TODO: turn it on when functionality is ready */}
            {/*<MenuItem*/}
            {/*  icon="flag"*/}
            {/*  title={securityScreenTexts.config.countries.title}*/}
            {/*  subtitle={securityScreenTexts.config.countries.description}*/}
            {/*  onClick={this.handleCountries}*/}
            {/*>*/}
            {/*  <Switcher checked={securitySettings.countries} />*/}
            {/*</MenuItem>*/}
          </div>
        </div>
      </Modal>);
  }

  render() {

    this.props.onModalOpen(this.renderModal());

    // on desktop we will use this render to output content
    // on mobile we use action to push modal with content
    return (
      <React.Fragment />
    );
  }

}

function inject({ cards }) {
  return {
    cards: cards.cards,
  };
}

function actions(dispatch) {
  return {
    setSecuritySettings: (id: string, updated: ICardSecurity) => dispatch(setCardSecuritySettings(id, updated)),
  };
}

// @ts-ignore
export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(CardSecurityPage);
