/* tslint:disable:no-console */
import {
  generateTransactions,
  getCardLimits,
  initialTransaction,
  predefinedCards,
  predefinedProfile,
  unmaskCardNumber,
  virtualCard,
} from '../utils/fake';

const request = (data) => {
  return new Promise((resolve, reject) => {
    if (data) {
      setTimeout(() => {
        resolve(data);
      },         200);
    } else {
      reject();
    }
  });
};

class Api {
  accountTransactions(fromRecord: number, recordCount: number, fromDate?: Date, toDate?: Date) {
    const isToday = (new Date().getTime() - toDate.getTime()) < 10000;
    if (isToday) {
      console.log('Fake API: getting initial transactions');
    } else {
      console.log(`Fake API: getting transactions from ${toDate.toISOString()}`);
    }
    const transactions = !isToday ? generateTransactions(toDate) : initialTransaction();
    return request(transactions);
  }

  account() {
    console.log('Fake API: getting account information');
    return request({
      available_amount: 123.23,
      currency_code: 'EUR',
    });
  }

  me() {
    console.log('Fake API: getting profile');
    return request(predefinedProfile);
  }

  login(email, password) {
    console.log('Fake API: logging in');
    return request(true);
  }

  logout() {
    console.log('Fake API: logging out');
    return request(true);
  }

  cards() {
    console.log('Fake API: getting cards');
    const cards = predefinedCards();
    return request(cards);
  }

  blockCard(cardId) {
    console.log(`Fake API: freezing card ${cardId}`);
    return request(cardId);
  }

  unblockCard(cardId) {
    console.log(`Fake API: unfreezing card ${cardId}`);
    return request(cardId);
  }

  remindPin(cardId) {
    console.log(`Fake API: sending PIN reminder for card ${cardId}`);
    return request(cardId);
  }

  remindCvv(cardId) {
    console.log(`Fake API: sending CVV2 reminder for card ${cardId}`);
    return request(cardId);
  }

  setCardLimit(cardId: string, period: string, type: string, value: number) {
    console.log('Fake API: setting card limit', { cardId, period, type, value });
    return request(true);
  }

  listCardLimits(cardId: string) {
    console.log(`Fake API: getting card limits. Card ${cardId}`);
    return request(getCardLimits(cardId));
  }

  getCardNumber(cardId: string) {
    console.log(`Fake API: unmasking card number for ${cardId}`);
    return request({
      number: unmaskCardNumber(cardId),
    });
  }

  updateCardSecurity(cardId, cardSecurity) {
    console.log(`Fake API: updating card security for ${cardId}`, JSON.stringify(cardSecurity));
    return request(true);
  }

  createVirtualCard() {
    console.log('Fake API: create virtual card');
    return request(virtualCard());
  }

  changePassword(current: string, newPassword: string) {
    console.log(`Fake API: change password ${current} to ${newPassword}`);
    // return Promise.reject({code: 400});
    return request(true);
  }

  activateCard(cardId) {
    console.log(`Fake API: activate card ${cardId}`);
    return request(true);
  }

  getAccountTopUpCredentials() {
    console.log('Fake API: get topup credentials');
    return request({
      iban:                'EE072200221070126269',
      receiver_name:        'Wallester AS',
      swift_code:           'HABAEE2X',
      details:             'WCARDONE Fake Fake',
    });
  }

  sendRecoveryEmail(email: string) {
    console.log(`Fake API: send recovery link to ${email}`);
    return request(true);
  }

  recoverPasswordByToken(token: string, password: string) {
    console.log(`Fake API: set password by ${token}: ${password}`);
    return request(true);
  }

  checkPasswordRecoveryToken(token: string) {
    console.log(`Fake API: check password recovery token${token}`);
    return request({ is_valid: true });
  }

  updateAddress(address) {
    console.log('Fake API: update address', address);
    return request(true);
  }
}

export default new Api();
