import * as React from 'react';

import classnames from 'classnames';
import * as moment from 'moment';
import { connect } from 'react-redux';

import currencySymbols from '../../../constants/currencySymbols';

interface IListHeaderProps {
  date: Date;
  isStuck?: boolean;
  currencySymbol?: string;
  amount?: number;
  pageAnchor?: any;
}

class ListHeader extends React.Component <IListHeaderProps, {}> {

  render() {
    const { date, currencySymbol, amount } = this.props;
    const dateRender = moment(date).format('YYYY, MMM DD');
    const wrapperClass = classnames({
      'list-header': true,
      'list-header-stuck': this.props.isStuck,
    });

    return (
      <div className={wrapperClass}>
        <p className="date">
          {dateRender}
        </p>
        <p className="amount">
          {amount > 0 && '-'}
          {currencySymbol} {Math.abs(amount).toFixed(2)}
        </p>
      </div>
    );
  }
}

function inject({ account }) {
  return {
    currencySymbol: currencySymbols[account.currency],
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(ListHeader);
