import * as React from 'react';

import { connect } from 'react-redux';

import { showSnackbarMessage } from '../../actions/ui/snackbar';
import { snackbarMessageTypes } from '../../constants/uiConfig';

export interface IWithCopySnackbarChild {
  onCopy: (content: string) => any;
  isCopied: boolean;
}

interface IWithCopySnackbarProps {
  onCopySnackbar: (content: string) => any;
}

interface IWithCopySnackbarState {
  copied: boolean;
}

// tslint:disable-next-line:variable-name
function withCopySnackbar(Component) {

  const displayName = Component.displayName;

  class WrappedComponent extends React.Component<IWithCopySnackbarProps, IWithCopySnackbarState> {
    displayName = `withRouterModal(${displayName})`;
    copiedStateTimer;

    constructor(props) {
      super(props);

      this.state = {
        copied: false,
      };

      this.onCopyStarted = this.onCopyStarted.bind(this);
      this.onCopyEnd = this.onCopyEnd.bind(this);
    }

    componentWillUnmount() {
      clearTimeout(this.copiedStateTimer);
    }

    onCopyStarted(content: string) {
      // To avoid snackbar spam it's validate copied state
      if (!this.state.copied) {
        this.setState({ copied: true });
        this.copiedStateTimer = setTimeout(this.onCopyEnd, 4000);
        this.props.onCopySnackbar(content);
      }
    }

    onCopyEnd() {
      this.setState({ copied: false });
    }

    render() {
      return (
        <Component
          {...this.props}
          onCopy={this.onCopyStarted}
          isCopied={this.state.copied}
        />
      );
    }
  }

  function inject({}) {
    return {
    };
  }

  function actions(dispatch) {
    return {
      // tslint:disable-next-line:max-line-length
      onCopySnackbar: (content) => dispatch(showSnackbarMessage(snackbarMessageTypes.copied, content)),
    };
  }

  return connect(inject, actions)(WrappedComponent);
}

export default withCopySnackbar;
