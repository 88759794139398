require.register("css-select/lib/procedure.json", function(exports, require, module) {
  module.exports = {
  "universal": 50,
  "tag": 30,
  "attribute": 1,
  "pseudo": 0,
  "descendant": -1,
  "child": -1,
  "parent": -1,
  "sibling": -1,
  "adjacent": -1
}
;
});