import * as React from 'react';

// tslint:disable-next-line:function-name
function AvatarIcon() {
  return (
    <svg width="60px" height="60px" viewBox="0 0 60 60">
      <g fill="#FFFFFF">
        <circle opacity="0.1" cx="30" cy="30" r="30"/>
        <path d="M30,11.0113058 C30.1123336,11.0074334 30.2256553,11.0045309 30.3399721,11.0026201 C39.6555423,10.8469124 42.3624928,17.6762512 42.3624928,19.4783947 C42.3624928,20.6798237 42.3624928,22.7555401 42.3624928,25.705544 C43.0283555,26.2983745 43.1777652,27.5711391 43.1777652,28.518696 L43.1777652,32.4003977 C43.1777652,33.5350363 42.8495591,34.5336154 41.943246,35.1106533 C41.3731301,38.0540288 40.770449,39.7574619 39.8421053,41.2909091 C39.1353868,42.4582735 37.9899945,43.168531 37.9899945,43.168531 L37.9899945,47.1045364 C37.9912276,48.5380199 37.9703333,50.1005705 39.2490363,50.7881566 L47,54.2935072 C47.5721487,54.6008557 41.640061,60 30.7496028,60 C30.4976216,60 30.2477388,59.9971102 30,59.9914604 C29.7522612,59.9971102 29.5023784,60 29.2503972,60 C18.359939,60 12.3148523,54.6008557 12.8870009,54.2935072 L20.7509637,50.7881566 C22.0296667,50.1005705 22.0087724,48.5380199 22.0100055,47.1045364 L22.0100055,43.168531 C22.0100055,43.168531 20.8646132,42.4582735 20.1578947,41.2909091 C19.229551,39.7574619 18.6268699,38.0540288 18.056754,35.1106533 C17.1504409,34.5336154 16.8222348,33.5350363 16.8222348,32.4003977 L16.8222348,28.518696 C16.8222348,27.5711391 16.9716445,26.2983745 17.6375072,25.705544 C17.6375072,22.7555401 17.6375072,20.6798237 17.6375072,19.4783947 C17.6375072,17.6762512 20.3444577,10.8469124 29.6600279,11.0026201 C29.7743447,11.0045309 29.8876664,11.0074334 30,11.0113058 Z" opacity="0.5"/>
      </g>
    </svg>
  );
}

export default AvatarIcon;
