import * as React from 'react';

import { connect } from 'react-redux';
import { urlImage } from '../../../utils/other';

interface IPreloaderProps {
  logo: string;
  preloaderSpinner: number;
}

class Preloader extends React.Component <IPreloaderProps, {}> {
  render() {
    const { logo, preloaderSpinner } = this.props;
    const isVisible = preloaderSpinner > 0;

    return isVisible ? (
      <div className="preloader-wrapper">
        <div
          className="preloader-logo"
          style={{
            backgroundImage: urlImage(logo),
          }}
        />
        <div className="preloader-body">
          <div className="preloader">
            <div className="preloader-bounce-1" />
            <div className="preloader-bounce-2" />
          </div>
        </div>
        <p className="preloader-message"> Application is initiating </p>
      </div>
    ) : null;
  }
}

function inject({ ui }) {
  return {
    logo: ui.logo,
    preloaderSpinner: ui.preloadSpinner,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(Preloader);
