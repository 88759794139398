import { IFaqSection } from '../models/faq';
import { routingPaths } from './routing';

const uiLink = (url: string) => `/#${url}`;

const supportMailLink = `mailto:${routingPaths.other.supportMail}`;

export const faqTexts: IFaqSection[] = [
  {
    id: 'question_group_help',
    title: 'Help',
    content: [
      {
        id: 'help_question_1',
        question: 'How can I contact you to get help?',
        answer: `In case of any issues or questions, please contact us at <a href="${supportMailLink}">${routingPaths.other.supportMail}</a>.<br/>We will answer your questions: Monday – Friday, 9AM – 5 PM (CET).`,
      },
      {
        id: 'help_question_2',
        question: `What details should I provide when contacting <a href="${supportMailLink}">Customer Support</a>?`,
        answer: `When sending request to <a href="${supportMailLink}">Customer Support</a>, please provide following details: ` +
          '<ol>' +
            '<li>Your name and last name.</li>' +
            '<li>E-mail.</li>' +
            '<li>Date of birth.</li>' +
            '<li>Sticker number and/or masked card number.</li>' +
            '<li>Description of request/issue.</li>' +
          '</ol>',
      },
    ],
  },
  {
    id: 'question_group_wallester_stickers',
    title: 'Wallester stickers',
    content: [
      {
        id: 'wallester_stickers_question_1',
        question: 'What is sticker?',
        answer: 'Sticker is Visa card product which works the same as contactless card. You can do purchases by tapping your sticker at merchant’s POS terminal or you can make e-commerce purchases using your card data (16-digit card number, expiry date, CVV code).',
      },
      {
        id: 'wallester_stickers_question_2',
        question: 'How can I get sticker?',
        answer: `If you are reading this question, you already have one. You can have only one active or frozen sticker at a time.<br/>In case your sticker is in blocked or closed status, please contact our <a href="${supportMailLink}">Customer Support</a> to request new sticker.`,
      },
      {
        id: 'wallester_stickers_question_3',
        question: 'What kind of payment transactions can I do with the sticker?',
        answer: 'Sticker can be used to do payments' +
          '<ol>' +
            '<li>In stores where merchants have POS terminals accepting Visa contactless cards: ' +
            '<ol>' +
              '<li>To do low-value payment just tap your sticker at POS terminal.</li>' +
              '<li>To do high-value payment (above country’s contactless limit) tap your sticker at POS terminal and enter PIN code. Please note that not all POS terminals allow PIN entering without insertion of payment card.</li>' +
            '</ol></li>' +
            '<li>In online shops accepting Visa cards. To do payment just enter your card data.</li>' +
            '<li>In mobile apps accepting Visa cards. To add your sticker to mobile app just enter your card data.</li>' +
          '</ol>' +
          'Please note that it is not allowed to withdraw money in ATMs.',
      },
    ],
  },
  {
    id: 'question_group_wallester_virtual_cards',
    title: 'Wallester virtual cards',
    content: [
      {
        id: 'wallester_virtual_cards_question_1',
        question: 'What is virtual card?',
        answer: 'Virtual card is Visa card product which is issued instantly and can be used for online purchases at e-commerce merchants right away. There is no physical card, only card data (16-digit card number, expiry date, CVV code).',
      },
      {
        id: 'wallester_virtual_cards_question_2',
        question: 'How can I get virtual card?',
        answer: `Go to <a href="${uiLink(routingPaths.account.root)}"><strong>Overview</strong></a> page and click ‘New virtual card’. You can have only one active or frozen virtual card at a time.`,
      },
      {
        id: 'wallester_virtual_cards_question_3',
        question: 'What kind of payment transactions can I do with the virtual card?',
        answer: 'Virtual card can be used to do payments' +
          '<ol>' +
            '<li>In online shops accepting Visa cards. To do payment just enter your card data.</li>' +
            '<li>In mobile apps accepting Visa cards. To add your virtual card to mobile app just enter your card data.</li>' +
          '</ol>',
      },
    ],
  },
  {
    id: 'question_group_account',
    title: 'Account',
    content: [
      {
        id: 'account_question_1',
        question: 'How can I add money to the account?',
        answer: `You can add funds to your account using bank transfer. Go to <a href="${uiLink(routingPaths.account.root + routingPaths.account.topUp)}">Top up</a> page in Main menu to find details for bank transfer. Only SEPA payments are accepted. Money will be added to your Wallester account within 1 working day upon payment receiving.`,
      },
      {
        id: 'account_question_2',
        question: 'What is the meaning of ‘Account balance’, ‘Reserved amount’ and ‘Available amount’?',
        answer: '‘Account balance’ – amount of money on your Wallester’s account;<br/>‘Reserved amount’ – amount of reserved transactions which are pending to be withdrawn from account;<br/>‘Available amount’ – amount which is available to be used for payments.',
      },
      {
        id: 'account_question_3',
        question: 'How much money can I spend with my sticker and/or virtual card?"',
        answer: 'Cards do not have separate balances, only account has balance. You can do payments as long as account’s available amount is above 0.00 EUR or you haven’t exceeded card or account limits.',
      },
      {
        id: 'account_question_4',
        question: 'How can I change my personal data?',
        answer: `You can change your personal data by contacting <a href="${supportMailLink}">Customer Support</a>. For security reasons we are not allowing to change your e-mail address and phone number.`,
      },
    ],
  },
  {
    id: 'question_group_card_data',
    title: 'Card data',
    content: [
      {
        id: 'card_data_question_1',
        question: 'How can I find my card details?',
        answer: `You can see list of your cards on <a href="${uiLink(routingPaths.account.root)}">Overview</a> page. From here you can navigate to <strong>Card view</strong> page by clicking card in the list. On <strong>Card view</strong> page you can find following data:` +
          '<ol>' +
          '<li>Masked card number</li>' +
          '<li>Expiry date</li>' +
          '<li>Sticker number, if it is a sticker</li>' +
          '<li>Card status</li>' +
          '<li>Card limits</li>' +
          '</ol>' +
          'From <strong>Card view</strong> page you can navigate to' +
          '<ol>' +
          '<li><strong>Card transactions</strong> page to find <strong>Reserved</strong> and <strong>Completed</strong> transactions</li>' +
          '<li><strong>Card actions</strong> page to find 16-digit card number, request to send PIN and/or CVV code, request to freeze/unfreeze your card.</li>' +
          '</ol>',
      },
      {
        id: 'card_data_question_2',
        question: 'How can I get full card number of my sticker or virtual card?',
        answer: 'Every card has a 16-digit card number that you can reveal on <strong>Card action</strong> page. Please select the card you want to use, then on <strong>Card view</strong> page you can find a link to the <strong>Card actions</strong> page. Go to the <strong>Card actions</strong> page and click “Show” button. On the pop-up window you can find your selected card’s number and copy it if needed. Please keep your full card number safe!',
      },
      {
        id: 'card_data_question_3',
        question: 'How can I get expiry date of my sticker or virtual card?',
        answer: 'Go to <strong>Card view</strong> page and find it on your card image. Additionally, for the sticker - you can find expiry date printed on the front side of the sticker.',
      },
      {
        id: 'card_data_question_4',
        question: 'How can I get PIN code of my sticker?',
        answer: 'Go to <strong>Card actions</strong> page to request PIN code to be sent to phone number you used on registration.',
      },
      {
        id: 'card_data_question_5',
        question: 'How can I get CVV code of my sticker or virtual card?',
        answer: 'Go to <strong>Card actions</strong> page to request CVV code to be sent to phone number you used on registration.',
      },
      {
        id: 'card_data_question_6',
        question: 'What is the difference between the sticker number and the card number?',
        answer: 'Every sticker has both sticker number and card number. Sticker number is a reference number which needs to be used during sticker registration and activation. You can find it on the front side of the sticker. Card number is a 16-digit number which identifies your sticker in Visa network. You can find it on <strong>Card actions</strong> page and use when doing online purchases at e-commerce merchants. Please keep your full card number safe!',
      },
    ],
  },
  {
    id: 'question_group_card_actions',
    title: 'Card actions',
    content: [
      {
        id: 'card_actions_question_1',
        question: 'I have lost my card. What should I do?',
        answer: `You can immediately freeze your card on the <strong>Card actions</strong> page, this way it will not be possible to use the card for purchases. All purchases before card freezing got processed. If you want to close your card permanently, please contact our <a href="${supportMailLink}">Customer Support</a>.`,
      },
      {
        id: 'card_actions_question_2',
        question: 'What is the meaning of ‘Freeze card’?',
        answer: 'In case you do not want to use your card for a certain time, you can temporarily freeze it. All payments will be declined after freezing the card. If you want to continue using your card, you can unfreeze it on <strong>Card actions</strong> page.',
      },
      {
        id: 'card_actions_question_3',
        question: 'Why is my card frozen?',
        answer: 'Your card is in frozen status if you froze it before. You can unfreeze your card and continue doing payments with it if you navigate from <strong>Card view</strong> page to <strong>Card actions</strong> page and click ‘Unfreeze’ button.',
      },
      {
        id: 'card_actions_question_4',
        question: 'Why is my card blocked?',
        answer: `There can be several reasons why a card got blocked, please contact <a href="${supportMailLink}">Customer Support</a> for more information.`,
      },
      {
        id: 'card_actions_question_5',
        question: 'How can I close my card?',
        answer: `You can contact our <a href="${supportMailLink}">Customer Support</a> to request closing your card.`,
      },
      {
        id: 'card_actions_question_6',
        question: 'How can I activate a new sticker?',
        answer: 'You can activate your sticker' +
          '<ol>' +
            `<li>By contacting <a href="${supportMailLink}">Customer Support</a> and requesting to activate the sticker. <a href="${supportMailLink}">Customer Support</a> will link the sticker to your account and activate it.</li>` +
            `<li>By yourself clicking the link ‘New sticker card’ on <a href="${uiLink(routingPaths.account.root)}"><strong>Overview</strong></a> page and entering sticker number on <strong>Activation</strong> page (link is available only in case you do not have any active or frozen sticker).</li>` +
          '</ol>',
      },
      {
        id: 'card_actions_question_7',
        question: 'I deleted the SMS with my PIN code. How can I get it again?',
        answer: 'You can resend your PIN code from the <strong>Card actions</strong> page. Select the card you want to use, on <strong>Card view</strong> page you can find a link to <strong>Card actions</strong> page. Go to <strong>Card actions</strong> page and click ‘Send’ button on ‘Send PIN’ section. You should receive your PIN code within 2 minutes.',
      },
      {
        id: 'card_actions_question_8',
        question: 'I deleted the SMS with my CVV code. How can I get it again?',
        answer: 'You can resend your CVV code from the <strong>Card actions</strong> page. Select the card you want to use, on <strong>Card view</strong> page you can find a link to <strong>Card actions</strong> page. Go to <strong>Card actions</strong> page and click ‘Send’ button on ‘Send CVV‘ section. You should receive your CVV code within 2 minutes.',
      },
    ],
  },
  {
    id: 'question_group_spending_limits',
    title: 'Spending limits',
    content: [
      {
        id: 'spending_limits_question_1',
        question: 'Are there any limits for purchases I am doing?',
        answer: 'Yes, there are limits set for payments which you are doing with your cards:' +
          '<ol>' +
            '<li>Each of your cards has transaction, daily and monthly limits, you can find them on <strong>Card view</strong> page.</li>' +
            `<li>All your cards are linked to one account, which has daily and monthly limits, you can find them on <a href="${uiLink(routingPaths.card.root)}/0"><strong>Cards</strong></a> page.</li>` +
          '</ol>',
      },
      {
        id: 'spending_limits_question_2',
        question: 'What is transaction limit?',
        answer: 'Transaction limit is maximum amount of money which can be spent for one purchase. Payment exceeding this limit will be declined.',
      },
      {
        id: 'spending_limits_question_3',
        question: 'What is daily limit?',
        answer: 'Daily limit is maximum amount of money which can be spent during one day. Both – card and account have daily limits. If daily limit will be exceeded, payment will be declined.',
      },
      {
        id: 'spending_limits_question_4',
        question: 'What is monthly limit?',
        answer: 'Monthly limit is maximum amount of money which can be spent during one month. Both – card and account have monthly limits. If monthly limit will be exceeded, payment will be declined.',
      },
      {
        id: 'spending_limits_question_5',
        question: 'Can I change my account and card limits?',
        answer: `Yes, you can change limits by contacting <a href="${supportMailLink}">Customer Support</a> and requesting to change the limits. Please note that you can only decrease limits (see <a href="${routingPaths.settings.legal.terms}" target="_blank">Terms & Conditions</a>).`,
      },
    ],
  },
  {
    id: 'question_group_transactions',
    title: 'Transactions',
    content: [
      {
        id: 'transactions_question_1',
        question: 'What is the meaning of ‘Reserved’ transactions?',
        answer: '<strong>Reserved</strong> transaction is payment transaction successfully authorized by merchant, transaction amount is placed on hold and removed from ‘Available amount’ immediately.',
      },
      {
        id: 'transactions_question_2',
        question: 'What is the meaning of ‘Completed’ transactions?',
        answer: '<strong>Completed</strong> transaction is payment transaction received from the merchant and posted to cardholder’s account thereby removing reserved transaction and updating ‘Account balance’.',
      },
      {
        id: 'transactions_question_3',
        question: 'Why my payment was declined?',
        answer: `There can be several reasons why payment can be declined, e.g. insufficient funds, account or card limits exceeded, wrong card data entered (card number, expiry, PIN or CVV), card is in frozen or blocked status. If you have issues to do the payments, please contact our <a href="${supportMailLink}">Customer Support</a> to clarify the reason of problem.`,
      },
    ],
  },
  {
    id: 'question_group_more',
    title: 'More',
    content: [
      {
        id: 'more_question_1',
        question: 'Where can I find Terms & Conditions?',
        answer: `<a href="${routingPaths.settings.legal.terms}" target="_blank">Click to read and download Terms & Conditions</a>`,
      },
      {
        id: 'more_question_2',
        question: 'Where can I find Privacy Policy?',
        answer: `<a href="${routingPaths.settings.legal.privacy}" target="_blank">Click to read and download Privacy Policy</a>`,
      },
      {
        id: 'more_question_3',
        question: 'How is my personal data stored and processed?',
        answer: 'We are collecting and processing your personal data only in accordance with <strong>Privacy Policy</strong>, <strong>GDPR</strong> and other applicable laws. In case of any questions, please contact as at <a href="mailto:privacy@wallester.eu">privacy@wallester.eu</a>. We will answer your questions: Monday – Friday, 9 AM – 5 PM (CET).',
      },
      {
        id: 'more_question_4',
        question: 'About Wallester',
        answer: 'Wallester is one of the first CAAS (Card-as-a-Service) solution providers and fully licensed PSPs (Payment Service Provider) to provide Visa payment services in Europe. We offer simplified card issuing and processing platforms that provide merchants and financial institutions the possibility to launch new Visa payment services to their end-customers faster. Wallester has close to 30 internal products, which allow us to build tailor-made card solutions combined with financial schemas, currencies, unique pricing, loyalty and transaction carriers (virtual, wallet, CHIP&amp;PIN, bracelet, implant, integration with third party devices).',
      },
    ],
  },
];
