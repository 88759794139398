import * as React from 'react';

import { connect } from 'react-redux';

import Button from '../../../../Basic/Button/Button';
import PasswordInput from '../../../../Basic/Inputs/PasswordInput/PasswordInput';

import { recoverPasswordByToken } from '../../../../../actions/auth';
import { texts } from '../../../../../constants/lang';
import { IFormInputWithValidation } from '../../../../../models/forms';
import {
  changeFormField,
  emptyFormInputWithValidation,
  validateFormFieldIsEntered,
  validateFormPasswordComplexity, validateFormPasswordConfirmation,
} from '../../../../../utils/forms';
import { getRoutedByUserLink } from '../../../../../utils/routing';

interface IRecoverPasswordFormProps {
  recoveryToken?: string;
  onComplete: () => any;
  onModalClose: () => any;
  recoverPasswordByToken: (token: string, password: string, onSuccess: () => any) => any;
}

interface IRecoverPasswordFormState {
  password: IFormInputWithValidation;
  confirmation: IFormInputWithValidation;
}

type formFieldName = 'password' | 'confirmation';

class RecoverPasswordForm extends React.Component <IRecoverPasswordFormProps, IRecoverPasswordFormState> {
  fieldList: formFieldName[] = ['password', 'confirmation'];

  onPasswordChange = this.onFormFieldChange('password');
  onConfirmationChange = this.onFormFieldChange('confirmation');

  constructor(props) {
    super(props);
    this.state = {
      password: emptyFormInputWithValidation,
      confirmation: emptyFormInputWithValidation,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormFieldChange(key: formFieldName) {
    return changeFormField(key, this);
  }

  validateForm(): boolean {
    const errorTexts = texts.login.recoverPassword.step1.errorMessages;
    let isValid = true;
    // @ts-ignore;
    const newState: IRecoverPasswordFormState = {};

    // Validate functions
    const validateFieldEntered = validateFormFieldIsEntered();
    const validateFieldComplexity = validateFormPasswordComplexity();
    const validateFieldMatch = validateFormPasswordConfirmation(this.state.password.value);

    const validationsMap = {
      password: [
        validateFieldComplexity,
        validateFieldEntered,
      ],
      confirmation: [
        validateFieldMatch,
        validateFieldEntered,
      ],
    };

    this.fieldList.forEach((key) =>  {
      validationsMap[key].forEach((validateFunction) => {
        const validateText = validateFunction(this.state[key].value);
        if (validateText && validateText.length !== 0) {
          isValid = false;
          newState[key] = {
            ...this.state[key],
            errorPrompt: validateText,
          };
        }
      });
    });

    if (!isValid) {
      this.setState(newState);
    }

    return isValid;
  }

  onFormSubmit() {
    // tslint:disable-next-line:no-shadowed-variable
    const { recoverPasswordByToken, onComplete, recoveryToken } = this.props;
    const { password } = this.state;
    if (this.validateForm()) {
      recoverPasswordByToken(recoveryToken, password.value, onComplete);
    }
  }

  render() {
    const recoveryTexts = texts.login.recoverPassword.step1;
    const { password, confirmation } = this.state;
    return (
      <div className="recover-password-form">
        <div className="stepper"> {recoveryTexts.stepper} </div>
        <h2 className="title"> {recoveryTexts.title} </h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: recoveryTexts.description }}
        />
        <PasswordInput
          label={recoveryTexts.passwordLabel}
          value={password.value}
          onChange={this.onPasswordChange}
          hintText={password.errorPrompt ? null : recoveryTexts.passwordHint}
          errorText={password.errorPrompt}
          onEnter={this.onFormSubmit}
        />
        <PasswordInput
          label={recoveryTexts.confirmationLabel}
          value={confirmation.value}
          onChange={this.onConfirmationChange}
          errorText={confirmation.errorPrompt}
          onEnter={this.onFormSubmit}
        />

        <div className="form-controllers">
          <Button
            bordered
            label={recoveryTexts.actions.cancel}
            onClick={this.props.onModalClose}
          />

          <Button
            width="large"
            label={recoveryTexts.actions.update}
            onClick={this.onFormSubmit}
          />
        </div>

      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    recoverPasswordByToken: (token: string, password: string, onSuccess: () => any) => dispatch(recoverPasswordByToken(token, password, onSuccess)),
  };
}

export default connect(inject, actions)(RecoverPasswordForm);
