/* tslint:disable:max-line-length */
import * as React from 'react';

import { List } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Modal from '../../../Layout/Modal/Modal';
import LimitEditingModal from './LimitEditingModal/LimitEditingModal';
import PeriodSegmentControl from './PeriodSegmentControl/PeriodSegmentControl';
import SingleLimitWidget from './SingleLimitWidget/SingleLimitWidget';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { getCardLimitsConfig } from '../../../../actions/cards';
import { openModal } from '../../../../actions/ui/modals';
import { singleCardLimitPeriods } from '../../../../constants/cards';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { cardTypes } from '../../../../constants/uiConfig';
import { ICard } from '../../../../models/cards';
import { createLinkToCard } from '../../../../utils/cards';

interface ICardLimitsPageProps extends IWithRouterModalChild {
  id: string;
  shouldGoBack?: boolean;
  cards: List<ICard>;
  getCardLimits: (id: string) => any;
  openSubModal: (id: string, content: React.ReactElement) => any;
}

interface ICardLimitsPageState {
  currentLimitPeriod: string;
}

class CardLimitsPage extends React.Component <ICardLimitsPageProps, ICardLimitsPageState> {
  constructor(props) {
    super(props);
    this.state = {
      currentLimitPeriod: singleCardLimitPeriods.transaction,
    };
    this.onChangeLimitPeriod = this.onChangeLimitPeriod.bind(this);
    this.renderLimitsEditing = this.renderLimitsEditing.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  componentDidMount(): void {
    const { id, getCardLimits } = this.props;
    getCardLimits(id);
  }

  onChangeLimitPeriod(newType: string) {
    this.setState({
      currentLimitPeriod: newType,
    });
  }

  renderLimitsEditing(type: string) {
    return function () {
      const { id, cards, openSubModal } = this.props;
      const currentCard = cards.find((card) => card.id === id);

      openSubModal('limits-editing-modal', (
        <LimitEditingModal
          type={type}
          period={this.state.currentLimitPeriod}
          card={currentCard}
        />
      ));
    }.bind(this);
  }

  onModalClose() {
    const { id, onModalClose } = this.props;
    onModalClose(createLinkToCard(id));
  }

  renderModal(): React.ReactElement {
    const { id, withoutUserRoute, cards } = this.props;
    const { currentLimitPeriod } = this.state;

    const limitScreenTexts = texts.cardPage.card.cardLimits.overview;

    const currentCard = cards.find((card) => card.id === id);
    // TODO(w2lker): loading state
    if (!currentCard.limits) {
      return null;
    }

    const currentLimits = currentCard.limits[currentLimitPeriod];

    const cardType = currentCard.type;
    const hideProgress = currentLimitPeriod === singleCardLimitPeriods.transaction;

    return (
      <Modal
        className="card-limits-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalTitle={limitScreenTexts.title}
      >
        <div className="card-limits-modal-wrapper">
          <p className="modal-description card-limits-modal-description">
            {limitScreenTexts.description}
          </p>
          <PeriodSegmentControl
            currentPeriod={currentLimitPeriod}
            oChangeLimitPeriod={this.onChangeLimitPeriod}
          />
          <div className="card-limits-menu-wrapper">
            { cardType !== cardTypes.virtual && (
              <SingleLimitWidget
                type="purchase"
                hideProgress={hideProgress}
                currentLimits={currentLimits}
                onClick={this.renderLimitsEditing('purchase')}
              />
            )}
            { cardType === cardTypes.chipPin && (
              <SingleLimitWidget
                type="withdrawal"
                hideProgress={hideProgress}
                currentLimits={currentLimits}
                onClick={this.renderLimitsEditing('withdrawal')}
              />
            )}
            <SingleLimitWidget
              type="internetPurchase"
              hideProgress={hideProgress}
              currentLimits={currentLimits}
              onClick={this.renderLimitsEditing('internetPurchase')}
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    this.props.onModalOpen(this.renderModal());
    // this.props.onModalOpen(<LimitEditingModal onClose={this.props.onModalClose}/>);

    // on desktop we will use this render to output content, on mobile we use action to push modal with content
    return (
            <React.Fragment/>
    );
  }

}

function inject({ cards }) {
  return {
    cards: cards.cards,
  };
}

function actions(dispatch) {
  return {
    getCardLimits: (id: string) => dispatch(getCardLimitsConfig(id)),
    openSubModal: (id: string, content: React.ReactElement) => dispatch(openModal(id, content)),
  };
}

export default compose(
  withRouterModal,
  connect(inject, actions))
// @ts-ignore
// tslint:disable-next-line:ter-func-call-spacing
(CardLimitsPage);
