import * as React from 'react';

import { List } from 'immutable';
import { connect } from 'react-redux';

import ListHeader from '../../../../Basic/ListHeader/ListHeader';
import SingleTransaction from '../../../../Basic/Transactions/SingleTransaction/SingleTransaction';

import { singleTransactionTypes } from '../../../../../constants/transactions';
import { ISingleTransaction } from '../../../../../models/transactions';
import AdjustmentTransaction from '../../../../Basic/Transactions/AdjustmentTransaction/AdjustmentTransaction';

interface ITransactionDayListProps {
  transactions: List<ISingleTransaction>;
  pageAnchor?: any;
}

interface ITransactionDayListState {
  headerStuck: boolean;
  shouldDisplay: boolean;
  displayHeight: number;
}

class TransactionDayList extends React.Component <ITransactionDayListProps, ITransactionDayListState> {

  componentRef;

  constructor(props) {
    super(props);
    this.state = {
      headerStuck: false,
      shouldDisplay: true,
      displayHeight: null,
    };

    this.refComponent = this.refComponent.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount(): void {
    const anchor = this.props.pageAnchor;
    if (anchor) {
      anchor.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps: Readonly<ITransactionDayListProps>, prevState: Readonly<ITransactionDayListState>, snapshot?: any): void {
    const anchor = this.props.pageAnchor;
    const prevAnchor = prevProps.pageAnchor;
    if (anchor !== prevAnchor) {
      anchor.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount(): void {
    // TODO(w2lker): why is it null sometimes
    if (this.props.pageAnchor) {
      this.props.pageAnchor.removeEventListener('scroll', this.handleScroll);
    }
  }

  // Return amount of money spent (inverted negative value)
  calculateDaySum(): number {
    const { transactions } = this.props;
    let dayTotal = 0;
    transactions.forEach((transaction) => {
      if (transaction.type === singleTransactionTypes.transaction || transaction.type === singleTransactionTypes.authorization || transaction.type === singleTransactionTypes.withdraw) {
        dayTotal += transaction.amount;
      } else if (transaction.type === singleTransactionTypes.deposit || transaction.type === singleTransactionTypes.reverted) {
        dayTotal -= transaction.amount;
      }
    });
    return dayTotal;
  }

  refComponent(element) {
    this.componentRef = element;
  }

  handleScroll() {
    const pageScrollPosition = this.props.pageAnchor.scrollTop;
    const selfPosition = this.componentRef.offsetTop;
    const topPosition = 60;
    const calculatedScrollPosition = selfPosition - topPosition - 3;

    if ((pageScrollPosition >= calculatedScrollPosition)) {
      this.onHeaderStuck();
    } else if ((pageScrollPosition < calculatedScrollPosition)) {
      this.onHeaderUnstuck();
    }

    const selfHeight = this.componentRef.offsetHeight;
    const delta = selfHeight > 2 * window.innerHeight ? selfHeight + window.innerHeight : 3 * window.innerHeight;
    const topRenderPosition = pageScrollPosition - delta;
    const bottomRenderPosition = pageScrollPosition + delta;

    if ((selfPosition + selfHeight > topRenderPosition) && (selfPosition < bottomRenderPosition)) {
      this.contentShouldDisplay();
    } else {
      this.contentShouldHide(selfHeight);
    }

  }

  onHeaderStuck() {
    if (!this.state.headerStuck) {
      this.setState({
        headerStuck: true,
      });
    }
  }

  onHeaderUnstuck() {
    if (this.state.headerStuck) {
      this.setState({
        headerStuck: false,
      });
    }
  }

  contentShouldDisplay() {
    if (!this.state.shouldDisplay) {
      this.setState({
        shouldDisplay: true,
      });
    }
  }

  contentShouldHide(height: number) {
    if (this.state.shouldDisplay) {
      this.setState({
        shouldDisplay: false,
        displayHeight: height,
      });
    }
  }

  render() {
    const { transactions } = this.props;
    const { headerStuck, shouldDisplay, displayHeight } = this.state;
    const singleTransactionsRender = transactions.map((transaction, index) => {
      const lastOfDay = index === transactions.size - 1;
      return (transaction.type === singleTransactionTypes.deposit || transaction.type === singleTransactionTypes.withdraw) ? (
        <AdjustmentTransaction
          key={transaction.id}
          content={transaction}
          lastOfDay={lastOfDay}
        />
      ) : (
        <SingleTransaction
          key={transaction.id}
          content={transaction}
          lastOfDay={lastOfDay}
        />
      );
    });
    // @ts-ignore
    const date = transactions.first().date;
    return shouldDisplay ? (
      <div className="transaction-day-wrapper" ref={this.refComponent}>
        <ListHeader
          date={date}
          amount={this.calculateDaySum()}
          isStuck={headerStuck}
        />
        {singleTransactionsRender}
      </div>
    ) : (
      <div
        className="transaction-day-hidden"
        ref={this.refComponent}
        style={{
          height: displayHeight,
        }}
      />
    );
  }
}

function inject({ ui }) {
  return {
    pageAnchor: ui.pageAnchor,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(TransactionDayList);
