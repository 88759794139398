import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Modal from '../../../Layout/Modal/Modal';
import SendRecoveryForm from './SendRecoveryForm/SendRecoveryForm';
import SendRecoverySuccess from './SendRecoverySuccess/SendRecoverySuccess';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';

interface ISendRecoverEmailState {
  step: number;
}

const recoverySteps = {
  sendEmail: 1,
  success: 2,
};

class SendRecoveryEmail extends React.Component <IWithRouterModalChild, ISendRecoverEmailState> {
  constructor(props) {
    super(props);
    this.state = {
      step: recoverySteps.sendEmail,
    };

    this.showSuccessScreen = this.showSuccessScreen.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  showSuccessScreen() {
    this.setState({
      step: recoverySteps.success,
    });
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.home);
  }

  renderModal(): React.ReactElement {
    const { withoutUserRoute } = this.props;
    const { step } = this.state;
    const recoveryTexts = texts.login.sendRecoveryEmail;
    return (
      <Modal
        className="send-recover-email-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalTitle={recoveryTexts.title}
      >
        {step === recoverySteps.sendEmail && (
          <SendRecoveryForm
            onComplete={this.showSuccessScreen}
          />
        )}
        {step === recoverySteps.success && (
          <SendRecoverySuccess onClose={this.onModalClose} />
        )}
      </Modal>
    );
  }

  render() {

    this.props.onModalOpen(this.renderModal());

    return (
      <React.Fragment />
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

// @ts-ignore
export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(SendRecoveryEmail);
