import { ACCOUNT_GET, ACCOUNT_GET_TOP_UP_CREDENTIALS, AccountAction } from '../actions/account';
import { IAccountReducer } from '../models/account';

const initialState: IAccountReducer = {
  currency: 'EUR',
  amount: 0,
  topUpCredentials: {
    iban: '',
    receiverName: '',
    swift: '',
    details: '',
  },
};

export default function accountReducer(state = initialState, action: AccountAction) {
  switch (action.type) {
    case ACCOUNT_GET: {
      return {
        ...state,
        amount: action.payload.amount,
        currency: action.payload.currencyCode,
      };
    }
    case ACCOUNT_GET_TOP_UP_CREDENTIALS:
      return {
        ...state,
        topUpCredentials: action.payload,
      };
    default:
      return state;

  }
}
