import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { List } from 'immutable';

import CardBackground from './CardBackground/CardBackground';
import CardsSlider from './CardsSlider/CardsSlider';

import { listCards, maskAllCardNumbers } from '../../../actions/cards';
import { virtualCardCreatingPredefined } from '../../../constants/cards';
import { routingPaths } from '../../../constants/routing';
import { cardTypes } from '../../../constants/uiConfig';
import { ICard } from '../../../models/cards';
import { isCardMasked, modifyCardList } from '../../../utils/cards';
import { getCardPath } from '../../../utils/routing';

interface ICardsPageProps extends RouteComponentProps<any> {
  cards: List<ICard>;
  isAppLoaded: boolean;
  listCards: () => any;
  maskCards: () => any;
}

class CardsPage extends React.Component <ICardsPageProps, {}> {
  constructor(props) {
    super(props);
    this.handlePreviousCardOpen = this.handlePreviousCardOpen.bind(this);
    this.handleNextCardOpen = this.handleNextCardOpen.bind(this);
  }

  componentDidMount(): void {
    // tslint:disable-next-line:no-shadowed-variable
    const { isAppLoaded, listCards } = this.props;
    if (isAppLoaded) {
      listCards();
    }
  }

  componentWillReceiveProps(nextProps: Readonly<ICardsPageProps>, nextContext: any): void {
    const prevID = this.props.match.params.id;
    const nextID = nextProps.match.params.id;
    const prevCards = this.props.cards;
    const nextCards = nextProps.cards;
    // Handle if app still haven't receive any card
    // @ts-ignore
    if (nextCards.size === 1 && nextCards.first().id === virtualCardCreatingPredefined.id) { return; }

    this.handleCardCreated(prevID, nextCards);
    this.handleCardNotFound(nextID, nextCards, prevCards);
    this.handleCardMasking(nextID, prevID, nextCards);
  }

  handleCardRoute(id: string) {
    this.props.history.push(`${routingPaths.card.root}/${id}`);
  }

  handleCardCreated(id: string, cards: List<ICard>) {
    // TODO: validate card created different way after refactoring card inject functionality
    const isCardCreation = id === virtualCardCreatingPredefined.id;
    // @ts-ignore
    if (isCardCreation && cards.last().type !== cardTypes.creating) {
      // @ts-ignore
      this.handleCardRoute(cards.last().id);
    }
  }

  handleCardNotFound(id: string, cards: List<ICard>, previousCards: List<ICard>) {
    const cardDoesntExist = !cards.find(card => card.id === id);
    const cardIsNotCreating = id !== virtualCardCreatingPredefined.id;
    const previousIndex = previousCards.findIndex(card => card.id === id);

    if (cardDoesntExist && cardIsNotCreating) {
      // Handle card has been removed
      if (previousIndex !== -1) {
        const nextIndex = previousIndex > cards.size ? cards.size : previousIndex;
        const nextCard = cards.get(nextIndex);
        this.handleCardRoute(nextCard.id);
      } else {
        // Handle card is incorrect
        this.handleCardRoute(cards.get(0).id);
      }
    }
  }

  handleCardMasking(id: string, prevID: string, cards: List<ICard>) {
    const areCardsUnmasked = cards.filter((card) => !isCardMasked(card.number)).size > 0;
    if (id !== prevID && areCardsUnmasked) {
      this.props.maskCards();
    }
  }

  handlePreviousCardOpen() {
    const { cards, match, history } = this.props;
    const currentCardIndex = cards.findIndex((card) => card.id === match.params.id);
    if (currentCardIndex === 0) {
      return null;
    }
    const previousCardID = cards.get(currentCardIndex - 1).id;
    history.replace(getCardPath(previousCardID));
  }

  handleNextCardOpen() {
    const { cards, match, history } = this.props;
    const currentCardIndex = cards.findIndex((card) => card.id === match.params.id);
    if (currentCardIndex === cards.size - 1) {
      return null;
    }
    const nextCardID = cards.get(currentCardIndex + 1).id;
    history.replace(getCardPath(nextCardID));
  }

  render() {
    const { cards } = this.props;
    const { match, isAppLoaded } = this.props;

    // tslint:disable-next-line:radix
    const cardID = match.params.id;

    return isAppLoaded ? (
      <div className="page cards-page">
        <CardBackground activeCard={cardID} cards={cards} />
        <CardsSlider
          activeCard={cardID}
          onCardSwipePrevious={this.handlePreviousCardOpen}
          onCardSwipeNext={this.handleNextCardOpen}
          cards={cards}
        />
      </div>
    ) : null;
  }
}

function inject({ cards, ui }) {
  return {
    cards: modifyCardList(cards.cards),
    isAppLoaded: ui.preloadSpinner === 0,
  };
}

function actions(dispatch) {
  return {
    listCards: () => dispatch(listCards()),
    maskCards: () => dispatch(maskAllCardNumbers()),
  };
}

// @ts-ignore
export default connect(inject, actions)(CardsPage);
