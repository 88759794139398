import store from '../reducers/store';

// import api from '../api/api';
import api from '../api/fakeApi';

import { ICard, ICardLimitsByPeriod, ICardSecurity } from '../models/cards';

import { List } from 'immutable';
import { cardBrandingData, snackbarMessageTypes } from '../constants/uiConfig';
import { formatCardNumber, isCardMasked, maskCardNumber } from '../utils/cards';
import { handleErrors } from './error';
import { openNotificationModal } from './ui/modals';
import { showSnackbarMessage } from './ui/snackbar';
import { hidePreloadSpinner, hideRequestSpinner, showRequestSpinner } from './ui/spinner';

export const CARD_CREATE = 'CARD/CREATE';
export const CARDS_LIST = 'CARD/LIST';
export const CARD_UNMASK_NUMBER = 'CARD/UNMASK_NUMBER';
export const CARD_MASK_ALL_NUMBERS = 'CARD/MASK_ALL_NUMBERS';
export const CARD_FROZEN_SET = 'CARD/FROZEN/SET';
export const CARD_FROZEN_UNSET = 'CARD/FROZEN/UNSET';
export const CARD_ACTIVATE = 'CARD/ACTIVATE';
export const CARD_SECURE_SETTINGS_SET = 'CARD/SECURE_SETTINGS/SET';
export const CARD_LIMIT_SETTINGS_GET = 'CARD/LIMIT_SETTINGS/GET';
export const CARD_LIMIT_SETTINGS_SET = 'CARD/LIMIT_SETTINGS/SET';

interface ICardCreate {
  type: typeof CARD_CREATE;
  payload: ICard;
}

export function createVirtualCard() {
  return handleErrors(dispatch => {
    dispatch(showRequestSpinner());
    return api.createVirtualCard().then(card => {
      dispatch({
        type: CARD_CREATE,
        // @ts-ignore
        payload: patchCard(card),
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardList {
  type: typeof CARDS_LIST;
  payload: List<ICard>;
}

function patchCard(card: ICard): ICard {
  return {
    ...card,
    created: new Date(card.created),
    number: formatCardNumber(card.number),
          // TODO (white-labeling): path white-labeled branding data here
    preview: cardBrandingData,
    expires: {
      ...card.expires,
      year: Number(card.expires.year) % 100,
    },
  };
}

export function listCards() {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.cards().then((cards: ICard[]) => {
      const mapped = (cards || [])
        .map(patchCard)
        .sort((a: ICard, b: ICard) => (+ a.created) - (+ b.created));

      dispatch({
        type: CARDS_LIST,
        payload: List(mapped),
      });
      dispatch(hidePreloadSpinner());
      dispatch(hideRequestSpinner());
    });
  // tslint:disable-next-line:align
  }, hidePreloadSpinner);
}

interface ICardNumberGetUnmasked {
  type: typeof CARD_UNMASK_NUMBER;
  payload: {
    id: string,
    number: string,
  };
}

export function getUnmaskedCardNumber(cardID) {
  return handleErrors(dispatch => {
    dispatch(showRequestSpinner());
    return api.getCardNumber(cardID).then(({ number }) => {
      dispatch({
        type: CARD_UNMASK_NUMBER,
        payload: {
          number: formatCardNumber(number),
          id: cardID,
        },
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardNumbersMaskAll {
  type: typeof CARD_MASK_ALL_NUMBERS;
  payload: List<ICard>;
}

export function maskAllCardNumbers() {
  return (dispatch) => {
    const cards = store.getState().cards.cards;
    const updatedCards = cards.map((card) => {
      if (isCardMasked(card.number)) {
        return card;
      }
      return {
        ...card,
        number: maskCardNumber(card.number),
      };
    });
    dispatch({
      type: CARD_MASK_ALL_NUMBERS,
      payload: updatedCards,
    });
  };
}

interface ICardActivate {
  type: typeof CARD_ACTIVATE;
  payload: string;
}

export function activateCard(id: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.activateCard(id).then(() => {
      dispatch({
        type: CARD_ACTIVATE,
        payload: id,
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardSetFreeze {
  type: typeof CARD_FROZEN_SET;
  payload: string;
}

export function setCardToFrozenState(id: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.blockCard(id).then(() => {
      dispatch({
        type: CARD_FROZEN_SET,
        payload: id,
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardSetUnfreeze {
  type: typeof CARD_FROZEN_UNSET;
  payload: string;
}

export function setCardToUnfreezeState(id: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.unblockCard(id).then(() => {
      dispatch({
        type: CARD_FROZEN_UNSET,
        payload: id,
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardSecuritySetSettings {
  type: typeof CARD_SECURE_SETTINGS_SET;
  payload: {
    id: string,
    security: ICardSecurity,
  };
}

export function setCardSecuritySettings(cardID: string, newSettings: ICardSecurity) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.updateCardSecurity(cardID, newSettings).then(() => {
      dispatch({
        type: CARD_SECURE_SETTINGS_SET,
        payload: {
          id: cardID,
          security: newSettings,
        },
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardLimitsGetConfig {
  type: typeof CARD_LIMIT_SETTINGS_GET;
  payload: {
    id: string,
    limits: ICardLimitsByPeriod,
  };
}

export function getCardLimitsConfig(cardID: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.listCardLimits(cardID).then(limits => {
      dispatch({
        type: CARD_LIMIT_SETTINGS_GET,
        payload: {
          limits,
          id: cardID,
        },
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ICardLimitsSetConfig {
  type: typeof CARD_LIMIT_SETTINGS_SET;
  payload: {
    id: string,
    period: any,
    type: any,
    amount: any,
  };
}

export function setCardLimitConfig(id: string, period: string, type: string, amount: number, successMessage?: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.setCardLimit(id, period, type, amount).then(() => {
      dispatch({
        type: CARD_LIMIT_SETTINGS_SET,
        payload: {
          id,
          period,
          type,
          amount,
        },
      });
      dispatch(showSnackbarMessage(snackbarMessageTypes.success, successMessage));
      dispatch(hideRequestSpinner());
    });
  });
}

export function getCardPINReminder(id: number, content) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.remindPin(id).then(() => {
      dispatch(openNotificationModal(content));
      dispatch(hideRequestSpinner());
    });
  });
}

export function getCardCVVReminder(id: number, content) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.remindCvv(id).then(() => {
      dispatch(openNotificationModal(content));
      dispatch(hideRequestSpinner());
    });
  });
}

export type CardsActions = ICardNumberGetUnmasked | ICardNumbersMaskAll | ICardSetFreeze | ICardSetUnfreeze |
  ICardSecuritySetSettings | ICardLimitsGetConfig | ICardLimitsSetConfig | ICardList | ICardCreate | ICardActivate;
