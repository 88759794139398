import * as React from 'react';

import { List } from 'immutable';

import SegmentControl, { ISegmentControlItem } from '../../../../Basic/SegmentControl/SegmentControl';

import { singleCardLimitPeriods } from '../../../../../constants/cards';
import { texts } from '../../../../../constants/lang';

interface IPeriodSegmentControlProps {
  currentPeriod;
  oChangeLimitPeriod: (active: string) => any;
}

// tslint:disable-next-line:function-name
function PeriodSegmentControl(props: IPeriodSegmentControlProps) {
  const { currentPeriod, oChangeLimitPeriod } = props;
  const limitScreenTexts = texts.cardPage.card.cardLimits.overview;

  const segmentControlContent: List<ISegmentControlItem> = List(Object.keys(singleCardLimitPeriods).map(key => {
    return {
      id: singleCardLimitPeriods[key],
      content: limitScreenTexts.types[key],
    };
  }));

  return (
    <SegmentControl
      active={currentPeriod}
      items={segmentControlContent}
      onChange={oChangeLimitPeriod}
    />
  );
}

export default PeriodSegmentControl;
