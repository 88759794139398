
require.register("react-router-dom/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {"transform":["loose-envify"]}, "react-router-dom");
  (function() {
    "use strict";

if ('development' === "production") {
  module.exports = require("./cjs/react-router-dom.min.js");
} else {
  module.exports = require("./cjs/react-router-dom.js");
}
  })();
});