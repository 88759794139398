import * as React from 'react';

import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Icon from '../../../../Basic/Icon/Icon';
import IconButton from '../../../../Basic/IconButton/IconButton';

import { ICard } from '../../../../../models/cards';

import { getUnmaskedCardNumber, setCardToUnfreezeState } from '../../../../../actions/cards';
import { blockType, cardStatus } from '../../../../../constants/cards';
import { texts } from '../../../../../constants/lang';
import { cardTypes } from '../../../../../constants/uiConfig';
import { isCardMasked } from '../../../../../utils/cards';
import { urlImage } from '../../../../../utils/other';
import Button from '../../../../Basic/Button/Button';
import withCopySnackbar, { IWithCopySnackbarChild } from '../../../../Decorators/withCopySnackbar';

interface ICardPreviewProps extends IWithCopySnackbarChild {
  content?: ICard;
  onUnmask?: (id: string) => any;
  onUnfreeze?: (id: string) => any;
}

class CardPreview extends React.Component <ICardPreviewProps, {}> {

  constructor(props) {
    super(props);

    this.onUnmaskCard = this.onUnmaskCard.bind(this);
    this.onUnfreezeCard = this.onUnfreezeCard.bind(this);
    this.onCopyCard = this.onCopyCard.bind(this);
  }

  onUnfreezeCard() {
    const { content, onUnfreeze } = this.props;
    onUnfreeze(content.id);
  }

  onUnmaskCard() {
    this.props.onUnmask(this.props.content.id);
  }

  onCopyCard() {
    this.props.onCopy(texts.cardPage.card.statusMessages.numberCopied);
  }

  render() {
    const { content } = this.props;
    const { issuerLogo, schemeLogo } = content.preview;
    const cardTexts = texts.cardPage.card;
    const { expires, number } = content;

    const cardIsFrozen = content.status === cardStatus.blocked && content.blockType === blockType.blockedByCardholder;

    const wrapperClass = classnames({
      'card-preview': true,
      'card-preview-physical': content.type === cardTypes.chipPin,
      'card-preview-sticker': content.type === cardTypes.sticker,
      'card-preview-virtual': content.type === cardTypes.virtual  || content.type === cardTypes.creating,
      'card-preview-creating': content.type === cardTypes.creating,
      'card-frozen': cardIsFrozen,
    });

    const isMasked: boolean = isCardMasked(number);

    const actionButton = isMasked ? (
      <IconButton
        className="number-action-button"
        onClick={this.onUnmaskCard}
      >
        <Icon name="eye" />
      </IconButton>
    ) : (
      <CopyToClipboard text={removeSpaces(number)} onCopy={this.onCopyCard}>
        <IconButton className="number-action-button">
          <Icon name="content-copy" />
        </IconButton>
      </CopyToClipboard>
    );

    const actionButtonRender = content.status === cardStatus.active ? actionButton : null;

    const frozenButton = cardIsFrozen ? (
      <Button
        width="medium"
        className="frozen-button"
        label="Unfreeze"
        onClick={this.onUnfreezeCard}
      />
    ) : null;

    const plusButton = content.type === cardTypes.creating ? (
      <div className="plus-button">
        <Icon name="plus" />
      </div>
    ) : null;

    const modifyDate = (date: number | string) => `0${date}`.slice(-2);

    return (
      <React.Fragment>
        <div className={wrapperClass}>
            <div className="card-preview-head">
                <div className="card-preview-type"> {cardTexts.cardTypes[content.type]} </div>
                <div
                    className="card-preview-logo-issuer"
                    style={{
                      backgroundImage: urlImage(issuerLogo),
                    }}
                />
            </div>
            <div className="card-preview-number">
                <h4 className="card-preview-number-content">{ number }</h4>
                {actionButtonRender}
            </div>
            <div className="card-preview-footer">
                <div className="card-preview-expires">
                    <p className="label"> {cardTexts.expiresTitle} </p>
                    <p className="value"> {modifyDate(expires.month)}/{modifyDate(expires.year)} </p>
                </div>
                <div
                    className="card-preview-footer-issuer"
                    style={{
                      backgroundImage: urlImage(schemeLogo),
                    }}
                />
            </div>
        </div>
        {plusButton}
        {frozenButton}
      </React.Fragment>
    );
  }
}

function removeSpaces(value: string): string {
  return value.replace(new RegExp(' ', 'g'), '');
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    onUnmask: (id: string) => dispatch(getUnmaskedCardNumber(id)),
    onUnfreeze: (id: string) => dispatch(setCardToUnfreezeState(id)),
  };
}

// @ts-ignore
export default compose(
  withCopySnackbar,
  connect(inject, actions),
// @ts-ignore
)(CardPreview);
