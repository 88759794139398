
require.register("html-element-map/byConstructor.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "html-element-map");
  (function() {
    'use strict';

var filter = require('array-filter');
var getData = require('./getData');

module.exports = function byConstructor(constructor) {
	if (!constructor) {
		return [];
	}

	var data = getData();
	if (constructor === data.unknown) {
		return [];
	}
	if (constructor === data.all) {
		return data.elements;
	}
	return filter(data.elements, function (item) {
		return item.constructor === constructor;
	});
};
  })();
});