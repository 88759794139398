import * as React from 'react';

import classnames from 'classnames';

interface IIconProps {
  name?: string;
  className?: string;
  style?: any;
}

// tslint:disable-next-line:function-name
function Icon({ name, className, style }: IIconProps) {
  // Don't render Icon without icon name
  if (!name) {
    return null;
  }

  const iconClass = classnames('icon', 'mdi', `mdi-${name}`, className);
  return (
    <div className={iconClass} style={style} />
  );
}

export default Icon;
