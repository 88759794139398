import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Root from './components/Root/Root';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
      <Root/>,
      document.getElementById('app'));
});
