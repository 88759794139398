export type AuthErrorType = 'login/invalid-credentials' | 'login/recover-token-outdated';

interface IAuthErrorCodes {
  invalidCredentials: AuthErrorType;
  recoverTokenOutdated: AuthErrorType;
}

export const authErrorCodes: IAuthErrorCodes = {
  invalidCredentials: 'login/invalid-credentials',
  recoverTokenOutdated: 'login/recover-token-outdated',
};

export interface IAuthReducerState {
  loggedIn: boolean;
  error?: AuthErrorType;
}
