import * as React from 'react';

import ProfilePreview from './ProfilePreview/ProfilePreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';

class SettingsPage extends React.Component <{}, {}> {
  render() {
    return (
      <div className="page settings-page">
        <ProfilePreview />
        <SettingsMenu />
      </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default SettingsPage;
