import * as React from 'react';

import classnames from 'classnames';

import Icon from '../Icon/Icon';
import MenuItem from '../MenuItem/MenuItem';
import AccordionBody from './AccordionBody';

interface IAccordionProps {
  title: string | React.ReactElement;
  icon?: string;
  className?: any;
  isOpen?: boolean;
  children: any;
}

interface IAccordionState {
  isAccordionOpen: boolean;
}

class Accordion extends React.Component <IAccordionProps, IAccordionState> {

  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: this.props.isOpen,
    };

    this.handleAccordionOpen = this.handleAccordionOpen.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<IAccordionProps>, nextContext: any): void {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState({
        isAccordionOpen: nextProps.isOpen,
      });
    }
  }

  handleAccordionOpen() {
    this.setState({ isAccordionOpen: !this.state.isAccordionOpen });
  }

  render() {
    const { title, icon, children } = this.props;
    const { isAccordionOpen } = this.state;
    const accordionIcon = isAccordionOpen ? 'chevron-up' : 'chevron-down';
    const wrapperClass = classnames({
      'accordion-wrapper': true,
      'accordion-wrapper-opened': isAccordionOpen,
      'accordion-wrapper-closed': !isAccordionOpen,
    });
    return (
      <div className={wrapperClass} >
          <MenuItem
            title={title}
            icon={icon}
            onClick={this.handleAccordionOpen}
          >
            <Icon name={accordionIcon} />
          </MenuItem>
          <AccordionBody isOpen={isAccordionOpen}>
            {children}
          </AccordionBody>
      </div>
    );
  }
}

export default Accordion;
