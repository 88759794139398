
require.register("react-copy-to-clipboard/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-copy-to-clipboard");
  (function() {
    'use strict';

var _require = require('./Component'),
    CopyToClipboard = _require.CopyToClipboard;

CopyToClipboard.CopyToClipboard = CopyToClipboard;
module.exports = CopyToClipboard;
  })();
});