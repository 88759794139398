import * as React from 'react';

import classnames from 'classnames';
import * as moment from 'moment';
import { connect } from 'react-redux';

import ListItem from '../../ListItem/ListItem';
import SingleTransactionIcon from '../SingleTransactionIcon/SingleTransactionIcon';

import { texts } from '../../../../constants/lang';
import {
  depositTransaction,
  singleTransactionTypes,
  withdrawTransaction,
} from '../../../../constants/transactions';
import { ISingleTransaction } from '../../../../models/transactions';
import { outputAlternativeCurrency } from '../../../../utils/cards';
import { transactionDateFormat } from '../../../../utils/transactions';

interface IAdjustmentTransactionProps {
  content: ISingleTransaction;
  lastOfDay: boolean;
  showFullDate?: boolean;
}

class AdjustmentTransaction extends React.Component <IAdjustmentTransactionProps, {}> {
  render() {
    const { content, lastOfDay, showFullDate } = this.props;
    const isDeposit = content.type === singleTransactionTypes.deposit;
    const config = isDeposit ? depositTransaction : withdrawTransaction;
    const type = isDeposit ? singleTransactionTypes.deposit : singleTransactionTypes.withdraw;
    const sign = isDeposit ? '+' : '-';

    const iconRender: React.ReactElement = (
      <SingleTransactionIcon
        name={config.icon}
        className={config.cssName}
        type={type}
      />
    );

    // TODO: define are we output top-up transactions on search
    const timeFormat = transactionDateFormat(showFullDate);
    const time = moment(content.date).format(timeFormat);
    const alternativeCurrency = outputAlternativeCurrency(content.alternativeCurrency, content.currency, isDeposit);

    const depositClassName = classnames({
      'single-transaction': true,
      'single-transaction-deposit': isDeposit,
      'single-transaction-withdraw': !isDeposit,
      'single-transaction-last-of-day': lastOfDay,
    });

    return (
      <ListItem
        className={depositClassName}
        leftElement={iconRender}
        title={content.title}
        contentLine1={content.details || time}
        contentLine2={content.details ? time : null}
        rightPrimary={`${sign}${content.amount.toFixed(2)}`}
        rightSecondary={alternativeCurrency}
      />
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(AdjustmentTransaction);
