import * as React from 'react';

import { List } from 'immutable';
import * as ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';

import Snackbar from '../Snackbar/Snackbar';

import { ISnackbarElement } from '../../../models/ui';

interface ISnackbarHandlerProps {
  snackbarStack: List<ISnackbarElement>;
}

class SnackbarHandler extends React.Component <ISnackbarHandlerProps, {}> {
  render() {
    const { snackbarStack } = this.props;
    const snackbarsRender = snackbarStack.reverse().map((item) => (
      <Snackbar key={item.timestamp} {...item} />
    ));

    return (
      <div className="snackbar-holder">
        <ReactCSSTransitionGroup
          transitionName="slide"
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
        >
          {snackbarsRender}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

function inject({ ui }) {
  return {
    snackbarStack: ui.snackbarStack,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(SnackbarHandler);
