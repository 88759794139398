import * as React from 'react';

import * as History from 'history';
import { Link } from 'react-router-dom';

interface IConditionalWrapperProps {
  className: any;
  onClick: (any) => any;
  to: History.LocationDescriptor;
  children: any;
}

// tslint:disable-next-line:function-name
function ConditionalWrapper(props: IConditionalWrapperProps) {

  if (props.to) {
    // @ts-ignore
    return props.to.includes && (props.to.includes('http') || props.to.includes('www')) ?
      // @ts-ignore
      <a href={props.to} target="_blank" {...props}> {props.children} </a> :
      <Link {...props}> {props.children} </Link>;
  }
  if (props.className) {
    return <div {...props}> {props.children} </div>;
  }

  return <React.Fragment> {props.children} </React.Fragment>;

}

export default ConditionalWrapper;
