import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { closeModal, openModal } from '../../actions/ui/modals';

export interface IWithRouterModalChild extends RouteComponentProps<any> {
  withoutUserRoute: boolean;
  onModalOpen: (connect: React.ReactElement) => any;
  onModalClose: (url: string) => any;
  onModalBodyClose: (url: string) => any;
}

interface IWithRouterModalProps extends RouteComponentProps<any> {
  onModalOpen: (id: string, content: React.ReactElement) => any;
  onModalClose: () => any;
}

// tslint:disable-next-line:variable-name
function withRouterModal(Component) {

  const displayName = Component.displayName;

  class WrappedComponent extends React.Component<IWithRouterModalProps, {}> {
    displayName = `withRouterModal(${displayName})`;

    constructor(props) {
      super(props);

      this.onModalOpen = this.onModalOpen.bind(this);
      this.onModalClose = this.onModalClose.bind(this);
    }

    componentDidMount(): void {
      const backListenerRemoval = this.props.history.listen((location, action) => {
        if (action === 'POP') {
          this.props.onModalClose();
          backListenerRemoval();
        }
      });
    }

    isRoutedByUser(): boolean {
      const locationState = this.props.location.state;
      return locationState && locationState.routerByUser;
    }

    onModalClose(url: string) {
      if (this.isRoutedByUser()) {
        this.props.history.goBack();
      } else {
        this.props.history.push(url);
      }
      this.props.onModalClose();
    }

    onModalOpen(content: React.ReactElement) {
      const modalID = this.props.location.pathname;
      this.props.onModalOpen(modalID, content);
    }

    render() {
      return (
        <Component
          {...this.props}
          onModalOpen={this.onModalOpen}
          onModalClose={this.onModalClose}
          withoutUserRoute={!this.isRoutedByUser()}
        />
      );
    }
  }

  function inject({}) {
    return {
    };
  }

  function actions(dispatch) {
    return {
      onModalOpen: (id: string, content: React.ReactElement) => dispatch(openModal(id, content)),
      onModalClose: () => dispatch(closeModal()),
    };
  }

  return connect(inject, actions)(WrappedComponent);
}

// @ts-ignore
export default withRouterModal;
