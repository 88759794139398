// import api from '../api/api';
import api from '../api/fakeApi';

import { IAccountTopUpCredentials } from '../models/account';
import { handleErrors } from './error';
import { hidePreloadSpinner, hideRequestSpinner, showRequestSpinner } from './ui/spinner';

export const ACCOUNT_GET = 'ACCOUNT/GET';
export const ACCOUNT_GET_TOP_UP_CREDENTIALS = 'ACCOUNT/GET_TOP_UP_CREDENTIALS';

interface IGetAccount {
  type: typeof ACCOUNT_GET;
  payload: {
    amount: number,
    currencyCode: string,
  };
}

interface IAccountResponse {
  available_amount: number;
  currency_code: number;
}

export function getAccount() {
  // tslint:disable-next-line:no-shadowed-variable
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.account().then((account: IAccountResponse) => {
      dispatch({
        type: ACCOUNT_GET,
        payload: {
          amount: account.available_amount || 0,
          currencyCode: account.currency_code || '',
        },
      });
      dispatch(hidePreloadSpinner());
      dispatch(hideRequestSpinner());
    });
    // tslint:disable-next-line:align
  }, hidePreloadSpinner);
}

export interface IGetAccountTopUpCredentials {
  type: typeof ACCOUNT_GET_TOP_UP_CREDENTIALS;
  payload: IAccountTopUpCredentials;
}

interface IAccountTopUpCredentialsResponse {
  iban: string;
  receiver_name: string;
  swift_code: string;
  details: string;
}

export function getAccountTopUpCredentials() {
  return handleErrors(dispatch => {
    dispatch(showRequestSpinner());
    return api.getAccountTopUpCredentials().then((credentials: IAccountTopUpCredentialsResponse) => {
      dispatch({
        type: ACCOUNT_GET_TOP_UP_CREDENTIALS,
        payload: {
          iban: credentials.iban || '',
          receiverName: credentials.receiver_name || '',
          swift: credentials.swift_code || '',
          details: credentials.details || '',
        },
      });
      dispatch(hideRequestSpinner());
    });
  });
}

export type AccountAction = IGetAccount | IGetAccountTopUpCredentials;
