import { List } from 'immutable';
import * as Moment from 'moment';
import store from '../reducers/store';

import { ICard, ICardLimitsByPeriod, ICardSecurity } from '../models/cards';

import { blockType, cardStatus } from '../constants/cards';
import { singleTransactionTypes } from '../constants/transactions';
import { cardBrandingData, cardTypes } from '../constants/uiConfig';
import { generateID, generateTransactionAmount } from './other';

function generateSingleTransaction(date) {
  const amount = generateTransactionAmount();
  const transactionTypes = [singleTransactionTypes.transaction, singleTransactionTypes.authorization, singleTransactionTypes.declined, singleTransactionTypes.reverted];
  return {
    amount,
    id: generateID(),
    account_amount: amount,
    account_currency_code: 'EUR',
    currency_code: 'EUR',
    card_id: '1',
    // TODO: in case changing type of transaction modify transaction type generator
    type: transactionTypes[Math.round(Math.random() * 3)],
    date: date.toDate(),
    merchant_name: 'The merchant name',
    merchant_category: Math.round(Math.random() * 32) + 1,
  };
}

export function generateTransactions(lastDay: Date) {
  return [
    generateSingleTransaction(Moment(lastDay).subtract(1, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(1, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(3, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(4, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(7, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(7, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(8, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(8, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(9, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(9, 'd')),
    generateSingleTransaction(Moment(lastDay).subtract(9, 'd')),
  ];
}

export const initialTransaction = () => [
  {
    id: generateID(),
    type: singleTransactionTypes.transaction,
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'USD',
    card_id: '1',
    card_number: '1234 **** **** 3435',
    merchant_category: 1,
    merchant_name: 'The long sample of merchant name is placed here to test overflow ellipsis functionality',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.deposit,
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',

  },
  {
    id: generateID(),
    type: singleTransactionTypes.deposit,
    details: 'Wallester client reward program',
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'USD',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.withdraw,
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',

  },
  {
    id: generateID(),
    type: singleTransactionTypes.withdraw,
    details: 'Load interests',
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'USD',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.authorization,
    date: Moment().toDate(),
    merchant_category: 2 * 3,
    merchant_name: 'The merchant name',
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'USD',
    card_id: '1',
    card_number: '1234********3435',

  },
  {
    id: generateID(),
    type: singleTransactionTypes.declined,
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'USD',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 3 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.reverted,
    date: Moment().toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'USD',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 4 * 3,
    merchant_name: 'The merchant name',
  },
  // Yesterday
  {
    id: generateID(),
    type: singleTransactionTypes.transaction,
    date: Moment().subtract(1, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 5 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.authorization,
    date: Moment().subtract(1, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 6 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.declined,
    date: Moment().subtract(1, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 7 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.reverted,
    date: Moment().subtract(1, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 9 * 3,
    merchant_name: 'The merchant name',
  },
  // 2 days ago
  {
    id: generateID(),
    type: singleTransactionTypes.transaction,
    date: Moment().subtract(2, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 1 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.authorization,
    date: Moment().subtract(2, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 2 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.declined,
    date: Moment().subtract(2, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 3 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.reverted,
    date: Moment().subtract(2, 'd').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 4 * 3,
    merchant_name: 'The merchant name',
  },
  // 1 month ago
  {
    id: generateID(),
    type: singleTransactionTypes.transaction,
    date: Moment().subtract(1, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 5 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.authorization,
    date: Moment().subtract(1, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 6 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.declined,
    date: Moment().subtract(1, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 7 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.reverted,
    date: Moment().subtract(1, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 8 * 3,
    merchant_name: 'The merchant name',
  },
  // 2 month ago
  {
    id: generateID(),
    type: singleTransactionTypes.transaction,
    date: Moment().subtract(2, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 9 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.authorization,
    date: Moment().subtract(2, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234 **** **** 3435',
    merchant_category: 10 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.declined,
    date: Moment().subtract(2, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 2 * 3,
    merchant_name: 'The merchant name',
  },
  {
    id: generateID(),
    type: singleTransactionTypes.reverted,
    date: Moment().subtract(2, 'M').toDate(),
    account_amount: generateTransactionAmount(),
    account_currency_code: 'EUR',
    amount: generateTransactionAmount(),
    currency_code: 'EUR',
    card_id: '1',
    card_number: '1234********3435',
    merchant_category: 3 * 3,
    merchant_name: 'The merchant name',
  },
];

function getCard(id: string): ICard {
  const cards = store.getState().cards.cards;
  return cards.find(card => card.id === id);
}

export function virtualCard() {
  return {
    id: String(Date.now()),
    status: cardStatus.active,
    type: cardTypes.virtual,
    number: '535323******4642',
    expires: {
      month: 12,
      year: 27,
    },
    limits: {},
    security: {
      nfc: true,
      internetPurchase: true,
    },
    preview: cardBrandingData,
  };
}

export function predefinedCards() {
  const emptyLimit = {
    total: null,
    used: null,
  };

  const defaultSecurity = {
    withdrawal: true,
    nfc: false,
    internetPurchase: true,
    magneticStripe: false,
  };

  const emptyPeriod = {
    purchase: emptyLimit,
    withdrawal: emptyLimit,
    internetPurchase: emptyLimit,
  };

  const initialLimits: ICardLimitsByPeriod = {
    transaction: emptyPeriod,
    daily: emptyPeriod,
    monthly: emptyPeriod,
  };
    // @ts-ignore
  const cards =  [
    {
      id: '1',
      status: cardStatus.blocked,
      blockType: blockType.blockedByCardholder,
      type: cardTypes.chipPin,
      number: '123412******3435',
      expires: {
        month: 10,
        year: 2025,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: '2',
      status: cardStatus.active,
      type: cardTypes.sticker,
      number: '443524******5423',
      expires: {
        month: 1,
        year: 2026,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: '3',
      status: cardStatus.active,
      type: cardTypes.virtual,
      number: '535375******4642',
      expires: {
        month: 12,
        year: 2027,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: 'blocked-by-client',
      status: cardStatus.blocked,
      blockType: blockType.blockedByClient,
      type: cardTypes.virtual,
      number: '535377******4642',
      expires: {
        month: 12,
        year: 2027,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: 'blocked-by-issuer',
      status: cardStatus.blocked,
      blockType: blockType.blockedByIssuer,
      type: cardTypes.virtual,
      number: '535398******4642',
      expires: {
        month: 12,
        year: 2027,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: 'blocked-and-stolen',
      status: cardStatus.blocked,
      blockType: blockType.stolen,
      type: cardTypes.virtual,
      number: '535332******4642',
      expires: {
        month: 12,
        year: 2027,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: 'sample-ordered',
      status: cardStatus.ordered,
      type: cardTypes.chipPin,
      number: '123412******3435',
      expires: {
        month: 10,
        year: 2025,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: 'sample-personalized',
      status: cardStatus.personalized,
      type: cardTypes.sticker,
      number: '123478******3435',
      expires: {
        month: 10,
        year: 2025,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
    {
      id: 'sample-dispatched',
      status: cardStatus.dispatched,
      type: cardTypes.sticker,
      number: '443589******5423',
      expires: {
        month: 11,
        year: 2026,
      },
      limits: initialLimits,
      security: defaultSecurity,
    },
  ];
  return cards;
}

export function unmaskCardNumber(cardId: string): string {
  const card = getCard(cardId);
  return `${card.number.replace(' ', '').slice(0, 6)}341234${card.number.slice(15, 19)}`;
}

export function getCardSettings(cardId: string): ICardSecurity {
  const card = getCard(cardId);
  const cardSecureSettings: ICardSecurity = card.security;
  const defaultSecureSettings: ICardSecurity = {
    internetPurchase: true,
    magneticStripe: true,
    nfc: true,
    withdrawal: true,
    countries: true,
  };
  return {
    ...defaultSecureSettings,
    ...cardSecureSettings,
  };
}

export function getCardLimits(cardId: string): ICardLimitsByPeriod {
  const card = getCard(cardId);
  const cardLimits = card.limits;

  // Generating some reasonable limits
  const random = (top) => Math.round(Math.random() * top);
  const transactionPos = random(500);
  const transactionWithdrawal = random(500);
  const transactionInternetPurchase = random(500);
  const dailyPos = random(1000) + transactionPos;
  const dailyWithdrawal = random(1000) + transactionWithdrawal;
  const dailyInternetPurchase = random(1000) + transactionInternetPurchase;
  const monthlyPurchase = random(5000) + dailyPos;
  const monthlyWithdrawal = random(5000) + dailyWithdrawal;
  const monthlyInternetPurchase = random(5000) + dailyInternetPurchase;

  const defaultLimits: ICardLimitsByPeriod = {
    transaction: {
      purchase: {
        total: transactionPos,
        used: random(transactionPos),
      },
      withdrawal: {
        total: transactionWithdrawal,
        used: random(transactionWithdrawal),
      },
      internetPurchase: {
        total: transactionInternetPurchase,
        used: random(transactionInternetPurchase),
      },
    },
    daily: {
      purchase: {
        total: dailyPos,
        used: random(dailyPos) + transactionPos,
      },
      withdrawal: {
        total: dailyWithdrawal,
        used: random(dailyWithdrawal) + transactionWithdrawal,
      },
      internetPurchase: {
        total: dailyInternetPurchase,
        used: random(dailyInternetPurchase) + transactionInternetPurchase,
      },
    },
    monthly: {
      purchase: {
        total: monthlyPurchase,
        used: random(monthlyPurchase) + dailyPos,
      },
      withdrawal: {
        total: monthlyWithdrawal,
        used: random(monthlyWithdrawal) + dailyWithdrawal,
      },
      internetPurchase: {
        total: monthlyInternetPurchase,
        used: random(monthlyInternetPurchase) + dailyInternetPurchase,
      },
    },
  };

  return {
    ...defaultLimits,
  };
}

export const predefinedProfile = {
  avatar: '/img/profile-avatar.png',
  id: '12312345434',
  first_name: 'John',
  last_name: 'Doe',
  address: {
    country: 'EST',
    postal_code: '10151',
    city: 'Tallinn',
    address_line_1: 'Office 37',
    address_line_2: 'Parda 10/12',
  },
  email: 'my@mail.com',
  phone: '+372 10 001 021',
  timezone: 'UTC + 02:00',
};
