import * as React from 'react';

import CardMessageMenu from '../CardMessageMenu/CardMessageMenu';

import { texts } from '../../../../../../constants/lang';

class CardBlocked extends React.Component <{}, {}> {
  render() {
    const blockedTexts = texts.cardPage.card.blocked;
    return (
      <CardMessageMenu
        title={blockedTexts.title}
        descriptionHtml={blockedTexts.description}
      />
    );
  }
}

export default CardBlocked;
