import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Accordion from '../../../Basic/Accordion/Accordion';
import Button from '../../../Basic/Button/Button';
import Icon from '../../../Basic/Icon/Icon';
import FixedInput from '../../../Basic/Inputs/FixedInput/FixedInput';
import Modal from '../../../Layout/Modal/Modal';

import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';

import { getAccountTopUpCredentials } from '../../../../actions/account';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { uiConfig } from '../../../../constants/uiConfig';
import { IAccountTopUpCredentials } from '../../../../models/account';

interface ITopUpProps extends IWithRouterModalChild {
  topUpCredentials: IAccountTopUpCredentials;
  getTopUpCredentials?: () => any;
}

class TopUp extends React.Component <ITopUpProps, {}> {

  constructor(props) {
    super(props);
    this.onModalClose = this.onModalClose.bind(this);
  }

  componentDidMount(): void {
    this.props.getTopUpCredentials();
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.account.root);
  }

  renderModal(): React.ReactElement {
    const { withoutUserRoute, topUpCredentials } = this.props;
    const topUpTexts = texts.accountPage.topUp;
    const credentialTexts = topUpTexts.credentials;

    const conditionsTexts = topUpTexts.conditions;
    const additionalInfoTexts = topUpTexts.additionalInfo;
    const conditionsIcons = uiConfig.account.topUp.conditions;
    const additionalInfoIcons = uiConfig.account.topUp.additionalInfo;

    const additionalInfoRender = additionalInfoTexts.map((text, index) => {
      const icon = additionalInfoIcons[index];
      return (
        <div key={`conditions-item-${index}`} className="list-item">
          <Icon name={icon} />
          <p className="list-item-text"> {text} </p>
        </div>
      );
    });

    const conditionsRender = conditionsTexts.map((conditionText, index) => {
      const conditionIcon = conditionsIcons[index];
      return (
        <div key={`conditions-item-${index}`} className="list-item">
          <Icon name={conditionIcon} />
          <p className="list-item-text"> {conditionText} </p>
        </div>
      );
    });

    return (
      <Modal
        className="top-up-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalTitle={topUpTexts.title}
      >
        <div className="top-up-modal-wrapper">
          <p className="modal-description top-up-modal-description"> {topUpTexts.description} </p>
          <div className="credentials-wrapper">
            <FixedInput
              value={topUpCredentials.iban}
              label={credentialTexts.iban.label}
              copiedMessage={credentialTexts.iban.message}
            />
            <FixedInput
              value={topUpCredentials.receiverName}
              label={credentialTexts.receiverName.label}
              copiedMessage={credentialTexts.receiverName.message}
            />
            <FixedInput
              value={topUpCredentials.swift}
              label={credentialTexts.swift.label}
              copiedMessage={credentialTexts.swift.message}
            />
            <FixedInput
              value={topUpCredentials.details}
              label={credentialTexts.details.label}
              copiedMessage={credentialTexts.details.message}
            />
          </div>
          {/* TODO: #1ufmnx - add top-up credentials send email functionality */}
          {/*<Button*/}
          {/*  className="send-button"*/}
          {/*  width="large"*/}
          {/*  label={topUpTexts.action}*/}
          {/*  onClick={ () => null}*/}
          {/*/>*/}
        </div>
        <Accordion title="Additional Info" icon="plus-circle">
          <div className="list">
            {additionalInfoRender}
          </div>
        </Accordion>
        <Accordion title="Payment conditions and limits" icon="information">
          <div className="list">
            {conditionsRender}
          </div>
        </Accordion>
      </Modal>
    );
  }

  render() {
    this.props.onModalOpen(this.renderModal());
    // on desktop we will use this render to output content
    // on mobile we use action to push modal with content
    return <React.Fragment />;
  }
}

function inject({ account }) {
  return {
    topUpCredentials: account.topUpCredentials,
  };
}

function actions(dispatch) {
  return {
    getTopUpCredentials: () => dispatch(getAccountTopUpCredentials()),
  };
}

export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(TopUp);
