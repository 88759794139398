
require.register("react-redux/lib/utils/reactBatchedUpdates.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {"transform":["loose-envify"]}, "react-redux");
  (function() {
    "use strict";

exports.__esModule = true;
exports.unstable_batchedUpdates = void 0;

var _reactDom = require("react-dom");

exports.unstable_batchedUpdates = _reactDom.unstable_batchedUpdates;
  })();
});