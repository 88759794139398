import * as React from 'react';

// tslint:disable-next-line:function-name
function CircleSpinner() {
  return (
    <div className="circle-spinner-wrapper">
        <svg
            className="circle-spinner"
            width="65"
            height="65"
            viewBox="0 0 80 80"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="circle-spinner-path"
                fill="none"
                strokeWidth="12"
                strokeLinecap="round"
                cx="40"
                cy="40"
                r="30"
            />
        </svg>
    </div>
  );
}

export default (CircleSpinner);
