import * as React from 'react';

import { texts } from '../../../../../constants/lang';
import { ICardLimit, ICardLimitType } from '../../../../../models/cards';
import Icon from '../../../../Basic/Icon/Icon';
import MenuItem from '../../../../Basic/MenuItem/MenuItem';
import Progress from '../../../../Basic/Progress/Progress';

interface ISingleLimitWidgetProps {
  type: string;
  hideProgress?: boolean;
  currentLimits: ICardLimitType;
  onClick: () => any;
}

class SingleLimitWidget extends React.Component <ISingleLimitWidgetProps, {}> {
  renderProgress(limit: ICardLimit) {
    const progressTexts = texts.cardPage.card.cardLimits.overview.progress;
    const { used, total } = limit;
    const fixedUsed = used > total ? total : used;
    const hasLimit = total > 0;
    const description = hasLimit ? `${fixedUsed}/${total}` : null;
    const title = hasLimit ? progressTexts.usage : progressTexts.notConfigured;

    return (
      <Progress
        title={title}
        description={description}
        value={fixedUsed}
        max={limit.total || 1}
      />
    );
  }

  renderValue(limit: ICardLimit) {
    const progressTexts = texts.cardPage.card.cardLimits.overview.progress;

    return (
      <div className="progress-wrapper no-progress">
        {progressTexts.noUsage} <span className="value"> {limit.total} </span>
      </div>
    );
  }

  render() {
    const { type, onClick, hideProgress, currentLimits } = this.props;
    const translation = texts.cardPage.card.cardLimits.overview.options[type];
    const limit = currentLimits[type];
    const bottomWidget = hideProgress ? this.renderValue(limit) : this.renderProgress(limit);

    const icon = {
      purchase: 'credit-card',
      withdrawal: 'cash',
      internetPurchase: 'web',
    }[type];

    return (
      <MenuItem
        icon={icon}
        title={translation.title}
        subtitle={translation.description}
        bottomWidget={bottomWidget}
        onClick={onClick}
      >
        <Icon name="pencil"/>
      </MenuItem>
    );
  }
}

export default SingleLimitWidget;
