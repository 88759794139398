import * as React from 'react';

import { connect } from 'react-redux';

interface IRequestLoaderProps {
  requestSpinner: number;
}

class RequestLoader extends React.Component <IRequestLoaderProps, {}> {
  render() {
    const isVisible = this.props.requestSpinner > 0;
    return isVisible ? (
      <div className="request-spinner">
        <div className="request-spinner-body" />
      </div>
    ) : null;
  }
}

function inject({ ui }) {
  return {
    requestSpinner: ui.requestSpinner,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(RequestLoader);
