require.register("cheerio/package.json", function(exports, require, module) {
  module.exports = {
  "_args": [
    [
      "cheerio@1.0.0-rc.3",
      "/Users/bogdan/Documents/c1-test"
    ]
  ],
  "_from": "cheerio@1.0.0-rc.3",
  "_id": "cheerio@1.0.0-rc.3",
  "_inBundle": false,
  "_integrity": "sha512-0td5ijfUPuubwLUu0OBoe98gZj8C/AA+RW3v67GPlGOrvxWjZmBXiBCRU+I8VEiNyJzjth40POfHiz2RB3gImA==",
  "_location": "/cheerio",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "cheerio@1.0.0-rc.3",
    "name": "cheerio",
    "escapedName": "cheerio",
    "rawSpec": "1.0.0-rc.3",
    "saveSpec": null,
    "fetchSpec": "1.0.0-rc.3"
  },
  "_requiredBy": [
    "/enzyme"
  ],
  "_resolved": "https://registry.npmjs.org/cheerio/-/cheerio-1.0.0-rc.3.tgz",
  "_spec": "1.0.0-rc.3",
  "_where": "/Users/bogdan/Documents/c1-test",
  "author": {
    "name": "Matt Mueller",
    "email": "mattmuelle@gmail.com",
    "url": "mat.io"
  },
  "bugs": {
    "url": "https://github.com/cheeriojs/cheerio/issues"
  },
  "dependencies": {
    "css-select": "~1.2.0",
    "dom-serializer": "~0.1.1",
    "entities": "~1.1.1",
    "htmlparser2": "^3.9.1",
    "lodash": "^4.15.0",
    "parse5": "^3.0.1"
  },
  "description": "Tiny, fast, and elegant implementation of core jQuery designed specifically for the server",
  "devDependencies": {
    "benchmark": "^2.1.0",
    "coveralls": "^2.11.9",
    "expect.js": "~0.3.1",
    "istanbul": "^0.4.3",
    "jquery": "^3.0.0",
    "jsdom": "^9.2.1",
    "jshint": "^2.9.2",
    "mocha": "^3.1.2",
    "xyz": "~1.1.0"
  },
  "engines": {
    "node": ">= 0.6"
  },
  "files": [
    "index.js",
    "lib"
  ],
  "homepage": "https://github.com/cheeriojs/cheerio#readme",
  "keywords": [
    "htmlparser",
    "jquery",
    "selector",
    "scraper",
    "parser",
    "html"
  ],
  "license": "MIT",
  "main": "./index.js",
  "name": "cheerio",
  "repository": {
    "type": "git",
    "url": "git://github.com/cheeriojs/cheerio.git"
  },
  "scripts": {
    "test": "make test"
  },
  "version": "1.0.0-rc.3"
}
;
});