import * as React from 'react';
import { connect, Provider } from 'react-redux';

import { checkAuth } from '../../actions/auth';
import store from '../../reducers/store';

import AuthorizedRoot from './AuthorizedRoot';
import UnauthorizedRoot from './UnauthorizedRoot';

interface IProps {
  loggedIn: boolean;

  checkAuth: () => any;
}

class Switch extends React.Component<IProps, {}> {

  componentDidMount(): void {
    this.configWindowSize();
    window.addEventListener('resize', this.configWindowSize);
    window.addEventListener('orientationchange', this.configWindowSize);
    if (this.props.loggedIn == null) {
      this.props.checkAuth();
    }
  }

  configWindowSize() {
    const windowSize = window.innerHeight;
    document.documentElement.style.setProperty('--screen-size', `${windowSize}px`);
  }

  render() {
    let component = null;
    if (this.props.loggedIn === true) {
      component = <AuthorizedRoot />;
    } else if (this.props.loggedIn === false) {
      component = <UnauthorizedRoot />;
    } else {
      component = <span />;
    }

    return (
      <Provider store={store}>
        {component}
      </Provider>
    );
  }
}

function inject({ auth }) {
  return {
    loggedIn: auth.loggedIn,
  };
}

function actions(dispatch) {
  return {
    checkAuth: () => dispatch(checkAuth()),
  };
}

export default connect(inject, actions)(Switch);
