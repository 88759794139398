
require.register("react-redux/lib/components/Context.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {"transform":["loose-envify"]}, "react-redux");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = exports.ReactReduxContext = void 0;

var _react = _interopRequireDefault(require("react"));

var ReactReduxContext = _react["default"].createContext(null);

exports.ReactReduxContext = ReactReduxContext;
var _default = ReactReduxContext;
exports["default"] = _default;
  })();
});