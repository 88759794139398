export const routingPaths = {
  home: '/',
  account: {
    root: '/account',
    topUp: '/top-up',
    search: '/search',
  },
  card: {
    root: '/cards',
    pin: 'pin',
    cvv2: 'cvv2',
    limits: 'limits',
    report: 'report',
    security: 'security',
    wallet: 'wallet',
  },
  settings: {
    root: '/more',
    deliveryAddress: 'delivery-address',
    password: 'password',
    faq: 'faq',
    legal: {
      terms: 'https://wallester.eu/assets/wallester-terms-and-conditions.pdf',
      privacy: 'https://wallester.eu/assets/wallester-privacy-policy.pdf',
    },
  },
  login: {
    forgotPassword: '/forgot-password',
    recoverPassword: '/recover-password',
    // TODO: havr - please replace with this path here
    // recoverPassword: '/reset-password',
  },
  other: {
    supportMail: 'customersupport@wallester.eu',
  },
};
