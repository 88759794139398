import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Button from '../../../Basic/Button/Button';
import Input from '../../../Basic/Inputs/Input/Input';
import withRouterModal, { IWithRouterModalChild } from '../../../Decorators/withRouterModal';
import Modal from '../../../Layout/Modal/Modal';

import { updateAddress } from '../../../../actions/profile';
import { texts } from '../../../../constants/lang';
import { routingPaths } from '../../../../constants/routing';
import { IFormInputWithValidation } from '../../../../models/forms';
import { IProfileAddress } from '../../../../models/profile';
import CountrySelect from './CountrySelect/CountrySelect';

interface IDeliveryAddressProps extends IWithRouterModalChild, IProfileAddress {
  updateAddress: (address: IProfileAddress) => any;
}

interface IDeliveryAddressState {
  country: IFormInputWithValidation;
  // There is no error messages for postal code and region yet. But they will appear later
  // Also it will help to save consistency thru all from fieldList and make UI logic easier
  region: IFormInputWithValidation;
  postalCode: IFormInputWithValidation;
  city: IFormInputWithValidation;
  addressLine1: IFormInputWithValidation;
  addressLine2: IFormInputWithValidation;
}

type formFieldName = 'country' | 'region' | 'postalCode' | 'city' | 'addressLine1' | 'addressLine2';

class DeliveryAddress extends React.Component <IDeliveryAddressProps, IDeliveryAddressState> {
  fieldList: formFieldName[] = ['country' , 'region' , 'postalCode' , 'city' , 'addressLine1' , 'addressLine2'];

  onCountryInputChange = this.onFormFieldChange('country');
  onRegionInputChange = this.onFormFieldChange('region');
  onPostalCodeInputChange = this.onFormFieldChange('postalCode');
  onCityInputChange = this.onFormFieldChange('city');
  onAddressLine1InputChange = this.onFormFieldChange('addressLine1');
  onAddressLine2InputChange = this.onFormFieldChange('addressLine2');

  constructor(props) {
    super(props);

    // @ts-ignore
    this.state = {};

    this.fieldList.forEach((key: formFieldName) => {
      // @ts-ignore
      this.state[key] = {
        value: props[key],
        errorPrompt: '',
      };
    });

    this.onCancelForm = this.onCancelForm.bind(this);
    this.onUpdateForm = this.onUpdateForm.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<IDeliveryAddressProps>, nextContext: any): void {
    const updatePropName = this.fieldList.find(field => {
      if (nextProps[field] !== this.props[field]) {
        return true;
      }
    });

    if (updatePropName) {
      const newState = {};
      this.fieldList.forEach((key: formFieldName) => {
        const stateField = this.state[key];
        newState[key] = {
          ...stateField,
          value: stateField.value !== this.props[name] ? stateField.value : nextProps[name],
        };
      });
    }

  }

  onFormFieldChange(key: formFieldName): (value: string) => any {
    // @ts-ignore
    return (value: string) => this.setState({
      [key]: {
        value,
        errorPrompt: '',
      },
    });
  }

  validateForm() {
    let valid = true;
    // @ts-ignore;
    const newState: IDeliveryAddressState = {};
    const errorMessages = texts.settingsPage.deliveryAddress.errorMessages;
    const requiredFields: formFieldName[] = ['country', 'city', 'postalCode', 'addressLine1'];

    requiredFields.forEach((key: formFieldName) => {
      if (this.state[key].value.length === 0) {
        newState[key] = {
          ...this.state[key],
          errorPrompt: errorMessages.requiredPrompt,
        };
        valid = false;
      }
    });

    if (!valid) {
      this.setState(newState);
    }

    return valid;
  }

  onCancelForm() {
    this.onModalClose();
  }

  onUpdateForm() {
    if (this.validateForm()) {
      const state = this.state;
      this.props.updateAddress({
        country: state.country.value,
        city: state.city.value,
        postalCode: state.postalCode.value,
        addressLine1: state.addressLine1.value,
        addressLine2: state.addressLine2.value,
      });
      this.onModalClose();
    }
  }

  onModalClose() {
    this.props.onModalClose(routingPaths.settings.root);
  }

  renderModal(): React.ReactElement {
    const { withoutUserRoute } = this.props;
    const deliveryTexts = texts.settingsPage.deliveryAddress;
    const { country, region, postalCode, city, addressLine1, addressLine2 } = this.state;

    return (
      <Modal
        className="delivery-address-modal"
        hideAppearTransition={withoutUserRoute}
        onBack={this.onModalClose}
        modalTitle={deliveryTexts.title}
      >
        <div
            className="delivery-address-modal-description"
            dangerouslySetInnerHTML={{ __html: deliveryTexts.description }}
        />
        <div className="form">
          <CountrySelect
            label={deliveryTexts.country}
            value={country.value}
            errorPrompt={country.errorPrompt}
            onChange={this.onCountryInputChange}
            errorText={country.errorPrompt}
          />
          {/*TODO(1rjtep): provide state/province data*/}
          {/*<Input*/}
          {/*  label={deliveryTexts.region}*/}
          {/*  value={region.value}*/}
          {/*  onChange={this.onRegionInputChange}*/}
          {/*  errorText={region.errorPrompt}*/}
          {/*/>*/}
          <Input
            required
            label={deliveryTexts.postal}
            value={postalCode.value}
            onChange={this.onPostalCodeInputChange}
            onEnter={this.onUpdateForm}
            errorText={postalCode.errorPrompt}
          />
          <Input
            required
            label={deliveryTexts.city}
            value={city.value}
            onChange={this.onCityInputChange}
            onEnter={this.onUpdateForm}
            errorText={city.errorPrompt}
          />
          <Input
            required
            label={deliveryTexts.addressLine1}
            value={addressLine1.value}
            onChange={this.onAddressLine1InputChange}
            onEnter={this.onUpdateForm}
            errorText={addressLine1.errorPrompt}
          />
          <Input
            label={deliveryTexts.addressLine2}
            value={addressLine2.value}
            onChange={this.onAddressLine2InputChange}
            onEnter={this.onUpdateForm}
            errorText={addressLine2.errorPrompt}
          />

          <div className="form-controllers">
            <Button
              bordered
              label={deliveryTexts.actions.cancel}
              onClick={this.onCancelForm}
            />
            <Button
              label={deliveryTexts.actions.update}
              onClick={this.onUpdateForm}
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    this.props.onModalOpen(this.renderModal());
    return (
      <React.Fragment />
    );
  }
}

function inject({ profile }) {
  return {
    ...profile.profile.address,
  };
}

function actions(dispatch) {
  return {
    updateAddress: (address: IProfileAddress) => dispatch(updateAddress(address)),
  };
}

// @ts-ignore
export default compose(
  withRouterModal,
  connect(inject, actions),
// @ts-ignore
)(DeliveryAddress);
