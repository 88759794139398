import * as React from 'react';

import { connect } from 'react-redux';

import Flag from '../../../../Basic/Flag/Flag';
import Icon from '../../../../Basic/Icon/Icon';
import CountrySelectModal from './CountrySelectModal/CountrySelectModal';

import { openModal } from '../../../../../actions/ui/modals';
import { displayCountryName } from '../../../../../utils/ui';

interface ICountrySelectProps {
  label?: string;
  value?: string;
  errorPrompt?: string;

  onChange?: (newValue: string) => any;
  openModal?: (content: React.ReactElement) => any;
}

class CountrySelect extends React.Component <ICountrySelectProps, {}> {
  constructor(props) {
    super(props);
    this.onSelectModalOpen = this.onSelectModalOpen.bind(this);
  }

  onSelectModalOpen() {
    const { value, onChange } = this.props;
    const countrySelectModal = (
      <CountrySelectModal
        value={value}
        onChange={onChange}
      />
    );
    this.props.openModal(countrySelectModal);
  }

  render() {
    const { label, value, errorPrompt } = this.props;
    const displayName = displayCountryName(value);
    return (
        <div className="select-input">
          <div className="input-label">
            {label}
            <span className="asterisk"> *</span>
          </div>
          {/* TODO: add empty state */}
          <div
            className="select-value country-selected"
            onClick={this.onSelectModalOpen}
          >
            <Flag
              className="country-flag"
              type="square"
              country={value}
            />
            <div className="country-name"> {displayName} </div>
            <Icon className="caret" name="chevron-right"/>
          </div>
          <p className="select-hint-error"> { errorPrompt } </p>
        </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions(dispatch) {
  return {
    openModal: (content: React.ReactElement) => dispatch(openModal('notification-modal', content)),
  };
}

export default connect(inject, actions)(CountrySelect);
