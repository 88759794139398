import * as React from 'react';

import classnames from 'classnames';
import { connect } from 'react-redux';

import Button from '../Button/Button';

import { urlImage } from '../../../utils/other';
import { isAgentMobile } from '../../../utils/ui';

interface IEmptySearchProps {
  className?: string;
  title?: any;
  description?: any;
  actionTitle?: string;
  action?: () => any;

  emptySearchImage?: string;
}

class EmptySearch extends React.Component <IEmptySearchProps, {}> {
  render() {

    const { className, title, description, action, actionTitle, emptySearchImage } = this.props;

    const wrapperClass = classnames({
      'empty-screen': true,
      mobile: isAgentMobile,
      desktop: !isAgentMobile,
    });

    return (
      <div className={wrapperClass}>
        <div
          className="image"
          style={{
            backgroundImage: urlImage(emptySearchImage),
          }}
        />
        <div className="content">
          {title && (
            <h2 className="title"> {title} </h2>
          )}
          {description && (
            <p className="description"> {description} </p>
          )}
          {action && (
            <Button
              className="action"
              width="medium"
              label={actionTitle}
              onClick={action}
            />
          )}
        </div>
      </div>
    );
  }
}

function inject({ ui }) {
  return {
    emptySearchImage: ui.emptyScreens.emptySearchImage,
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(EmptySearch);
