import * as React from 'react';

import Button from '../../../../Basic/Button/Button';
import KeyIcon from '../../../../Basic/SVG/KeyIcon';

import { texts } from '../../../../../constants/lang';

interface ICompletenessFormProps {
  onChange: () => any;
}

class CompletenessForm extends React.Component <ICompletenessFormProps, {}> {
  render() {
    const { onChange } = this.props;
    const completenessTexts = texts.settingsPage.passwordPage.completenessTexts;

    return (
      <div className="completeness-form">
        <KeyIcon />
        <h5 className="title"> {completenessTexts.title} </h5>
        <p className="description"> {completenessTexts.description} </p>
        <Button
          rounded
          label={completenessTexts.action}
          onClick={onChange}
        />
      </div>
    );
  }
}

export default CompletenessForm;
