import * as React from 'react';

import { HashRouter } from 'react-router-dom';

import LoginPage from '../Pages/LoginPage/LoginPage';

export default class UnauthorizedRoot extends React.Component<{}> {
  render() {
    return (
      <HashRouter>
        <LoginPage />
      </HashRouter>
    );
  }
}
