// TODO: add account chart
import * as React from 'react';
import { connect } from 'react-redux';

class AccountChart extends React.Component <{}, {}> {
  render() {
    return null;
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(AccountChart);
