import { snackbarMessageTypes } from '../../constants/uiConfig';

export const UI_SNACKBAR_SHOW = 'UI/SNACKBAR/SHOW';
export const UI_SNACKBAR_HIDE = 'UI/SNACKBAR/HIDE';

interface ISnackbarMessageShow {
  type: typeof UI_SNACKBAR_SHOW;
  payload: {
    type: number,
    content: string,
    timestamp: number,
  };
}

// TODO: create snackbar component to handle snackbar messages
export function showSnackbarMessage(type: number, content: string) {
  return (dispatch) => {
    dispatch({
      type: UI_SNACKBAR_SHOW,
      payload: {
        type,
        content,
        timestamp: new Date().getTime(),
      },
    });
  };
}

interface ISnackbarMessageHide {
  type: typeof UI_SNACKBAR_HIDE;
  payload: number;
}

export function hideSnackbarMessage(timestamp: number) {
  return (dispatch) => {
    dispatch({
      type: UI_SNACKBAR_HIDE,
      payload: timestamp,
    });
  };
}

export function showSnackbarAPIErrorMessage(content: string) {
  return showSnackbarMessage(snackbarMessageTypes.serverError, content);
}

export type SnackbarActions = ISnackbarMessageShow | ISnackbarMessageHide;
