const apiBase = '/api';

export const api = {
  authLogin: () => `${apiBase}/auth`,
  authLogout: () => `${apiBase}/auth`,
  authMe: () => `${apiBase}/me`,
  updateAddress: () => `${apiBase}/me/address`,

  getAccount: () => `${apiBase}/account`,
  listAccountTransactions: () => `${apiBase}/account/transactions`,
  getAccountTopUpCredentials: () => `${apiBase}/account/top-up`,

  listCards: () => `${apiBase}/cards`,
  remindCardPin: (cardId) => `${apiBase}/cards/${cardId}/pin/remind`,
  remindCardCvv: (cardId) => `${apiBase}/cards/${cardId}/cvv/remind`,
  createVirtualCard: () => `${apiBase}/cards`,
  blockCard: (cardId) => `${apiBase}/cards/${cardId}/block`,
  activateCard: (cardId) => `${apiBase}/cards/${cardId}/activate`,
  unblockCard: (cardId) => `${apiBase}/cards/${cardId}/unblock`,
  updateCardLimit: (cardId) => `${apiBase}/cards/${cardId}/limits`,
  listCardLimits: (cardId) => `${apiBase}/cards/${cardId}/limits`,
  updateCardSecurity: (cardId) => `${apiBase}/cards/${cardId}/security`,
  getCardNumber: (cardId) => `${apiBase}/cards/${cardId}/number`,
  changePassword: () => `${apiBase}/me/password`,
  sendRecoveryEmail: () => `${apiBase}/auth/password/request-recovery-link`,
  recoverPasswordByToken: (token) => `${apiBase}/auth/password/recover/${token}`,
  checkPasswordRecoveryToken: (token) => `${apiBase}/auth/password/recover/${token}`,
};
