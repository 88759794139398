import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import BottomNavigation from './BottomNavigation/BottomNavigation';
import ModalHandler from './ModalHandler/ModalHandler';
import Preloader from './Preloader/Preloader';
import RequestLoader from './RequestLoader/RequestLoader';
import SnackbarHandler from './SnackbarHandler/SnackbarHandler';

interface ILayoutProps extends RouteComponentProps<any> {
  children?: React.ReactElement;
}

class LayoutRoot extends React.Component <ILayoutProps, {}> {
  render() {
    const { location } = this.props;
    return (
          <div className="layout-wrapper">
              <div className="app-body">
                  {this.props.children}
              </div>
              <BottomNavigation location={location.pathname} />
              <ModalHandler />
              <SnackbarHandler />
              <RequestLoader />
              <Preloader />
          </div>
    );
  }
}

function inject({}) {
  return {

  };
}

function actions() {
  return {

  };
}

export default compose(
    withRouter,
    connect(inject, actions),
// @ts-ignore
)(LayoutRoot);
