import * as React from 'react';

import classnames from 'classnames';

import { IFaqSection } from '../../../../../models/faq';
import FaqQuestion from '../FaqQuestion/FaqQuestion';

interface IFaqSectionProps {
  sectionContent: IFaqSection;
  searchRequest: string;
}

class FaqSection extends React.Component <IFaqSectionProps, {}> {
  render() {
    const { sectionContent, searchRequest } = this.props;
    const searchRequestLowercase = searchRequest.toLowerCase();
    const searchKey = searchRequest && searchRequest.length > 0 ? '-in-search' : '';
    const questionsRender = sectionContent.content
      .filter((content) => (
        searchRequest.length === 0 ||
        content.question.toLowerCase().indexOf(searchRequestLowercase) !== -1 ||
        content.answer.toLowerCase().indexOf(searchRequestLowercase) !== -1),
      ).map((question) => (
      <FaqQuestion key={question.id + searchKey} content={question} searchRequest={searchRequest} />
    ));

    const wrapperClass = classnames({
      'faq-section-wrapper': true,
      'faq-section-search': !!searchRequest,
    });

    if (questionsRender.length === 0) {
      return null;
    }

    return (
      <div className={wrapperClass}>
        { !searchRequest && (
          <h5 className="faq-section-title"> {sectionContent.title} </h5>
        )}
        <div className="faq-section-questions">
          {questionsRender}
        </div>
      </div>
    );
  }
}

export default FaqSection;
