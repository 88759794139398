import * as React from 'react';

import { List } from 'immutable';
import { connect } from 'react-redux';

import BottomNavigationItem from './BottomNavigationItem/BottomNavigationItem';

import { ICard } from '../../../models/cards';

import { texts } from '../../../constants/lang';
import { routingPaths } from '../../../constants/routing';
import { uiConfig } from '../../../constants/uiConfig';
import { modifyCardList } from '../../../utils/cards';
import { checkAccountPath, checkCardPath, checkSettingsPath, getCardPath } from '../../../utils/routing';

interface IBottomNavigationProps {
  location: any;
  cards: List<ICard>;
}

// tslint:disable-next-line:function-name
function BottomNavigation({ location, cards }: IBottomNavigationProps) {
  const componentUIConfig = uiConfig.navigation;
  const componentTexts = texts.navigation;

  // @ts-ignore
  const cardURL = cards && (cards.size > 0) ? getCardPath(cards.first().id) : routingPaths.cards;

  return (
        <div className="bottom-navigation-wrapper">
            <BottomNavigationItem
                icon={componentUIConfig.account.defaultIcon}
                iconActive={componentUIConfig.account.activeIcon}
                name={componentTexts.account.title}
                to={routingPaths.account.root}
                active={checkAccountPath(location)}
            />
            <BottomNavigationItem
                icon={componentUIConfig.cards.defaultIcon}
                iconActive={componentUIConfig.cards.activeIcon}
                name={componentTexts.cards.title}
                to={cardURL}
                active={checkCardPath(location)}
            />
            <BottomNavigationItem
              icon={componentUIConfig.other.defaultIcon}
              iconActive={componentUIConfig.other.activeIcon}
              name={componentTexts.more.title}
              to={routingPaths.settings.root}
              active={checkSettingsPath(location)}
            />
        </div>
  );
}

function inject({ cards }) {
  return {
    cards: modifyCardList(cards.cards),
  };
}

function actions() {
  return {

  };
}

export default connect(inject, actions)(BottomNavigation);
