
require.register("tiny-warning/dist/tiny-warning.cjs.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "tiny-warning");
  (function() {
    'use strict';

var isProduction = 'development' === 'production';
function warning(condition, message) {
  if (!isProduction) {
    if (condition) {
      return;
    }

    var text = "Warning: " + message;

    if (typeof console !== 'undefined') {
      console.warn(text);
    }

    try {
      throw Error(text);
    } catch (x) {}
  }
}

module.exports = warning;
  })();
});