import * as React from 'react';

import classnames from 'classnames';

interface IFlagProps {
  className: string;
  country: string;
  type: 'rounded' | 'rectangle' | 'square' | 'pin' | 'hexagon';
}

class Flag extends React.Component <IFlagProps, {}> {
  render() {
    const { className, country, type } = this.props;
    const wrapperClass = classnames({
      flag: true,
    }, className, type);

    const svgLinks = {
      rounded: '/flags/round-flags.svg',
      square: '/flags/square-flags.svg',
      rectangle: '/flags/rectangle-flags.svg',
      pin: '/flags/pin-flags.svg',
      hexagon: '/flags/hexagon-flags.svg',
    };

    const fileURL = svgLinks[type] || svgLinks.rounded;
    const xlink = `${fileURL}#${country}`;

    return (
      <div className={wrapperClass}>
        <svg role="img">
          <use xlinkHref={xlink}/>
        </svg>
      </div>
    );
  }
}

export default Flag;
