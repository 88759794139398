import * as React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { routingPaths } from '../../constants/routing';

// @ts-ignore
import CardsPage from 'components/Pages/CardsPage/CardsPage';
// @ts-ignore
import OverviewPage from 'components/Pages/OverviewPage/OverviewPage';
import Layout from '../Layout/Layout';
import Card from '../Pages/CardsPage/Card/Card';
import RecoverPassword from '../Pages/LoginPage/RecoverPassword/RecoverPassword';
import SettingsPage from '../Pages/SettingsPage/SettingsPage';

// tslint:disable:ter-arrow-parens
class AuthorizedRoot extends React.Component<{}> {
  render() {
    return (
      <HashRouter>
        <Layout>
          <Switch>
            <Route
              exact
              path={routingPaths.home}
              component={ OverviewPage }
            />
            <Route
              path={`${routingPaths.card.root}/:id`}
              component={ CardsPage }
            />
            <Route
              path={routingPaths.settings.root}
              component={ SettingsPage }
            />
            <Route
              path={`${routingPaths.login.recoverPassword}/:token`}
              component={ RecoverPassword }
            />
            {/* Default root - for unexpected cases */}
            <Route component={ OverviewPage } />
          </Switch>
        </Layout>
      </HashRouter>
    );
  }
}
// tslint:enable:ter-arrow-parens

export default AuthorizedRoot;
