import * as React from 'react';

// tslint:disable-next-line:function-name
function KeyIcon() {
  return (
    <svg width="90px" height="90px" viewBox="0 0 90 90">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#E6E6E6" fillRule="nonzero">
          <path d="M39.4629545,57.8475 L31.8804545,65.4361364 L26.5909091,65.4361364 C25.4597727,65.4361364 24.5454545,66.3525 24.5454545,67.4836364 L24.5454545,71.5765909 L20.4545455,71.5765909 C19.3234091,71.5765909 18.4090909,72.4929545 18.4090909,73.6240909 L18.4090909,77.7170455 L14.3181818,77.7170455 C13.1890909,77.7170455 12.2727273,78.6334091 12.2727273,79.7645455 L12.2727273,85.0581818 L11.4259091,85.905 L4.09090909,85.905 L4.09090909,78.5659091 L38.2622727,44.3659091 C39.0620455,43.5681818 39.0620455,42.2713636 38.2643182,41.4715909 L37.665,40.8702273 C37.1188636,40.3261364 36.8202273,39.6020455 36.8202273,38.8309091 L36.8202273,38.8227273 C36.8202273,37.6915909 35.9059091,36.7793182 34.7747727,36.7793182 C33.6436364,36.7793182 32.7293182,37.6997727 32.7293182,38.8309091 C32.7293182,40.2893182 33.1731818,41.6802273 33.9975,42.8461364 L0.599318182,76.2709091 C0.214772727,76.6554545 0,77.175 0,77.7190909 L0,87.9545455 C0,89.0836364 0.916363636,90 2.04545455,90 L12.2727273,90 C12.8147727,90 13.3363636,89.7831818 13.7188636,89.4006818 L15.7643182,87.3531818 C16.1488636,86.9686364 16.3636364,86.4490909 16.3636364,85.9070455 L16.3636364,81.8140909 L20.4545455,81.8140909 C21.5856818,81.8140909 22.5,80.8977273 22.5,79.7665909 L22.5,75.6736364 L26.5909091,75.6736364 C27.7220455,75.6736364 28.6363636,74.7572727 28.6363636,73.6261364 L28.6363636,69.5331818 L32.7272727,69.5331818 C33.2713636,69.5331818 33.7909091,69.3163636 34.1734091,68.9338636 L42.3552273,60.7459091 C43.155,59.9461364 43.155,58.6513636 42.3552273,57.8515909 C41.5554545,57.0477273 40.2627273,57.0477273 39.4629545,57.8475 Z"/>
          <path d="M69.5454545,34.9813636 C71.2513636,34.9813636 72.855,34.3165909 74.0597727,33.1097727 C76.5490909,30.6184091 76.5490909,26.5643182 74.0597727,24.075 L65.8779545,15.8870455 C63.4684091,13.4713636 59.2588636,13.4713636 56.8493182,15.8870455 C54.36,18.3784091 54.36,22.4325 56.8493182,24.9218182 L65.0311364,33.1097727 C66.2359091,34.3165909 67.8395455,34.9813636 69.5454545,34.9813636 Z M59.7415909,18.7793182 C60.1752273,18.3456818 60.75,18.1063636 61.3636364,18.1063636 C61.9772727,18.1063636 62.5520455,18.3456818 62.9856818,18.7793182 L71.1675,26.9672727 C72.0613636,27.8611364 72.0613636,29.3195455 71.1675,30.2134091 C70.3002273,31.0806818 68.7906818,31.0806818 67.9234091,30.2134091 L59.7415909,22.0254545 C58.8497727,21.1315909 58.8497727,19.6752273 59.7415909,18.7793182 Z"/>
          <path d="M87.9545455,25.6970455 L64.2559091,1.98 C61.6152273,-0.658636364 57.0211364,-0.660681818 54.3804545,1.98 L34.7727273,21.6020455 C33.4534091,22.9213636 32.7272727,24.6763636 32.7272727,26.5438636 C32.7272727,28.4113636 33.4534091,30.1663636 34.7727273,31.4856818 L54.3804545,51.1077273 L53.1777273,52.3125 C52.6336364,52.8565909 51.9095455,53.1572727 51.1404545,53.1572727 L51.1384091,53.1572727 C50.3672727,53.1572727 49.6411364,52.8565909 49.095,52.3104545 L43.3779545,46.5893182 C42.5781818,45.7895455 41.2854545,45.7895455 40.4856818,46.5893182 L29.2356818,57.8475 C28.4359091,58.6472727 28.4359091,59.9420455 29.2356818,60.7418182 C29.6345455,61.1406818 30.1581818,61.3411364 30.6818182,61.3411364 C31.2054545,61.3411364 31.7290909,61.1406818 32.1279545,60.7418182 L41.9318182,50.9318182 L46.2027273,55.2047727 C47.5220455,56.5240909 49.2729545,57.2502273 51.1404545,57.2502273 C51.1425,57.2502273 51.1445455,57.2502273 51.1465909,57.2502273 C53.0079545,57.2481818 54.7568182,56.52 56.07,55.2047727 L58.7188636,52.5538636 C59.5186364,51.7540909 59.5186364,50.4593182 58.7188636,49.6595455 L37.665,28.5893182 C37.1168182,28.0431818 36.8181818,27.3170455 36.8181818,26.5438636 C36.8181818,25.7706818 37.1168182,25.0445455 37.665,24.4963636 L57.2727273,4.87636364 C58.3670455,3.78 60.2693182,3.78204545 61.3636364,4.87636364 L85.0622727,28.5934091 C85.6104545,29.1395455 85.9090909,29.8656818 85.9090909,30.6388636 C85.9090909,31.4120455 85.6104545,32.1381818 85.0622727,32.6863636 L65.4545455,52.3063636 C64.9063636,52.8545455 64.1822727,53.1552273 63.4090909,53.1552273 C62.2779545,53.1552273 61.3636364,54.0715909 61.3636364,55.2027273 C61.3636364,56.3338636 62.2779545,57.2502273 63.4090909,57.2502273 C65.2725,57.2502273 67.0275,56.5240909 68.3468182,55.2027273 L87.9545455,35.5806818 C89.2738636,34.2613636 90,32.5063636 90,30.6388636 C90,28.7693182 89.2738636,27.0143182 87.9545455,25.6970455 Z"/>
        </g>
      </g>
    </svg>
  );
}

export default KeyIcon;
