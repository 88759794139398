// import api from '../api/api';
import api from '../api/fakeApi';
import { getAccount } from './account';
import { listCards } from './cards';
import { handleErrors } from './error';
import {
  convertProfileResponse,
  PROFILE_GET,
} from './profile';
import { initialAccountTransactions } from './transactions';
import { showSnackbarAPIErrorMessage } from './ui/snackbar';
import { hideRequestSpinner, showPreloadSpinner, showRequestSpinner } from './ui/spinner';

export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_ERROR = 'AUTH/LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'AUTH/CLEAR_LOGIN_ERROR';
export const LOGOUT = 'AUTH/LOGOUT';
export const SEND_RECOVERY_EMAIL = 'AUTH/SEND_RECOVERY_EMAIL';
export const RECOVER_PASSWORD_BY_TOKEN = 'AUTH/RECOVER_PASSWORD_BY_TOKEN';
export const RECOVER_PASSWORD_TOKEN_ERROR = 'AUTH/RECOVER_PASSWORD_BY_TOKEN/SET_TOKEN_INVALID';
export const RECOVER_PASSWORD_TOKEN_CLEAR_ERROR = 'AUTH/RECOVER_PASSWORD_BY_TOKEN/UNSET_TOKEN_INVALID';

interface ILogin {
  type: typeof LOGIN;
  loginVar?: number;
}

export function checkAuth() {
  return (dispatch) => {
    dispatch(showRequestSpinner());
    api.me().then((content) => {
      dispatch(initiate());
      dispatch({
        type: PROFILE_GET,
        payload: convertProfileResponse(content),
      });
      dispatch({ type: LOGIN });
      dispatch(hideRequestSpinner());
    }).catch(() => {
      dispatch({ type: LOGOUT });
      dispatch(hideRequestSpinner());
    });
  };
}

interface ILoginError {
  type: typeof LOGIN_ERROR;
}

export function login(email, password: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.login(email, password)
      .then(() => api.me())
      .then(content => {
        dispatch({
          type: PROFILE_GET,
          payload: convertProfileResponse(content),
        });

        dispatch({ type: LOGIN });
        dispatch(initiate());
        dispatch(hideRequestSpinner());
      }).catch(() => {
        dispatch({ type: LOGIN_ERROR });
      });
  });
}

interface IClearLoginError {
  type: typeof CLEAR_LOGIN_ERROR;
}

export function clearLoginError() {
  return (dispatch) => {
    dispatch({ type: CLEAR_LOGIN_ERROR });
  };
}

interface ILogout {
  type: typeof LOGOUT;
  logoutVar?: number;
}

export function logout() {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.logout().then(() => {
      dispatch({ type: LOGOUT });
      dispatch(hideRequestSpinner());
    });
  });
}

export function initiate() {
  return (dispatch) => {
    // pass here amount of initial load requests
    dispatch(showPreloadSpinner(3));
    dispatch(getAccount());
    dispatch(listCards());
    dispatch(initialAccountTransactions());
    // TODO: add profile request
  };
}

interface ISendRecoveryEmail {
  type: typeof SEND_RECOVERY_EMAIL;
}

export function sendRecoveryEmail(email: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.sendRecoveryEmail(email).then(() => {
      dispatch({
        type: SEND_RECOVERY_EMAIL,
      });
      dispatch(hideRequestSpinner());
    });
  });
}

interface ISetPasswordByTokenError {
  type: typeof RECOVER_PASSWORD_TOKEN_ERROR;
}

function showRecoverPasswordTokenError() {
  return (dispatch) => {
    dispatch({ type: RECOVER_PASSWORD_TOKEN_ERROR });
  };
}

interface ISetPasswordByTokenCleanError {
  type: typeof RECOVER_PASSWORD_TOKEN_CLEAR_ERROR;
}

function hideRecoverPasswordTokenError() {
  return (dispatch) => {
    dispatch({ type: RECOVER_PASSWORD_TOKEN_CLEAR_ERROR });
  };
}

export function recoverPasswordTokenValidate(token: string) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.checkPasswordRecoveryToken(token).then(({ is_valid }) => {
      if (is_valid) {
        dispatch(hideRecoverPasswordTokenError());
      } else {
        dispatch(showRecoverPasswordTokenError());
      }
      dispatch(hideRequestSpinner());
    });
  });
}

interface ISetPasswordByToken {
  type: typeof RECOVER_PASSWORD_BY_TOKEN;
}

export function recoverPasswordByToken(token: string, password: string, onSuccess: () => any) {
  return handleErrors((dispatch) => {
    dispatch(showRequestSpinner());
    return api.recoverPasswordByToken(token, password).then(() => {
      dispatch({
        type: RECOVER_PASSWORD_BY_TOKEN,
      });
      dispatch(hideRequestSpinner());
      onSuccess();
    }).catch((e) => {
      dispatch(hideRequestSpinner());
      // TODO: havr - define exact error code for token invalid case
      if (e.code === 403) {
        dispatch(showRecoverPasswordTokenError());
      } else {
        dispatch(showSnackbarAPIErrorMessage(e));
      }
    });
  });
}

export type AuthActions = ILogin | ILogout | IClearLoginError | ILoginError |
  ISetPasswordByToken | ISetPasswordByTokenError | ISetPasswordByTokenCleanError | ISendRecoveryEmail;
