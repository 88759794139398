/* tslint:disable:align */
import * as React from 'react';

import { InjectedProps, withRipple } from '@material/react-ripple';
import classnames from 'classnames';
import * as History from 'history';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import Icon from '../Icon/Icon';

interface IButtonProps extends InjectedProps<HTMLDivElement> {
  className?: any;
  label: string;
  icon?: string;
  width?: 'block' | 'large' | 'medium';
  bordered?: boolean;
  ghost?: boolean;
  pinnedBottom?: boolean;
  rounded?: boolean;

  initRipple: React.Ref<HTMLDivElement>;
  onClick?: (event) => any;
  to?: History.LocationDescriptor;
}

class Button extends React.Component <IButtonProps, {}> {
  render() {
    const { label, className, initRipple, icon, width, bordered, ghost,
      pinnedBottom, rounded, onClick, to, ...other } = this.props;
    const buttonClass = classnames({
      button: true,
      'button-width-block': width === 'block',
      'button-width-large': width === 'large',
      'button-width-medium': width === 'medium',
      'button-bordered': bordered,
      'button-ghost': ghost,
      'button-pinned-bottom': pinnedBottom,
      'button-rounded': rounded,
    }, className);

    const iconRender = icon ? (
      <Icon name={icon} className="button-icon" />
    ) : null;

    return (
      <ConditionalWrapper
        className={to ? 'button-with-link' : null}
        onClick={onClick}
        to={to}
      >
        <div
          className={buttonClass}
          ref={initRipple}
          onClick={onClick}
          {...other}
        >
          {iconRender}
          {label}
        </div>
      </ConditionalWrapper>
    );
  }
}

export default withRipple(Button);
