/* tslint:disable:align */
import * as React from 'react';

import classnames from 'classnames';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

interface IListItemProps {
  className?: any;
  leftElement: React.ReactElement;

  // Pass content line 2 and rightSecondary only if it is exists (not needed for category list item)
  title: string;
  tag?: React.ReactElement;
  contentLine1: string;
  contentLine2?: string;
  rightPrimary: string;
  rightSecondary?: string;

  // Pass 'onClick' function to render list item as div and 'to' link to render list item as router link
  onClick?: (event: EventTarget) => any;
  to?: string;
}

class TransactionListItem extends React.Component <IListItemProps, {}> {
  renderContentLine() {
    const { contentLine1, contentLine2 } = this.props;
    // Render double lines list
    if (contentLine2) {
      return (
        <div className="list-item-sub-line">
          <p className="primary"> {contentLine1} </p>
          <p className="secondary"> {contentLine2} </p>
        </div>
      );
    }
    // Render single line list
    return (
      <p className="list-item-sub-content"> {contentLine1} </p>
    );
  }

  render() {

    const { className, leftElement, title, tag, contentLine2,
            rightPrimary, rightSecondary, onClick, to,
    } = this.props;

    const wrapperClass = classnames({
      'list-item': true,
      'list-item-link': to,
      'list-item-single-line': !contentLine2,
      'list-item-multi-line': contentLine2,
      'list-item-additional-info': rightSecondary,
    }, className);

    const secondaryValueRender = rightSecondary && (
      <span className="value-secondary"> {rightSecondary} </span>
    );

    const titleContent = tag ? (
      <React.Fragment>
        <span key="title" className="list-item-name">{title}</span>
        {tag}
      </React.Fragment>
    ) : title;

    return (
      <ConditionalWrapper
        className={wrapperClass}
        onClick={onClick}
        to={to}
      >
        <div className="icon-wrapper">
          {leftElement}
        </div>
        <div className="content-wrapper">
          <h5 className="list-item-title">
            {titleContent}
          </h5>
          {this.renderContentLine()}
        </div>
        <div className="value-wrapper">
          <span className="value-primary"> {rightPrimary} </span>
          {secondaryValueRender}
        </div>
      </ConditionalWrapper>
    );
  }
}

export default TransactionListItem;
