/* tslint:disable:align */
import * as React from 'react';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Icon from '../../Basic/Icon/Icon';
import IconButton from '../../Basic/IconButton/IconButton';

interface IModalProps {
  className?: any;
  hideAppearTransition?: boolean;
  hideBackArrow?: boolean;
  asideWidget?: React.ReactElement;

  onBack?: () => any;
  modalTitle?: string;
  modalWidget?: React.ReactElement;
  children?: any;
}

class Modal extends React.Component <IModalProps, {}> {
  render() {
    const {
      className, children, onBack, modalTitle, asideWidget,
      hideAppearTransition, hideBackArrow, modalWidget,
    } = this.props;
    const wrapperClass = classnames({
      modal: true,
      'modal-no-appear': hideAppearTransition,
    }, className);

    const backButton = !hideBackArrow && (
        <IconButton
            className="modal-header-back-action"
            onClick={onBack}
        >
            <Icon name="keyboard-backspace" />
        </IconButton>
    );

    const headerItemRender = modalWidget || (
      <h2 className="modal-header-title">{modalTitle}</h2>
    );

    const modalHeaderActionClassname = classnames({
      'modal-header-action': true,
      empty: !asideWidget,
    });

    const modalHeaderAction = asideWidget ? (
      <div className={modalHeaderActionClassname}>
        {asideWidget}
      </div>
    ) : (
      <div className={modalHeaderActionClassname}/>
    );

    return (
            <div className={wrapperClass}>
                <div className="modal-header">
                    {backButton}
                    {headerItemRender}
                    {modalHeaderAction}
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </div>
    );
  }
}

export default Modal;
